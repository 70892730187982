import { useTheme } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import DomSerializer from "dom-serializer";
import parser, { domToReact } from "html-react-parser";
import React, { Suspense } from "react";
import { atomOneDark, atomOneLight } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { SRLWrapper } from "simple-react-lightbox";

const SyntaxHighlighter = React.lazy(() => import("react-syntax-highlighter/dist/cjs/default-highlight"));

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            width: "auto",
            fontSize: 14,
            "& a": {
                color: theme.palette.mode === "dark" ? theme.palette.text.primary : theme.palette.primary.main,
                textDecoration: theme.palette.mode === "dark" ? "underline" : "none",
            },
            "& img": {
                maxWidth: "100%",
            },
            "& p:first-of-type": {
                marginTop: 0,
            },
        },

        item: {
            paddingTop: "15px",
            paddingBottom: "15px",
        },
        code: {
            maxWidth: "1000px",
        },
    }),
);

export function HtmlLongText({ htmlText, label }: { htmlText: string; label: string }) {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <>
            <Typography variant="h6" component="h2" className={classes.item}>
                {label}
            </Typography>
            <SRLWrapper>
                <div className={classes.content}>
                    {parser(htmlText, {
                        replace: function (domNode) {
                            if (
                                domNode.name === "pre" &&
                                domNode.attribs?.class?.startsWith("code-") &&
                                domNode.children
                            ) {
                                const language = domNode.attribs?.class?.replace("code-", "");
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                const codeText = DomSerializer((domNode as any).children)
                                    .replace(/<span\/?[^>]+(>|$)/g, "")
                                    .replace(/<\/span>/g, "");
                                return (
                                    <Suspense fallback={<pre className={classes.code}>{codeText}</pre>}>
                                        <SyntaxHighlighter
                                            language={language || "javascript"}
                                            style={theme.palette.mode === "dark" ? atomOneDark : atomOneLight}
                                            className={classes.code}
                                        >
                                            {codeText}
                                        </SyntaxHighlighter>
                                    </Suspense>
                                );
                            }
                            if (domNode.name === "a" && domNode.attribs && domNode.children) {
                                domNode.attribs.target = "_blank";
                                return domToReact([domNode], {});
                            }
                        },
                    })}
                </div>
            </SRLWrapper>
        </>
    );
}
