import {
    JWT_TOKEN_LOCAL_STORAGE_KEY,
    WS_URL_LOCAL_STORAGE_KEY,
    SESSION_CONFIG_LOCAL_STORAGE_KEY,
    JWT_MEMBER_TOKEN_SESSION_STORAGE_KEY,
} from "../../interfaces/LocalStorageKeys";

export const LOGOUT = "LOGOUT";

export interface LogoutAction {
    type: typeof LOGOUT;
    payload: {
        wasKicked?: boolean;
    };
}

export const Logout = (wasKicked?: boolean): LogoutAction => {
    window.localStorage.removeItem(JWT_TOKEN_LOCAL_STORAGE_KEY);
    window.localStorage.removeItem(WS_URL_LOCAL_STORAGE_KEY);
    window.localStorage.removeItem(SESSION_CONFIG_LOCAL_STORAGE_KEY);
    window.sessionStorage.removeItem(JWT_MEMBER_TOKEN_SESSION_STORAGE_KEY);

    return {
        type: LOGOUT,
        payload: {
            wasKicked,
        },
    };
};
