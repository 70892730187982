import React, { PropsWithChildren } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import ButtonBase from "@mui/material/ButtonBase";
import clsx from "clsx";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import "./VoteCard.css";

export interface VoteCardProps {
    score: string;
    votePossible: boolean;
    selected?: boolean;
    onClick?: () => void;
}
export function VoteCard({ score, children, votePossible, selected, onClick }: PropsWithChildren<VoteCardProps>) {
    return (
        <div className="vote-card__wrapper">
            <Card className={clsx({ selected: selected }, "vote-card__card")}>
                <ButtonBase disabled={!votePossible} style={{ width: "100%" }} onClick={onClick}>
                    {selected && <CheckCircleIcon className="check-circle" />}
                    <CardContent className="vote-card__card--content">
                        <Typography variant="h2">{score}</Typography>
                        {children}
                    </CardContent>
                </ButtonBase>
            </Card>
        </div>
    );
}
