import { Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDebounce } from "use-debounce/lib";
import { JiraApi, JiraDeploymentType } from "../../helpers/JiraApi";
import { TextFieldWithStatusIndications } from "../UI/TextFieldWithStatusIndications";

interface JiraInstanceData {
    instanceName: string;
    url: string;
    publicBaseUrl: string;
    kind: JiraDeploymentType;
}

export type OnJiraSetPayload = ({ isSet: true } & JiraInstanceData) | ({ isSet: false } & Partial<JiraInstanceData>);
interface JiraUrlInputProps {
    onJiraSet: (data: OnJiraSetPayload) => void;
}

export function JiraUrlInput({ onJiraSet }: JiraUrlInputProps) {
    const [jiraUrlInputValue, setJiraUrlInputValue] = useState("");
    const [debouncedJiraUrlInputValue] = useDebounce(jiraUrlInputValue, 1000);

    const { isFetching, data, error } = useQuery({
        queryKey: `jiraUrlValidationResult-${debouncedJiraUrlInputValue}`,
        queryFn: () => JiraApi.verifyJiraUrl(debouncedJiraUrlInputValue),
        staleTime: Infinity,
        retry: 0,
    });

    useEffect(() => {
        data?.isValid
            ? onJiraSet({
                  isSet: true,
                  url: data?.url,
                  kind: data?.deploymentType,
                  instanceName: data.serverTitle,
                  publicBaseUrl: data.baseUrl,
              })
            : onJiraSet({ isSet: false });
    }, [data, onJiraSet]);

    return (
        <>
            <TextFieldWithStatusIndications
                fullWidth
                id="outlined-jira-url"
                label="Url of your JIRA"
                value={jiraUrlInputValue}
                name={"jira-url"}
                onChange={(e) => setJiraUrlInputValue(e.target.value)}
                helperText={
                    data?.isValid
                        ? ` Connected to jira "${data.serverTitle}"
                            (${data.deploymentType === "Cloud" ? "Atlasian Cloud Jira" : "Jira Server"})`
                        : "Write url of your jira ex https://jira.mycomapny.com"
                }
                required
                valid={data?.isValid}
                errorMessage={(error as Error)?.message}
                isLoading={isFetching}
            />
            {data?.isValid && data.corsProxyRequired && (
                <>
                    <Alert severity="warning">
                        Connection to Jira has been established correctly using proxy.
                        <br />
                        For better privacy and performance it is recommended to use direct connection. To make it
                        possible contact your jira Administrator to add "{window.location.hostname}" to&nbsp;
                        <a
                            href="https://confluence.atlassian.com/adminjiraserver/configuring-the-allowlist-1014667309.html"
                            target="_blank"
                            rel="noreferrer"
                        >
                            list of white listed domains.
                        </a>
                    </Alert>
                </>
            )}
        </>
    );
}
