import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { ConfigWizard } from "../components/ConfigWizard/ConfigWizard";
import { UserState, UserType } from "../interfaces/ActiveUser";
import { Config } from "../interfaces/Config";
import { RoomState } from "../interfaces/RoomState";
import { ConfigUpdated } from "../store/actions/ConfigUpdated";
import { CreateRoom } from "../store/actions/CreateRoom";
import "./sessionBox.css";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: "5em",
            alignSelf: "center",
            justifyItems: "center",
            display: "inline-grid",
            width: "100%",
        },
        wizard: {
            width: "1100px",
        },
    }),
);

export function ConfigPage() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const currentConfig = useSelector((state: RoomState) => state.config);
    const activeUser = useSelector((state: RoomState) => state.activeUser);
    const isFistLoad = activeUser.state === UserState.EMPTY;

    const onSave = (config: Config) => {
        if (JSON.stringify(currentConfig) !== JSON.stringify(config)) {
            dispatch(ConfigUpdated(config));

            if (isFistLoad) {
                dispatch(
                    CreateRoom(
                        config.isVoting ? { name: config.name, nick: config.shortName, group: config.group } : {},
                    ),
                );
            }
        }
        history.push("/");
    };

    if (currentConfig?.jiraInstance === undefined || activeUser.userType === UserType.MEMBER) {
        return <Redirect to={`/`} />;
    }

    return (
        <div className={classes.root}>
            <ConfigWizard
                className={classes.wizard}
                initConfig={currentConfig}
                onSave={onSave}
                isFirstLoad={isFistLoad}
            />
        </div>
    );
}
