/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Button, TextField } from "@mui/material";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { AvailableVoter } from "../../store/actions/SetAvailableVoters";
import "./VoteMgmt.css";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minWidth: 275,
            maxWidth: 275,
            height: 120,
            display: "flex",
        },
        title: {
            fontSize: 14,
        },
        button: {
            margin: "0.5em 0.5em 0.5em 0em",
        },
        saveVoteBar: {
            display: "inline-block",
            marginTop: "1em",
        },
    }),
);

interface IVoteMgmt {
    wasTicketEstimated: boolean;
    estimation?: string;
    everyOneVoted: boolean;
    groups?: string[];
    onSave: (vote: string | undefined) => void;
    onReset: () => void;
    onReveal: () => void;
    onMarkAsUnestimated: () => void;
    canReveal: boolean;
    checkboxOnChange: (e: any) => void;
    availableVoters: AvailableVoter[];
}

export function VoteMgmtView({
    estimation,
    groups,
    onSave,
    onReset,
    onReveal,
    canReveal,
    everyOneVoted,
    wasTicketEstimated,
    onMarkAsUnestimated,
    checkboxOnChange,
    availableVoters,
}: IVoteMgmt) {
    const [estimationValue, setValue] = React.useState<{ estimation: string }>({
        estimation: "",
    });

    const classes = useStyles();
    useEffect(() => {
        if (estimation) {
            setValue({ estimation });
        }
    }, [estimation]);

    const handleSend = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        onSave(estimationValue.estimation);
    };

    const handleReset = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        estimationValue.estimation = "";
        setValue({ ...estimationValue });
        onReset();
    };

    const handleReveal = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        onReveal();
    };

    return (
        <div>
            <div className="vote-mgmt">
                {estimation !== undefined ? (
                    <div className={classes.saveVoteBar}>
                        {!groups && (
                            <TextField
                                fullWidth
                                id="outlined-vote"
                                label="Vote"
                                variant="outlined"
                                value={estimationValue.estimation}
                                onChange={(e) => setValue({ ...estimationValue, estimation: e.target.value })}
                            />
                        )}
                        <Button variant="contained" color="primary" className={classes.button} onClick={handleSend}>
                            Save
                        </Button>
                        <Button variant="contained" color="primary" className={classes.button} onClick={handleReset}>
                            Reset
                        </Button>
                    </div>
                ) : (
                    <>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!canReveal}
                            className={classes.button}
                            onClick={handleReveal}
                        >
                            {everyOneVoted && (
                                <CountdownCircleTimer
                                    isPlaying
                                    duration={2}
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    colors={["#00f853", "#F7B801", "#ffcc00", "#0e0505"] as any}
                                    colorsTime={[2, 1, 0]}
                                    onComplete={() => onReveal()}
                                    size={25}
                                    strokeWidth={5}
                                ></CountdownCircleTimer>
                            )}
                            Reveal votes
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={wasTicketEstimated ? onMarkAsUnestimated : handleSend}
                        >
                            Mark as {wasTicketEstimated ? `unestimated` : `estimated`}
                        </Button>
                    </>
                )}
            </div>
            {groups?.length && (
                <div className="disabling-voters">
                    <h2>Available voters</h2>
                    <div className="disabling-voters-list">
                        <FormGroup>
                            {availableVoters &&
                                availableVoters.map((group) => (
                                    <FormControlLabel
                                        key={group.value}
                                        control={
                                            <Checkbox
                                                key={group.value}
                                                value={group.value}
                                                checked={group.checked}
                                                onChange={(e) => checkboxOnChange(e)}
                                            />
                                        }
                                        label={group.value?.toLocaleUpperCase()}
                                    />
                                ))}
                        </FormGroup>
                    </div>
                </div>
            )}
        </div>
    );
}
