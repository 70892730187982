import { IconButton, Tooltip } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import CachedIcon from "@mui/icons-material/Cached";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { createFilterOptions } from "@mui/material/Autocomplete";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserType } from "../../interfaces/ActiveUser";
import { JiraTicket } from "../../interfaces/JiraTicket";
import { RoomState } from "../../interfaces/RoomState";
import { ChangeActiveTicketToNext, ChangeActiveTicketToPrev } from "../../store/actions/ActiveTicketChanged";
import { RefreshOrAddTicket } from "../../store/actions/TicketAddedOrUpdated";
import { JiraTicketDetails } from "../JiraTicketDetails/JiraTicketDetails";
import { JiraTicketDetailsPlaceholder } from "../JiraTicketDetails/JiraTicketDetailsPlaceholder";
import { SelectActiveTicket } from "../SelectActiveTicket/SelectActiveTicket";
import { TicketListView } from "../TicketList/TicketListView";

const useStyles = makeStyles(() =>
    createStyles({
        buttons: {
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
            paddingBottom: "0.5em",
        },
        ticketSelect: {
            flexGrow: 5,
        },
    }),
);

export const defaultFilterOptions = createFilterOptions<JiraTicket>();
export function ActiveJiraTicketConnected() {
    const classes = useStyles();
    const ticket = useSelector((state: RoomState) => state.activeTicket);
    const ticketList = useSelector((state: RoomState) => state.ticketList);
    const extraFields = useSelector((state: RoomState) => state.config.extraFields);

    const userType = useSelector((state: RoomState) => state.activeUser.userType);
    const dispatch = useDispatch();

    return (
        <>
            {ticket && userType === UserType.SCRUM_MASTER && (
                <div className={classes.buttons}>
                    <SelectActiveTicket className={classes.ticketSelect} />

                    <TicketListView ticketList={ticketList || []} extraFields={extraFields || []} />
                    <IconButton
                        onClick={() =>
                            dispatch(RefreshOrAddTicket(ticket.ticketId, { setAsActive: true, resetVotes: false }))
                        }
                        size="large"
                    >
                        <Tooltip title="Refresh current ticket">
                            <CachedIcon />
                        </Tooltip>
                    </IconButton>
                    <IconButton onClick={() => dispatch(ChangeActiveTicketToPrev())} size="large">
                        <Tooltip title="Previous ticket">
                            <NavigateBeforeIcon />
                        </Tooltip>
                    </IconButton>
                    <IconButton onClick={() => dispatch(ChangeActiveTicketToNext())} size="large">
                        <Tooltip title="Next ticket">
                            <NavigateNextIcon />
                        </Tooltip>
                    </IconButton>
                </div>
            )}
            {ticket && !ticket.isPlaceholder ? <JiraTicketDetails {...ticket} /> : <JiraTicketDetailsPlaceholder />}
        </>
    );
}
