import { RoomInfo } from "../../interfaces/RoomInfo";

export const ROOM_LOADED = "ROOM_LOADED";

export interface RoomLoadedAction {
    type: typeof ROOM_LOADED;
    payload: RoomInfo;
}

export const RoomLoaded = (payload: RoomInfo): RoomLoadedAction => {
    return {
        type: ROOM_LOADED,
        payload,
    };
};
