import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import App from "./App";
import "./index.css";
import { store } from "./store/store";
import * as Sentry from "@sentry/react";
import { CaptureConsole } from "@sentry/integrations";
import SimpleReactLightbox from "simple-react-lightbox";

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV,
    tracesSampleRate: 1.0,
    integrations: [new CaptureConsole({ levels: ["error"] })],
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <Sentry.ErrorBoundary>
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <SimpleReactLightbox>
                        <App />
                    </SimpleReactLightbox>
                </QueryClientProvider>
            </Provider>
        </Sentry.ErrorBoundary>
    </React.StrictMode>,
    document.getElementById("root"),
);
