export const USER_KICKED = "USER_KICKED";

export interface UserKickedAction {
    type: typeof USER_KICKED;
    payload: {
        uuid: string;
    };
}

export const UserKicked = (uuid: string): UserKickedAction => {
    return {
        type: USER_KICKED,
        payload: { uuid },
    };
};
