import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import { websocketsMiddleware } from "./middleware/websocketsMiddleware";
import { rootReducer } from "./reducers/rootReducer";
import * as Sentry from "@sentry/react";
import { localStorageMiddleware } from "./middleware/localStorageMiddelware";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});
const composedEnhancer = composeWithDevTools(
    applyMiddleware(thunkMiddleware, websocketsMiddleware, localStorageMiddleware),
    sentryReduxEnhancer,
);

export const store = createStore(rootReducer, composedEnhancer);
