import { UserType } from "../../interfaces/ActiveUser";
import { ReferenceTicket } from "../../interfaces/ReferenceTicket";
import { WsCompatibleAction } from "./WsCompatibleAction";

export const REFERENCE_TICKETS_LOADED = "REFERENCE_TICKETS_LOADED";

export interface ReferenceTicketsLoadedAction extends WsCompatibleAction {
    type: typeof REFERENCE_TICKETS_LOADED;
    payload: ReferenceTicket[];
}

export const ReferenceTicketsLoaded = (payload: ReferenceTicket[]): ReferenceTicketsLoadedAction => {
    return {
        type: REFERENCE_TICKETS_LOADED,
        payload,
        sendOverWs: {
            when: (user) => user.userType === UserType.SCRUM_MASTER,
            format: ({ token }) => {
                const simplifiedPayload = payload.map((point) => ({
                    storyPoint: point.storyPoint,
                    tickets: point.tickets.map((ticket) => ({
                        title: ticket.title,
                        ticketId: ticket.ticketId,
                    })),
                }));
                return { action: "reference_tickets", data: JSON.stringify(simplifiedPayload), token: token || "" };
            },
        },
    };
};
