import { CircularProgress, TextField, TextFieldProps } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import CheckIcon from "@mui/icons-material/Check";
import React from "react";

export const TextFieldWithStatusIndications = withStyles({
    root: {
        "&.valid": {
            "& svg": {
                color: "green",
            },
            "& label": {
                color: "green",
            },
            "& .MuiInput:after": {
                borderBottomColor: "green",
            },
            "& .MuiOutlinedInput-root": {
                "& fieldset": {
                    borderColor: "green",
                },
            },
        },
    },
})(
    React.forwardRef<HTMLDivElement, TextFieldProps & { valid?: boolean; errorMessage?: string; isLoading?: boolean }>(
        (props, ref) => {
            const { errorMessage, error, isLoading, valid, ...otherProps } = props;
            return (
                <TextField
                    variant="outlined"
                    autoComplete="off"
                    className={valid ? "valid" : ""}
                    InputProps={{
                        endAdornment: (
                            <>
                                {isLoading ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : props.valid ? (
                                    <CheckIcon />
                                ) : undefined}
                            </>
                        ),
                    }}
                    ref={ref}
                    {...otherProps}
                    error={error || !!errorMessage}
                    helperText={props.errorMessage ? props.errorMessage : props.helperText}
                />
            );
        },
    ),
);
