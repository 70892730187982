import jwt_decode from "jwt-decode";
import useLocalStorage from "react-use/lib/useLocalStorage";

export function isJwtTokenExpired(payload?: { exp: number }) {
    return payload && Date.now() >= (payload.exp || 0) * 1000;
}
export const useJwtFromLocalStorage = <T extends { exp: number }>(
    name: string,
): [(T & { exp: number; jwt: string }) | undefined, () => void] => {
    const [value, , remove] = useLocalStorage<T & { jwt: string }>(name, undefined, {
        raw: false,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        serializer: (v) => v as any, // we don't use serializer here
        deserializer: (jwt) => ({ ...jwt_decode<T>(jwt), jwt }),
    });

    return [value, remove];
};
