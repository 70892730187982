import { WsCompatibleAction } from "./WsCompatibleAction";
export const VOTE = "VOTE";

export interface VoteAction extends WsCompatibleAction {
    type: typeof VOTE;
    payload: {
        hasVoted: boolean;
        vote?: string;
    };
}

export function Vote(vote?: string): VoteAction {
    return {
        type: VOTE,
        payload: {
            hasVoted: vote !== undefined,
            vote,
        },
        sendOverWs: {
            when: () => true,
            format: (user) => ({
                action: "user_voted",
                vote: vote ? vote : null,
                voted: vote !== undefined,
                token: user.token,
                group: user.group,
            }),
        },
    };
}
