import { FormControl, InputLabel, MenuItem, Select, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { JiraApi } from "../../../helpers/JiraApi";
import SelectWithAutocomplete, { SelectValueObject } from "../../UI/SelectWithAutocomplete";
import SelectWithAutocompleteAsyncList from "../../UI/SelectWithAutocompleteAsyncList";
import { JqlInputWithAutocomplete } from "./JqlInputWithAutocomplete";
import { EstimationMode, JqlQueryBuilderConfig } from "./JqlQueryBuilderConfig";
import { JqlStoryPointSelector } from "./JqlStoryPointSelector";
import TicketsList from "./TicketsList";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& .MuiTextField-root": {
                marginTop: theme.spacing(2),
            },
        },
        ticketList: {
            marginTop: "3em",
        },
    }),
);

export interface JqlQueryBuilderProps {
    jiraApi: JiraApi;
}
export function JqlQueryBuilder({ jiraApi }: JqlQueryBuilderProps) {
    const classes = useStyles();
    const { values, setFieldValue, handleBlur, isSubmitting, errors, touched } =
        useFormikContext<JqlQueryBuilderConfig>();

    const jiraProjectsQuery = useQuery("jira-projects", async () =>
        (await jiraApi.getProjects()).map((project) => ({
            id: project.name,
            name: `${project.key}: ${project.name}`,
        })),
    );
    const { data: autocompleteData } = useQuery("jira-autocomplete-data", () => jiraApi.getAutocompleteData(), {
        staleTime: Infinity,
    });
    const { data: issueTypes, isLoading: issueTypesLoading } = useQuery(`jira-issue-types`, async () =>
        (await jiraApi.getIssueTypes()).map((issueType) => ({
            id: `"${issueType.name}"`,
            name: issueType.name,
        })),
    );

    useEffect(() => {
        if (values.manualJql) {
            return;
        }
        const query = buildJql(values, issueTypes);
        if (query) {
            const { jql, referenceTicketsJql } = query;
            setFieldValue("jql", jql);
            setFieldValue("referenceTicketsJql", referenceTicketsJql);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.project, values.storyPointsField, values.issueTypes, values.statuses, issueTypes]);

    return (
        <div className={classes.root}>
            <Typography variant="subtitle1">
                Define filters to load list of ticket to your refinement session from Jira.
            </Typography>
            <SelectWithAutocomplete
                id="project"
                multiple={false}
                label="JIRA project"
                error={!!errors.project && (isSubmitting || touched.project)}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                helperText={(errors as any).project?.name}
                onBlur={handleBlur}
                isLoading={jiraProjectsQuery.isLoading}
                options={jiraProjectsQuery.data || []}
                onNewValue={(v) => setFieldValue("project", v)}
                value={values.project || null}
            />

            {values.project && (
                <>
                    <FormControl fullWidth style={{ marginTop: "15px" }}>
                        <InputLabel id="estimation-mode-label">Estimation mode</InputLabel>
                        <Select
                            labelId="estimation-mode-label"
                            value={values.estimationMode}
                            label="estimationMode"
                            onChange={(e) => setFieldValue("estimationMode", e.target.value)}
                        >
                            <MenuItem value={EstimationMode.STORY_POINTS}>Story points</MenuItem>
                            <MenuItem value={EstimationMode.HOURS}>Hours </MenuItem>
                            <MenuItem value={EstimationMode.MANDAYS}>Man-days </MenuItem>
                        </Select>
                    </FormControl>

                    {values.estimationMode === EstimationMode.STORY_POINTS && (
                        <JqlStoryPointSelector
                            error={!!errors.storyPointsField && (isSubmitting || touched.storyPointsField)}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            helperText={(errors as any).storyPointsField?.name}
                            autocompleteData={autocompleteData}
                            value={values?.storyPointsField}
                            setValue={(value) => setFieldValue("storyPointsField", value)}
                        />
                    )}

                    <SelectWithAutocomplete
                        multiple
                        label="Issue types"
                        isLoading={issueTypesLoading}
                        options={issueTypes || []}
                        value={values.issueTypes || []}
                        limitTags={4}
                        onNewValue={(issueTypes) => setFieldValue("issueTypes", issueTypes)}
                        disabled={values.manualJql}
                    />

                    <SelectWithAutocompleteAsyncList
                        multiple
                        label="Ticket statuses"
                        value={values.statuses || []}
                        onNewValue={(statuses) => setFieldValue("statuses", statuses)}
                        fetchAutocomplete={async (value) => await jiraApi.getAutocompleteSuggestions("status", value)}
                        disabled={values.manualJql}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={values.manualJql || false}
                                onChange={({ target: { checked } }) => setFieldValue("manualJql", checked)}
                                color="primary"
                            />
                        }
                        label="Manual JQL input"
                        labelPlacement="end"
                    />
                    <br />
                    {autocompleteData && (
                        <JqlInputWithAutocomplete
                            jiraApi={jiraApi}
                            jql={values.jql || ""}
                            setJql={(newJql) => setFieldValue("jql", newJql)}
                            autocompleteData={autocompleteData}
                            isValid={values.isJqlValid ?? true}
                            disabled={!values.manualJql}
                        />
                    )}

                    <div className={classes.ticketList}>
                        <TicketsList
                            jiraApi={jiraApi}
                            jql={values.jql as string}
                            setQueryValidationResult={(v) => setFieldValue("isJqlValid", v)}
                        />
                    </div>
                </>
            )}
        </div>
    );
}

export function buildJql(values: JqlQueryBuilderConfig, issueTypes?: SelectValueObject[]) {
    let issueTypesQuery = undefined;
    if (!values.project) {
        return;
    }

    if (values.issueTypes && issueTypes && values?.issueTypes?.length !== issueTypes?.length) {
        issueTypesQuery = values.issueTypes.length
            ? `issuetype IN (${values.issueTypes?.map((v) => v.id).join(",")})`
            : undefined;
    }

    const projectPhrase = `project = "${values.project.id}"`;
    const jql = [
        projectPhrase,
        values.storyPointsField && `${values.storyPointsField.id} IS EMPTY`,
        values.statuses &&
            values.statuses.length > 0 &&
            `status IN (${values?.statuses?.map((status) => status.id).join(",")})`,
        issueTypesQuery,
    ]
        .filter((v) => typeof v === "string")
        .join(" AND ");

    const referenceTicketsJql = `${projectPhrase} AND ${values.storyPointsField?.id} IN ($SP_VALUES)`;
    return { jql, referenceTicketsJql };
}
