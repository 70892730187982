export const SET_GROUP_FILTER = "SET_GROUP_FILTER";

export interface SetGroupFilterAction {
    type: typeof SET_GROUP_FILTER;
    group: string;
}

export const SetGroupFilter = (group: string): SetGroupFilterAction => {
    return {
        type: SET_GROUP_FILTER,
        group,
    };
};
