import { ThunkAction } from "redux-thunk";
import { JiraApi } from "../../helpers/JiraApi";
import { JiraTicket } from "../../interfaces/JiraTicket";
import { RoomState } from "../../interfaces/RoomState";
import { Actions } from "./actions";

export const TICKETS_LIST_REFRESHED = "TICKETS_LIST_REFRESHED";

export interface TicketsListRefreshedAction {
    type: typeof TICKETS_LIST_REFRESHED;
    payload: JiraTicket[];
}

export const RefreshTicketList =
    (): ThunkAction<Promise<void>, RoomState, unknown, Actions> => async (dispatch, getState) => {
        const { config } = getState();

        if (!config.jiraInstance) {
            console.error("Unexpected state! Missing jira instance on CONFIG_UPDATED");
            return;
        }
        const jiraApi = new JiraApi(config.jiraInstance);

        const tickets = await jiraApi.getAllMatchingTickets(config);

        dispatch({
            type: TICKETS_LIST_REFRESHED,
            payload: tickets,
        });
    };
