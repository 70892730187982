import React from "react";
import { VoteCard } from "../VoteCard/VoteCard";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import "./VoteSummary.css";
import { Hidden, Typography } from "@mui/material";
import { VoteStatistics } from "../../helpers/calculateVoteSummary";

export interface VoteSummaryProps {
    voteStatistics: VoteStatistics;
}

export function VoteSummary({ voteStatistics }: VoteSummaryProps) {
    const { min, max, avg, median, recommended } = voteStatistics;
    return (
        <>
            <div className="vote-summary">
                <div>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableBody>
                                {[
                                    ["min", min],
                                    ["max", max],
                                    ["median", median],
                                    ["avg", avg],
                                ].map(([col1, col2], id) => (
                                    <TableRow key={`row-${id}`}>
                                        <TableCell key={`col1-${id}-${col1}`} align="right">
                                            {col1}
                                        </TableCell>
                                        <TableCell key={`col2-${id}-${col2}`} align="left">
                                            {col2}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                {recommended && (
                    <Hidden xlDown>
                        <Typography variant="subtitle1">Recommended vote:</Typography>
                        <div>
                            <VoteCard score={recommended.toString()} votePossible={false}></VoteCard>
                        </div>
                    </Hidden>
                )}
            </div>
        </>
    );
}
