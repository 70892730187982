import { ThunkAction } from "redux-thunk";
import { UserType } from "../../interfaces/ActiveUser";
import { RoomState } from "../../interfaces/RoomState";
import { WsCompatibleAction } from "./WsCompatibleAction";
import { Actions } from "./actions";

export const SET_AVAILABLE_VOTERS = "SET_AVAILABLE_VOTERS";

export interface AvailableVoter {
    value: string;
    checked: boolean;
}

export interface SetAvailableVotersAction extends WsCompatibleAction {
    type: typeof SET_AVAILABLE_VOTERS;
    payload: AvailableVoter[];
}

export const SetAvailableVoters =
    (payload: { value: string; checked: boolean }[]): ThunkAction<Promise<unknown>, RoomState, unknown, Actions> =>
    async (dispatch, getState) => {
        const state = getState();

        const { cards, estimationMode, votingGroupsEnabled, votingGroups, displayVoteInBubble } = state.config;
        dispatch({
            type: SET_AVAILABLE_VOTERS,
            payload,
            sendOverWs: {
                when: (user) => user.userType === UserType.SCRUM_MASTER,
                format: (user) => {
                    return {
                        action: "config",
                        token: user.token,
                        data: JSON.stringify({
                            cards,
                            estimationMode,
                            votingGroupsEnabled,
                            votingGroups,
                            displayVoteInBubble,
                            availableVoters: payload,
                        }),
                    };
                },
            },
        });
    };
