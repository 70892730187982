import { Avatar, List, ListItem, ListItemAvatar, ListItemText, ListSubheader, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect } from "react";
import { useInfiniteQuery } from "react-query";
import { Waypoint } from "react-waypoint";
import { useDebounce } from "use-debounce/lib";
import { JiraApi } from "../../../helpers/JiraApi";
import { JiraTicketsForListResponseIssue } from "../../../interfaces/JiraTicketsForListResponse";
export const useTicketListStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            maxHeight: "20em",
            backgroundColor: theme.palette.background.default,
            overflow: "scroll",
        },
    }),
);

export default function TicketsList({
    jql,
    jiraApi,
    setQueryValidationResult,
}: {
    jql: string;
    jiraApi: JiraApi;
    setQueryValidationResult: (result?: boolean) => void;
}) {
    const [debouncedJql] = useDebounce(jql, 1000);

    const { data, fetchNextPage, isError, isLoading } = useInfiniteQuery(
        `jira-tickets-${debouncedJql}`,
        ({ pageParam = 0 }) => jiraApi.getTicketsForList(debouncedJql, pageParam),
        {
            getNextPageParam: (lastPage) => lastPage.startAt + lastPage.maxResults,
            retry: false,
            staleTime: 5 * 60 * 1000,
        },
    );

    useEffect(() => {
        setQueryValidationResult(isLoading ? undefined : !isError);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError, isLoading]);

    const classes = useTicketListStyles();
    const issues = (data?.pages.map((page) => page.issues).flat() as JiraTicketsForListResponseIssue[]) || [];
    const total = data?.pages?.[0].total || 0;
    return (
        <List
            className={classes.root}
            subheader={data && <ListSubheader>Matching tickets ({total} total)</ListSubheader>}
        >
            {issues.map((issue, index) => (
                <ListItem
                    divider={true}
                    dense={true}
                    button
                    key={issue.id}
                    component="a"
                    href={`${jiraApi.publicBaseUrl}/browse/${issue.key}`}
                    target="_blank"
                >
                    <ListItemAvatar>
                        <Avatar src={issue.fields.issuetype.iconUrl}></Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                        primary={issue.key}
                        secondary={issue.fields.summary}
                    />
                    {index === issues.length - 1 && (
                        <Waypoint
                            onEnter={() => {
                                fetchNextPage();
                            }}
                        />
                    )}
                </ListItem>
            ))}
        </List>
    );
}
