import { toast } from "react-toastify";

export const USER_VOTED = "USER_VOTED";

export interface UserVotedAction {
    type: typeof USER_VOTED;
    payload: UserVotedPayload;
}

export interface UserVotedPayload {
    userId: string;
    hasVoted: boolean;
    vote?: string;
    previousVote?: string;
    userName?: string;
}

export function UserVoted(payload: UserVotedPayload): UserVotedAction {
    const { previousVote, vote, userName } = payload;
    if (previousVote && vote && vote !== previousVote) {
        toast.info(
            `${
                Number.parseInt(vote) > Number.parseInt(previousVote) ? "⬆️" : "📉"
            } ${userName} changed vote form ${previousVote} to ${vote}`,
        );
    }
    return {
        type: USER_VOTED,
        payload: payload,
    };
}
