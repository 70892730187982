import { combineReducers } from "redux";
import { RoomState } from "../../interfaces/RoomState";
import { activeTicketReducer } from "./activeTicketReducer";
import { activeUserReducer } from "./activeUserReducer";
import { referenceTicketsReducer } from "./referenceTicketsReducer";
import { revealVotesReducer } from "./revealVotesReducer";
import { ticketListReducer } from "./ticketListReducer";
import { usersReducer } from "./usersReducer";
import { configReducer } from "./configReducer";
import { groupFilterReducer } from "./groupFilterReducer";
export const rootReducer = combineReducers<RoomState>({
    users: usersReducer,
    activeTicket: activeTicketReducer,
    revealVotes: revealVotesReducer,
    activeUser: activeUserReducer,
    referenceTickets: referenceTicketsReducer,
    groupFilter: groupFilterReducer,
    ticketList: ticketListReducer,
    config: configReducer,
});
