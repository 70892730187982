import { RoomState } from "../../interfaces/RoomState";
import { Actions } from "../actions/actions";
import { CONFIG_UPDATED } from "../actions/ConfigUpdated";
import { TICKET_ADDED_OR_UPDATED } from "../actions/TicketAddedOrUpdated";
import { SESSION_CONFIG_LOCAL_STORAGE_KEY } from "../../interfaces/LocalStorageKeys";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const localStorageMiddleware = (store: any) => (next: any) => (action: Actions) => {
    next(action);
    if (action?.type && [CONFIG_UPDATED, TICKET_ADDED_OR_UPDATED].includes(action.type)) {
        window.localStorage.setItem(
            SESSION_CONFIG_LOCAL_STORAGE_KEY,
            JSON.stringify((store.getState() as RoomState).config),
        );
    }
};
