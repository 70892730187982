import { User } from "../interfaces/User";

export interface VoteStatistics {
    min: number;
    max: number;
    avg: number;
    median: number;
    recommended?: number;
}
export type VoteStatisticsForGroup = VoteStatistics & { group: string };

export function calculateVoteStatisticsForGroups(
    users: User[],
    possibleScores: string[],
    groups: string[],
): VoteStatisticsForGroup[] {
    return groups.map((group) => {
        const matchingVotes = users
            .filter((user) => user.group === group && user.hasVoted)
            .map((user) => user.vote as string);
        const stats = calculateVoteStatistics(matchingVotes, possibleScores);
        if (stats) {
            return { group, ...stats };
        }
        return { group, min: 0, max: 0, avg: 0, median: 0 };
    });
}

export function calculateVoteStatistics(votes: string[], possibleScores: string[]): VoteStatistics | undefined {
    const votesAsNumbers = votes
        .map((v) => Number.parseFloat(v))
        .filter((v) => !Number.isNaN(v))
        .sort((a, b) => a - b);
    if (votesAsNumbers.length === 0) {
        return;
    }
    const min = Math.min(...votesAsNumbers);
    const max = Math.max(...votesAsNumbers);
    const avg = avgValue(votesAsNumbers);
    const median = medianValue(votesAsNumbers);

    const recommended = recommendedValue(votesAsNumbers, median, possibleScores);

    return { min, max, avg, median, recommended };
}

export function recommendedValue(votes: number[], median: number, possibleScoresRaw: string[]) {
    if (votes.length < 5) {
        const possibleScores = possibleScoresRaw.map((x) => parseFloat(x)).filter((v) => !isNaN(v));
        const closestMatching = possibleScores.reduce(function (prev, curr) {
            return Math.abs(curr - median) < Math.abs(prev - median) ? curr : prev;
        });
        return closestMatching;
    }

    const uniquePoints = [...new Set(votes)];
    if (uniquePoints.length > 3) {
        return;
    }

    return Math.max(...votes.slice(1, votes.length - 1));
}
export function medianValue(arr: number[]) {
    const arrSort = arr.sort();
    const mid = Math.ceil(arr.length / 2);

    return arr.length % 2 === 0 ? (arrSort[mid] + arrSort[mid - 1]) / 2 : arrSort[mid - 1];
}

export function avgValue(arr: number[]) {
    return Math.round((arr.reduce((sum, current) => sum + current, 0) / arr.length) * 100) / 100;
}
