import { TableHead, TextField } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useEffect } from "react";
import { VoteStatisticsForGroup } from "../../helpers/calculateVoteSummary";
import "./VoteSummaryForGroups.css";

const BASE_FIELDS: (keyof VoteStatisticsForGroup)[] = ["group", "min", "max", "median", "avg"];
const MEMBER_FIELDS: (keyof VoteStatisticsForGroup)[] = [...BASE_FIELDS, "recommended"];
const SM_FIELDS = [...BASE_FIELDS, "estimation"];

export type FinalGroupEstimations = { group: string; value: number }[];
export interface VoteSummaryForGroupsProps {
    voteStatistics: VoteStatisticsForGroup[];
    finalEstimations: FinalGroupEstimations;
    setFinalEstimations: (estimation: FinalGroupEstimations) => void;
    isSm: boolean;
}

export function VoteSummaryForGroups({
    voteStatistics,
    isSm,
    finalEstimations,
    setFinalEstimations,
}: VoteSummaryForGroupsProps) {
    useEffect(() => {
        setFinalEstimations(voteStatistics.map(({ group, recommended }) => ({ group, value: recommended || 0 })));
    }, [setFinalEstimations, voteStatistics]);
    const setFinalEstimation = (group: string, value: number) =>
        setFinalEstimations(
            finalEstimations.map((estimation) => (estimation.group === group ? { group, value } : estimation)),
        );
    const total = finalEstimations.reduce((subSum, statistic) => subSum + (statistic.value || 0), 0);
    return (
        <>
            <div className="vote-group-summary">
                <div>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {(isSm ? SM_FIELDS : MEMBER_FIELDS).map((section) => (
                                        <TableCell key={`thead-${section}`}>{section}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {voteStatistics.map((group) => (
                                    <TableRow key={`row-${group.group}`}>
                                        {(isSm ? BASE_FIELDS : MEMBER_FIELDS).map((field) => (
                                            <TableCell key={`col-${group.group}-${field}`} align="right">
                                                {group[field]}
                                            </TableCell>
                                        ))}
                                        {isSm && (
                                            <TableCell>
                                                <TextField
                                                    fullWidth
                                                    id="outlined-vote"
                                                    label={`Estimation ${group.group}`}
                                                    value={
                                                        finalEstimations.find((est) => est.group === group.group)
                                                            ?.value || 0
                                                    }
                                                    onChange={(e) =>
                                                        setFinalEstimation(
                                                            group.group,
                                                            Number.parseInt(e.target.value) || 0,
                                                        )
                                                    }
                                                    variant="outlined"
                                                />
                                            </TableCell>
                                        )}
                                    </TableRow>
                                ))}
                                {isSm && (
                                    <TableRow>
                                        <TableCell colSpan={4}></TableCell>
                                        <TableCell align="right">
                                            <strong>Total</strong>
                                        </TableCell>
                                        <TableCell align="right">
                                            <strong>{total}</strong>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </>
    );
}
