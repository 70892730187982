import { Box, Button, Card, Toolbar } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Formik } from "formik";
import React, { useMemo } from "react";
import * as yup from "yup";
import { JiraApi } from "../../helpers/JiraApi";
import { Config } from "../../interfaces/Config";
import { JiraInstanceCredentials } from "../../interfaces/JiraInstanceCredentials";
import { JqlQueryBuilder } from "./JqlQueryBuilder/JqlQueryBuilder";
import { jqlQueryBuilderValidationSchema } from "./JqlQueryBuilder/JqlQueryBuilderValidationSchema";
import { TicketViewCustomFields } from "./TicketViewCustomFields/TicketViewCustomFields";
import { VotingSettings } from "./VotingSettings/VotingSettings";
import { VotingSettingsValidationSchema } from "./VotingSettings/VotingSettingsValidationSchema";

export interface ConfigWizardPros {
    isFirstLoad: boolean;
    onSave: (newConfig: Config) => void;
    className?: string;
    initConfig: Config;
}

const validationSchemas = [jqlQueryBuilderValidationSchema, yup.object(), VotingSettingsValidationSchema];

export function ConfigWizard({ isFirstLoad, className, onSave, initConfig }: ConfigWizardPros) {
    const jiraApi: JiraApi = useMemo(() => {
        return new JiraApi(initConfig.jiraInstance as JiraInstanceCredentials);
    }, [initConfig.jiraInstance]);
    const [currentTab, setTab] = React.useState(0);

    return (
        <Formik
            initialValues={initConfig}
            validationSchema={validationSchemas[currentTab]}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onSubmit={() => {}}
            validateOnChange={true}
        >
            {({ validateForm, setSubmitting, setTouched, values }) => (
                <Card className={className}>
                    <AppBar position="static" color="default">
                        <Toolbar>
                            <Tabs
                                value={currentTab}
                                onChange={(_, newValue) => {
                                    setSubmitting(true);
                                    validateForm().then((errs) => {
                                        if (Object.keys(errs).length === 0) {
                                            setTab(newValue);
                                            setSubmitting(false);
                                            setTouched({});
                                        }
                                    });
                                }}
                                aria-label="config option"
                                style={{ flex: "1" }}
                            >
                                <Tab label="Tickets for refinement" />
                                <Tab label="Ticket preview" />
                                <Tab label="Voting settings" />
                                <Tab label="" />
                            </Tabs>
                            <Button
                                autoFocus
                                color="inherit"
                                disabled={values.jql === undefined || values.isJqlValid !== true}
                                onClick={() => {
                                    setSubmitting(true);
                                    validateForm().then((errs) => {
                                        if (Object.keys(errs).length === 0) {
                                            onSave(values);
                                        }
                                    });
                                }}
                            >
                                save
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <TabPanel index={currentTab}>
                        {
                            [
                                <JqlQueryBuilder jiraApi={jiraApi} />,
                                <TicketViewCustomFields jiraApi={jiraApi} />,
                                <VotingSettings enabled={isFirstLoad} />,
                            ][currentTab]
                        }
                    </TabPanel>
                </Card>
            )}
        </Formik>
    );
}

function TabPanel(props: { children?: React.ReactNode; index: number }) {
    const { children, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            style={{
                minHeight: "200px",
                padding: "2em",
            }}
            {...other}
        >
            <Box>{children}</Box>
        </div>
    );
}
