import * as colors from "colors";
import * as moo from "moo";
import { getDefaultState, JqlState } from "./JqlState";
import { specification } from "./languageSpecification";
import { processOrderToken } from "./processOrderToken";
import { processQueryToken } from "./processQueryToken";

export function processJql(jql: string, debug = false) {
    let state: JqlState = { ...getDefaultState() };

    const lexer = moo.compile(specification);
    const fixedJql = jql;
    lexer.reset(fixedJql);
    let token: moo.Token | undefined;
    do {
        token = lexer.next();
        if (!token) {
            break;
        }
        state.text = token.value.trim() === "" ? "" : token.value;
        if (token.type === "orderKeyword") {
            state.activeMode = "orderBy";
            state.activeSection = "field";
        }
        if (state.activeMode === "query") {
            state = processQueryToken(token, state);
        } else if (state.activeMode === "orderBy") {
            state = processOrderToken(token, state);
        }

        if (debug) {
            console.log(colors.yellow(JSON.stringify({ token })));
            console.log(colors.green(JSON.stringify({ state })));
        }
    } while (token !== undefined);
    return state;
}
