import { Grid, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import useLocalStorage from "react-use/lib/useLocalStorage";
import { ActiveJiraTicketConnected } from "../components/ActiveJiraTicketConnected/ActiveJiraTicketConnected";
import { CopyLinkButton } from "../components/CopyLinkButton/CopyLinkButton";
import { JiraTickersWithSimilarVoteConnected } from "../components/JiraTickersWithSimilarVoteConnected/JiraTickersWithSimilarVoteConnected";
import SideBar from "../components/SideBar/SideBar";
import { JoinScreen } from "../components/UserJoinScreen/JoinScreen";
import { VoteCardsWithUsersConnected } from "../components/VoteCardsWithUsersConnected/VoteCardsWithUsersConnected";
import { getMemberRoomToken } from "../helpers/memberRoomToken";
import { UserState, UserType } from "../interfaces/ActiveUser";
import { JWT_TOKEN_LOCAL_STORAGE_KEY, WS_URL_LOCAL_STORAGE_KEY } from "../interfaces/LocalStorageKeys";
import { RoomState } from "../interfaces/RoomState";
import { SessionRestored } from "../store/actions/SessionRestored";
import "./sessionBox.css";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: "35px",
            height: "90vh",
        },
        innerGrid: {
            maxWidth: "1920px",
        },
        control: {
            padding: theme.spacing(2),
        },
        voteSection: {
            display: "flex",
            justifyContent: "center",
        },
        ticketGrid: {
            width: "1000px",
        },
    }),
);

function PokerSessionPage() {
    const classes = useStyles();
    const { roomId } = useParams<{ roomId: string }>();
    const dispatch = useDispatch();
    const { roomId: activeRoomId, userType, state } = useSelector((state: RoomState) => state.activeUser);
    const [wsUrl] = useLocalStorage<string>(WS_URL_LOCAL_STORAGE_KEY, undefined, { raw: true });

    if (state === UserState.EMPTY) {
        const foundToken = getMemberRoomToken(roomId);
        if (foundToken) {
            dispatch(SessionRestored(foundToken.decoded, foundToken.token, wsUrl as string));
        } else if (window.localStorage.getItem(JWT_TOKEN_LOCAL_STORAGE_KEY)) {
            return <Redirect to="/" />;
        }
    }

    if (state === UserState.LOADING) {
        return <></>;
    }

    if (roomId !== activeRoomId) {
        return (
            <div className="SessionBox">
                <JoinScreen roomId={roomId} />
            </div>
        );
    }

    return (
        <SideBar>
            <Grid container className={classes.root} justifyContent="center">
                <Grid item xs={12} sm={12} md={11} className={classes.innerGrid}>
                    <Grid container spacing={2} justifyContent="space-around">
                        <Grid item xs={12} sm={7} className={classes.ticketGrid}>
                            <ActiveJiraTicketConnected />
                        </Grid>
                        <Grid item xs={12} sm={5} style={{ overflow: "scroll", height: "calc(100vh - 100px)" }}>
                            <Grid container direction="column" alignItems="center" justifyContent="center">
                                {userType === UserType.SCRUM_MASTER && <CopyLinkButton roomId={roomId} />}
                                <VoteCardsWithUsersConnected />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <JiraTickersWithSimilarVoteConnected />
                    </Grid>
                </Grid>
            </Grid>
        </SideBar>
    );
}

export default PokerSessionPage;
