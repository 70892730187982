import React from "react";
import "./UserBubble.css";

interface SeeMoreBubbleProps {
    count: number;
    includeFlipId: boolean;
}
export function SeeMoreBubble({ count, includeFlipId }: SeeMoreBubbleProps) {
    return (
        <div className="bubble bubble__see-more" data-flip-id={includeFlipId && `see-more-${count}`}>
            <div className="circle" style={{ backgroundColor: "#6200EE" }}>
                {<div className="label">{`+${count}`}</div>}
            </div>
        </div>
    );
}
