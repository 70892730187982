import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import { Dispatch, SetStateAction } from "react";

import { ConfigurableCard } from "./ConfigurableCard";

export function ConfigurableCardsList({
    cards,
    setCards,
}: {
    cards: string[];
    setCards: Dispatch<SetStateAction<string[]>>;
}) {
    const sensors = useSensors(useSensor(PointerSensor));

    return (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={cards} strategy={rectSortingStrategy}>
                {cards.map((value, index) => (
                    <ConfigurableCard
                        id={value}
                        key={value}
                        value={value}
                        setValue={(newValue) => setValue(index, newValue)}
                        onRemove={() => removeItem(index)}
                    />
                ))}
            </SortableContext>
        </DndContext>
    );

    function setValue(index: number, value: string) {
        setCards(cards.map((item, i) => (i === index ? value : item)));
    }
    function removeItem(index: number) {
        setCards(cards.filter((_, i) => i !== index));
    }

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;

        if (active.id !== over?.id) {
            const oldIndex = cards.indexOf(active.id);
            const newIndex = cards.indexOf(over?.id || "");

            setCards(arrayMove(cards, oldIndex, newIndex));
        }
    }
}
