import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { JiraApi } from "../../../helpers/JiraApi";
import { handleJqlAutocomplete } from "../../../helpers/jqlParser/handleAutocomplete";
import { JiraAutocompleteData } from "../../../interfaces/JiraAutocompleteData";
import { SelectValueObject } from "../../UI/SelectWithAutocomplete";
export interface JqlInputWithAutocompleteProps {
    jql: string;
    setJql: (jql: string) => void;
    autocompleteData: JiraAutocompleteData;
    jiraApi: JiraApi;
    disabled?: boolean;
    isValid: boolean;
}

export function JqlInputWithAutocomplete({
    setJql,
    jql,
    jiraApi,
    autocompleteData,
    disabled,
    isValid,
}: JqlInputWithAutocompleteProps) {
    const { data, isLoading } = useQuery(
        `autocomplete-suggestions-${disabled}-${jql}`,
        () => handleJqlAutocomplete(disabled ? "" : jql, autocompleteData, jiraApi),
        { staleTime: Infinity },
    );
    const [isFocused, setIsFocused] = useState(false);

    const [options = [], autocompleteState] = data || [[], undefined];

    const onValueSelected = (selected: string) => {
        const userInputLength = autocompleteState?.text?.length || 0;
        let newValue = `${selected} `;

        if (
            !autocompleteState?.queryBlock.isArrayValue &&
            ["IN", "NOT IN"].includes(autocompleteState?.queryBlock.operator?.toUpperCase() || "")
        ) {
            newValue = `(${selected}, `;
        }
        if (autocompleteState?.activeSection === "value" && autocompleteState?.queryBlock?.isArrayValue) {
            newValue = `${selected}, `;
        }
        setJql(`${userInputLength ? jql.slice(0, -userInputLength) : jql}${newValue}`);
    };

    return (
        <Autocomplete
            inputValue={jql}
            onInputChange={(_, value, reason) => {
                if (reason === "input") {
                    setJql(value);
                }
            }}
            loading={isLoading}
            options={options || []}
            filterOptions={(options) => options}
            open={options.length > 0 && isFocused}
            value={null}
            disabled={disabled}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            isOptionEqualToValue={(option: SelectValueObject, value: SelectValueObject) => option.name === value.name}
            getOptionLabel={(option: SelectValueObject) => option.name}
            onChange={(_, v) => v?.id && onValueSelected(v?.id)}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        value={jql}
                        error={!isValid}
                        variant="outlined"
                        fullWidth
                        multiline
                        maxRows={3}
                        label="jql"
                    />
                );
            }}
        />
    );
}
