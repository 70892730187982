export const ROOM_CREATE_STARTED = "ROOM_CREATE_STARTED";

export interface RoomCreateStartedAction {
    type: typeof ROOM_CREATE_STARTED;
}

export const RoomCreateStarted = (): RoomCreateStartedAction => {
    return {
        type: ROOM_CREATE_STARTED,
    };
};
