import { Box, TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { JiraApi } from "../../helpers/JiraApi";
import { JiraTicket } from "../../interfaces/JiraTicket";
import { RoomState } from "../../interfaces/RoomState";
import { ActiveTicketChanged } from "../../store/actions/ActiveTicketChanged";
import { RefreshOrAddTicket } from "../../store/actions/TicketAddedOrUpdated";
import { defaultFilterOptions } from "../ActiveJiraTicketConnected/ActiveJiraTicketConnected";

export function SelectActiveTicket({ className }: { className?: string }) {
    const ticket = useSelector((state: RoomState) => state.activeTicket);
    const ticketList = useSelector((state: RoomState) => state.ticketList);
    const jiraInstance = useSelector((state: RoomState) => state.config.jiraInstance);

    const jiraApi = useMemo(() => jiraInstance && new JiraApi(jiraInstance), [jiraInstance]);

    const [currentText, setCurrentText] = useState<string>("");
    const { data: ticketPropositions } = useQuery(`ticket-propositions-${currentText}`, () =>
        jiraApi?.getTicketsPickerResults(currentText),
    );
    const dispatch = useDispatch();

    return (
        <Autocomplete
            className={className}
            disableClearable
            options={ticketList}
            value={ticket || undefined}
            onChange={(_, newTicket) => {
                if (newTicket.isPlaceholder) {
                    dispatch(RefreshOrAddTicket(newTicket.ticketId, { setAsActive: true, resetVotes: true }));
                    setCurrentText("");
                    return;
                }
                dispatch(ActiveTicketChanged(newTicket));
            }}
            onInputChange={(_, v) => setCurrentText(v)}
            filterOptions={(options, state) => {
                const results = defaultFilterOptions(options, state);

                if (results.length) {
                    return results;
                }

                if (ticketPropositions?.length) {
                    return ticketPropositions.map(
                        ({ id, name }) => ({ ticketId: id, title: name, isPlaceholder: true }) as JiraTicket,
                    );
                }
                return [];
            }}
            renderOption={(props, option) => (
                <Box component={"li"} style={{ display: "block" }} {...props}>
                    {option.isPlaceholder && (
                        <Typography variant="subtitle2" display="block">
                            Add ticket to the list
                        </Typography>
                    )}
                    <Typography
                        variant="caption"
                        style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                    >
                        <strong>{option.ticketId}</strong> {option?.title}
                    </Typography>
                </Box>
            )}
            getOptionLabel={(ticket) => ticket.ticketId}
            isOptionEqualToValue={(option, value) => option.ticketId === value.ticketId}
            renderInput={(params) => <TextField {...params} label="Active ticket" variant="outlined" />}
        />
    );
}
