import { Theme } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

export const useJiraTicketStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxHeight: "68vh",
            width: "100%",
            height: "100%",
        },
        cardContent: {
            display: "flex",
            flexDirection: "column",
            height: "100%",
        },
        header: {},
        body: {
            flexGrow: 1,
            overflow: "auto",
        },
        content: {
            fontSize: 14,
            "& a": {
                color: theme.palette.mode === "dark" ? theme.palette.text.primary : theme.palette.primary.main,
                textDecoration: theme.palette.mode === "dark" ? "underline" : "none",
            },
            "& p:first-of-type": {
                marginTop: 0,
            },
        },
        reporter: {
            fontSize: 14,
            paddingBottom: 10,
        },
        link: {
            color: theme.palette.mode === "dark" ? theme.palette.text.primary : theme.palette.primary.main,
            textDecoration: theme.palette.mode === "dark" ? "underline" : "none",
        },
        chip: {
            margin: theme.spacing(0.5),
            "& div": {
                display: "flex",
                alignItems: "center",
            },
        },
        item: {
            paddingTop: "15px",
            paddingBottom: "15px",
        },
    }),
);
