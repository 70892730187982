import {
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    FormControl,
    FormHelperText,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import Icon from "@mui/material/Icon";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { MemoryRouter as Router } from "react-router";
import useLocalStorage from "react-use/lib/useLocalStorage";
import * as yup from "yup";
import { useRoomInfo } from "../../hooks/useRoomInfo";
import { MEMBER_NAME_AND_NICK_LOCAL_STORAGE_KEY } from "../../interfaces/LocalStorageKeys";
import { JoinRoom } from "../../store/actions/JoinRoom";
import { RoomState } from "../../interfaces/RoomState";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minWidth: 275,
            maxWidth: 600,
            "& .MuiTextField-root": {
                margin: theme.spacing(2),
            },
            "& .MuiFormControl-root": {
                margin: theme.spacing(2),
            },
            paddingRight: 10,
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
        button: {
            margin: theme.spacing(1),
        },
    }),
);

export function JoinScreen({ roomId }: { roomId: string }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const wasKicked = useSelector((state: RoomState) => state.activeUser.wasKicked);

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    useEffect(() => {
        if (wasKicked) {
            setIsDialogOpen(true);
        }
    }, [wasKicked]);

    const { data: roomInfo, isLoading, isError } = useRoomInfo(roomId);
    const [defaultValue] = useLocalStorage(MEMBER_NAME_AND_NICK_LOCAL_STORAGE_KEY, {
        name: "",
        shortName: "",
    });

    if (isLoading) {
        return <CircularProgress />;
    }
    if (isError) {
        return (
            <>
                <h1>Room not found</h1>
                <span>
                    Looks like the room you are trying to join does not exist. <br />
                    Please contact creator of the room. <br />
                    Verify if the link is correct.
                </span>
            </>
        );
    }

    const closeDialog = () => {
        setIsDialogOpen(false);
    };

    return (
        <Formik
            initialValues={defaultValue as { name: string; shortName: string; group?: string }}
            validationSchema={yup.object({
                name: yup.string().required("Name is required"),
                shortName: yup
                    .string()
                    .required("shortname is required")
                    .max(5, "Shortname must have less then 6 characters"),
                ...(roomInfo?.config?.votingGroups
                    ? {
                          group: yup.string().oneOf(roomInfo.config.votingGroups).required(),
                      }
                    : undefined),
            })}
            onSubmit={async (values) =>
                dispatch(
                    JoinRoom(
                        {
                            name: values.name,
                            nick: values.shortName,
                            group: values.group,
                            roomId,
                        },
                        roomInfo,
                    ),
                )
            }
        >
            {({ handleBlur, handleChange, handleSubmit, values, touched, isSubmitting, errors, isValid }) => (
                <Card className={classes.root}>
                    <form className={classes.root} onSubmit={handleSubmit} noValidate autoComplete="off">
                        <CardContent>
                            <Typography variant="h5" component="h2">
                                Accept pointing session invitation
                            </Typography>

                            <div>
                                <TextField
                                    fullWidth
                                    id="name"
                                    label="Name"
                                    variant="outlined"
                                    defaultValue={defaultValue?.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={!!errors.name && (isSubmitting || touched.name || !!values.name)}
                                    helperText={errors.name}
                                />

                                <TextField
                                    fullWidth
                                    id="shortName"
                                    label="Short Name"
                                    variant="outlined"
                                    defaultValue={defaultValue?.shortName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        !!errors.shortName && (isSubmitting || touched.shortName || !!values.shortName)
                                    }
                                    helperText={errors.shortName}
                                />

                                {roomInfo?.config?.votingGroups && (
                                    <FormControl fullWidth error={!!errors.group}>
                                        <InputLabel id="voting-group-label">group</InputLabel>
                                        <Select
                                            labelId="voting-group-label"
                                            value={values.group}
                                            label="Group"
                                            name="group"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        >
                                            {roomInfo?.config?.votingGroups.map((groupName) => (
                                                <MenuItem key={groupName} value={groupName}>
                                                    {groupName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{errors.group}</FormHelperText>
                                    </FormControl>
                                )}
                            </div>
                        </CardContent>
                        <CardActions>
                            <Router>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    endIcon={<Icon>add</Icon>}
                                    type="submit"
                                    disabled={isSubmitting || !isValid}
                                >
                                    {isSubmitting ? "Wait..." : `Join`}
                                </Button>

                                <Dialog open={isDialogOpen} onClose={closeDialog}>
                                    <DialogTitle>You were kicked out by SM</DialogTitle>
                                    <DialogContent>You can rejoin using the regular form.</DialogContent>
                                    <DialogActions>
                                        <Button onClick={closeDialog} color="primary">
                                            OK
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Router>
                        </CardActions>
                    </form>
                </Card>
            )}
        </Formik>
    );
}
