import BlockIcon from "@mui/icons-material/Block";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import React from "react";

export function PriorityIcon({ priority }: { priority: string }) {
    switch (priority?.toLowerCase()) {
        case "critical":
            return <BlockIcon style={{ color: "#ed6040" }} />;
        case "high":
            return <PriorityHighIcon style={{ color: "#ed6040" }} />;
        case "normal":
            return <DragHandleIcon style={{ color: "#f3ad3d" }} />;
        case "low":
            return <LowPriorityIcon style={{ color: "#2467f6" }} />;
        default:
            return <></>;
    }
}
