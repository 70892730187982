import { AvailableVoter } from "../../../store/actions/SetAvailableVoters";

export interface VotingSettingsConfig {
    displayVoteInBubble?: boolean;
    isVoting: boolean;
    name?: string;
    shortName?: string;
    group?: string;
    cards: string[];
    votingGroupsEnabled?: boolean;
    votingGroups?: string[];
    groupedVotesSaveMethod?: GroupedVotesSaveMethod;
    availableVoters: AvailableVoter[];
}

export enum GroupedVotesSaveMethod {
    SUBTASK = "subtask",
    COMMENT = "comment",
}
