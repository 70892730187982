import { Actions } from "../actions/actions";
import { LOGOUT } from "../actions/Logout";
import { RESET_VOTES } from "../actions/ResetVotes";
import { VOTES_REVEALED } from "../actions/VotesRevealed";
export function revealVotesReducer(state = false, action: Actions): boolean {
    switch (action.type) {
        case VOTES_REVEALED:
            return true;
        case LOGOUT:
        case RESET_VOTES:
            return false;
        default:
            return state;
    }
}
