import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { FlipProvider, useFlip } from "react-easy-flip";
import { User } from "../../interfaces/User";
import { UserList } from "../UserList/UserList";
import { VoteCards } from "../VoteCards/VoteCards";
import "./VoteCardsWithUsers.css";
export enum AnimationMode {
    IN_PACE,
    MOVE_CARDS,
}
interface VoteCardsWithUsersProps {
    showVotes: boolean;
    users: User[];
    possibleScores: string[];
    keepBubblesInPlace: boolean;
    votePossible: boolean;
    removeVotePossible?: boolean;
    userVote?: string;
    selectVote: (vote?: string) => void;
    setFilter: (filter: string) => void;
    groups?: string[];
    filter?: string;
    isSm: boolean;
}
export function VoteCardsWithUsers({
    showVotes,
    users,
    possibleScores,
    keepBubblesInPlace,
    votePossible,
    removeVotePossible,
    userVote,
    selectVote,
    setFilter,
    groups,
    filter,
    isSm,
}: VoteCardsWithUsersProps) {
    const domItemsId = "flip-card-items";
    const userChangeDetectString = users.map((user) => user.nick).join("");
    useFlip(domItemsId, { duration: 800 }, userChangeDetectString);

    return (
        <div className="vote-cards-with-users">
            <FlipProvider>
                <div data-flip-root-id={domItemsId} className={"vote-cards-with-users"}>
                    {!votePossible && !isSm ? (
                        <h2>You are excluded from voting for this ticket</h2>
                    ) : (
                        <UserList
                            users={users.filter((user) => keepBubblesInPlace || !showVotes || user.vote === undefined)}
                            showVotes={showVotes}
                        />
                    )}
                    {groups && (
                        <ToggleButtonGroup
                            color="primary"
                            value={filter}
                            exclusive
                            onChange={(_, newGroup) => setFilter(newGroup)}
                            fullWidth
                        >
                            <ToggleButton value={""}>All</ToggleButton>
                            {groups.map((group) => (
                                <ToggleButton key={group} value={group}>
                                    {group.toUpperCase()}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    )}
                    <VoteCards
                        possibleScores={possibleScores}
                        users={users}
                        showVotes={showVotes}
                        includeFlipId={!keepBubblesInPlace}
                        votePossible={votePossible}
                        removeVotePossible={removeVotePossible}
                        userVote={userVote}
                        selectVote={selectVote}
                    />
                </div>
            </FlipProvider>
        </div>
    );
}
