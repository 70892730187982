import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { Checkbox } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

export interface SelectValueObject {
    name: string;
    id: string;
}

export interface SelectWithAutocompleteProps<Multiple extends boolean>
    extends Omit<AutocompleteProps<SelectValueObject, Multiple, boolean, boolean>, "renderInput"> {
    label?: string;
    multiple?: Multiple;
    onNewValue: (value?: Multiple extends true ? SelectValueObject[] : SelectValueObject) => void;
    isLoading: boolean;
    error?: boolean;
    helperText?: string;
}
export default function SelectWithAutocomplete<M extends boolean>(props: SelectWithAutocompleteProps<M>) {
    const { label, isLoading, onNewValue, error, helperText, ...restProps } = props;
    return (
        <Autocomplete
            {...restProps}
            isOptionEqualToValue={(option: SelectValueObject, value: SelectValueObject) => option.name === value.name}
            getOptionLabel={(option) => (option as SelectValueObject)?.name}
            loading={isLoading}
            onChange={(_, v) => onNewValue((v as any) || undefined)} // eslint-disable-line @typescript-eslint/no-explicit-any
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    helperText={helperText}
                    variant="outlined"
                    error={error}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            {...(restProps.multiple
                ? {
                      renderOption: (props, option, { selected }) => (
                          <li {...props}>
                              <Checkbox
                                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                              />
                              {option.name}
                          </li>
                      ),
                  }
                : {})}
        />
    );
}
