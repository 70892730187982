import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { List, ListItem, ListItemIcon } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import DeleteIcon from "@mui/icons-material/Delete";
import DragHandleIcon from "@mui/icons-material/DragHandle";
//import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import arrayMove from "array-move";
import { JiraFieldDescriptor } from "../../../interfaces/JiraTicket";

export function SortableListItem({ item, removeItem }: { item: JiraFieldDescriptor; removeItem: () => void }) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes}>
            <ListItem ContainerComponent="div">
                <ListItemIcon {...listeners}>
                    <DragHandleIcon />
                </ListItemIcon>
                <ListItemText primary={item.label} secondary={item.id} />
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={removeItem} size="large">
                        <DeleteIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </div>
    );
}

export function ExtraFieldsList({
    extraFields,
    setFieldValue,
}: {
    extraFields: JiraFieldDescriptor[];
    setFieldValue: (field: string, value: JiraFieldDescriptor[]) => void;
}) {
    const sensors = useSensors(useSensor(PointerSensor));

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;

        if (active.id !== over?.id) {
            const oldIndex = extraFields.findIndex((item) => item.id === active.id);
            const newIndex = extraFields.findIndex((item) => item.id === over?.id);

            const newArray = arrayMove(extraFields, oldIndex, newIndex);
            setFieldValue("extraFields", newArray);
        }
    }

    const removeItem = (id: string) =>
        setFieldValue("extraFields", [...(extraFields ?? []).filter((f) => f.id !== id)]);

    return (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <List dense>
                <SortableContext items={extraFields.map((f) => f.id)} strategy={verticalListSortingStrategy}>
                    {extraFields.map((item) => (
                        <SortableListItem item={item} key={item.id} removeItem={() => removeItem(item.id)} />
                    ))}
                </SortableContext>
            </List>
        </DndContext>
    );
}
