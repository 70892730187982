import { User } from "../../interfaces/User";

export const USER_JOINED = "USER_JOINED";

export interface UserJoinedAction {
    type: typeof USER_JOINED;
    payload: User;
}

export const UserJoined = (payload: User): UserJoinedAction => {
    return {
        type: USER_JOINED,
        payload,
    };
};
