import axios from "axios";
import { useQuery } from "react-query";
import { AppConfig } from "../Config";
import { RoomInfo } from "../interfaces/RoomInfo";

export function useRoomInfo(roomId: string) {
    return useQuery(roomId, async () => (await axios.get<RoomInfo>(`${AppConfig.backendUrl}/rooms/${roomId}`)).data, {
        retry: false,
    });
}
