export interface ActiveUser {
    state: UserState;
    userType?: UserType;
    roomId?: string;
    token?: string;
    wsUrl?: string;
    vote?: string;
    uuid?: string;
    wasKicked?: boolean;
    group?: string;
}

export enum UserType {
    MEMBER,
    SCRUM_MASTER,
}

export enum UserState {
    EMPTY,
    LOADING,
    READY,
}
