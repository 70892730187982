import React, { useEffect, useMemo } from "react";
import { JiraAutocompleteData } from "../../../interfaces/JiraAutocompleteData";
import SelectWithAutocomplete, { SelectValueObject } from "../../UI/SelectWithAutocomplete";

export interface JqlStoryPointSelectorProps {
    error?: boolean;
    helperText?: string;
    autocompleteData: JiraAutocompleteData | undefined;
    value?: SelectValueObject;
    setValue: (value: SelectValueObject) => void;
}
export function JqlStoryPointSelector({
    autocompleteData,
    value,
    setValue,
    error,
    helperText,
}: JqlStoryPointSelectorProps) {
    const storyPointCandidateFields = useMemo(
        () =>
            autocompleteData?.visibleFieldNames
                .filter((f) => !!f.cfid && f.types.includes("java.lang.Number"))
                .map((field) => ({
                    id: field.value,
                    name: field.displayName,
                    cfid: field.cfid?.replace(/cf\[(.*)\]/, "$1"),
                })),
        [autocompleteData],
    );
    useEffect(() => {
        if (value === undefined) {
            const matching = storyPointCandidateFields?.find(({ name }) =>
                name.toLowerCase().startsWith("story points"),
            );
            if (matching) {
                setTimeout(() => setValue(matching), 0);
            }
        }
    }, [storyPointCandidateFields, autocompleteData, value, setValue]);

    return (
        <SelectWithAutocomplete
            error={error}
            helperText={helperText}
            multiple={false}
            label="Field that contain story points"
            isLoading={autocompleteData === undefined}
            options={storyPointCandidateFields || []}
            value={value || null}
            onNewValue={(storyPointsField) => storyPointsField && setValue(storyPointsField)}
        />
    );
}
