import { toast } from "react-toastify";
import { ThunkAction } from "redux-thunk";
import { AppConfig } from "../../Config";
import { JiraApi } from "../../helpers/JiraApi";
import { UserType } from "../../interfaces/ActiveUser";
import { Config } from "../../interfaces/Config";
import { SESSION_CONFIG_STORAGE_LOCAL_STORAGE_KEY } from "../../interfaces/LocalStorageKeys";
import { RoomState } from "../../interfaces/RoomState";
import { Actions } from "./actions";
import { ReferenceTicketsLoaded } from "./ReferenceTicketsLoaded";
import { TicketsListLoaded } from "./TicketsListLoaded";
import { WsCompatibleAction } from "./WsCompatibleAction";

const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time));
export const CONFIG_UPDATED = "CONFIG_UPDATED";

export interface ConfigUpdatedAction extends WsCompatibleAction {
    type: typeof CONFIG_UPDATED;
    payload: Config;
}

export const ConfigUpdated =
    (payload: Partial<Config>, noReload = false): ThunkAction<Promise<void>, RoomState, unknown, Actions> =>
    async (dispatch, getState) => {
        const { cards, estimationMode, votingGroupsEnabled, votingGroups, displayVoteInBubble, availableVoters } =
            payload;

        const availableVotersLatest = [
            ...(votingGroups?.map((v) => ({ value: v, checked: true })) || []),
            ...(availableVoters || []),
        ].filter((voter, index, self) => index === self.findIndex((v) => v.value === voter.value));

        const config = {
            isVoting: false,
            cards: [],
            extraTickets: [],
            availableVoters: availableVotersLatest,
            ...payload,
        };

        dispatch({
            type: CONFIG_UPDATED,
            payload: config,
            sendOverWs: {
                when: (user) => user.userType === UserType.SCRUM_MASTER,
                format: (user) => {
                    return {
                        action: "config",
                        token: user.token,
                        data: JSON.stringify({
                            cards,
                            estimationMode,
                            votingGroupsEnabled,
                            votingGroups: votingGroupsEnabled ? votingGroups : undefined,
                            displayVoteInBubble,
                            availableVoters: availableVotersLatest,
                        }),
                    };
                },
            },
        });
        if (noReload) {
            return;
        }
        const jiraInstance = payload.jiraInstance;
        if (!jiraInstance) {
            console.error("Unexpected state! Missing jira instance on CONFIG_UPDATED");
            return;
        }
        const jiraApi = new JiraApi(jiraInstance);

        localStorage.setItem(
            `${SESSION_CONFIG_STORAGE_LOCAL_STORAGE_KEY}-${jiraInstance.publicBaseUrl}`,
            JSON.stringify({
                ...payload,
                extraTickets: [],
                jiraInstance: undefined,
                availableVoters: availableVotersLatest,
            }),
        );

        const promiseTickets = getJiraTickets(jiraApi, config).then((event) => event && dispatch(event));
        const promiseReferences = getReferenceTickers(jiraApi, config).then((event) => event && dispatch(event));
        await Promise.all([promiseTickets, promiseReferences]);
    };

async function getJiraTickets(jiraApi: JiraApi, payload: Config) {
    try {
        const tickets = await jiraApi.getAllMatchingTickets(payload);

        if (tickets.length === 0) {
            toast.warn("No tickets found matching your query!");
        }

        return TicketsListLoaded(tickets);
    } catch (e) {
        console.error(e);
        toast.error("Problem with fetching jira tickets! Please try again later");
    }
}

async function getReferenceTickers(jiraApi: JiraApi, payload: Config) {
    try {
        const possibleScores = AppConfig.possibleScores.filter((e: string) => e !== "?");
        const referenceTicketsResponse = await jiraApi.getTickets(
            (payload.referenceTicketsJql as string).replace("$SP_VALUES", possibleScores.toString()),
            40,
            payload,
        );
        const referenceTickets = possibleScores
            .map((storyPoint) => ({
                storyPoint,
                tickets: referenceTicketsResponse.filter(
                    (ticket) => ticket.storyPoint?.toString() === storyPoint.toString(),
                ),
            }))
            .map((rt) => ({
                storyPoint: rt.storyPoint,
                tickets: rt.tickets.length < 5 ? rt.tickets : rt.tickets.slice(0, 5),
            }));

        const missingPoints = referenceTickets
            .filter((rt) => rt.tickets.length < 5)
            .map(({ storyPoint }) => storyPoint);

        await Promise.all(
            missingPoints.map(async (missingPoint) => {
                await sleep(Math.floor(Math.random() * 1000));
                const results = await jiraApi.getTickets(
                    (payload.referenceTicketsJql as string).replace("$SP_VALUES", missingPoint),
                    5,
                    payload,
                );
                const sp = referenceTickets.find(({ storyPoint }) => storyPoint === missingPoint);
                if (sp) {
                    sp.tickets = results;
                }
            }),
        );

        return ReferenceTicketsLoaded(referenceTickets);
    } catch (e) {
        console.error(e);
        toast.error("Problem while fetching reference tickets!");
        return ReferenceTicketsLoaded([]);
    }
}
