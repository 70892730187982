import { ThunkAction } from "redux-thunk";
import { AppConfig } from "../../Config";
import { storeMemberRoomToken } from "../../helpers/memberRoomToken";
import { WS_URL_LOCAL_STORAGE_KEY, MEMBER_NAME_AND_NICK_LOCAL_STORAGE_KEY } from "../../interfaces/LocalStorageKeys";
import { RoomInfo } from "../../interfaces/RoomInfo";
import { RoomState } from "../../interfaces/RoomState";
import { Actions } from "./actions";
import { JoinedSession } from "./JoinedSession";
import { JoinRoomStarted } from "./JoinRoomStarted";
import { RoomLoaded } from "./RoomLoaded";

export const JOIN_ROOM_ACTION = "JOIN_ROOM_ACTION";

export interface JoinRoomAction {
    type: typeof JOIN_ROOM_ACTION;
    payload: JoinRoomPayload;
}

export interface JoinRoomPayload {
    name: string;
    nick: string;
    roomId: string;
    group?: string;
}

export const JoinRoom =
    (payload: JoinRoomPayload, roomInfo?: RoomInfo): ThunkAction<Promise<void>, RoomState, unknown, Actions> =>
    async (dispatch) => {
        dispatch(JoinRoomStarted(payload));
        if (roomInfo) {
            dispatch(RoomLoaded(roomInfo));
        }
        const result: CreateRoomResponse = await fetch(`${AppConfig.backendUrl}/rooms/${payload.roomId}/join`, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => response.json());

        const userEvent = {
            token: result.token,
            wsUrl: result.url,
            uuid: result.uuid,
            name: payload.name,
            nick: payload.nick,
            roomId: payload.roomId,
            group: payload.group,
        };

        storeMemberRoomToken(userEvent.token);
        window.localStorage.setItem(WS_URL_LOCAL_STORAGE_KEY, userEvent.wsUrl);
        window.localStorage.setItem(
            MEMBER_NAME_AND_NICK_LOCAL_STORAGE_KEY,
            JSON.stringify({ name: payload.name, shortName: payload.nick, group: payload.group }),
        );

        dispatch(JoinedSession(userEvent));
    };

interface CreateRoomResponse {
    token: string;
    url: string;
    uuid: string;
}
