import { ImageList, ImageListItem, ImageListItemBar, IconButton, Link, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import { AttachmentOutlined } from "@mui/icons-material";
import { JiraAttachment } from "../../interfaces/JiraTicket";
import { FileIcon, defaultStyles } from "react-file-icon";
import { SRLWrapper, useLightbox } from "simple-react-lightbox";

export const useAttachmentsStyles = makeStyles((theme: Theme) =>
    createStyles({
        headline: {
            paddingTop: "15px",
            paddingBottom: "15px",
        },
        root: {
            display: "flex",
            "overflow-y": "hidden",
            "overflow-x": "scroll",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            backgroundColor: theme.palette.background.paper,
        },
        gridList: {
            minWidth: "100%",
            flexWrap: "nowrap",
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: "translateZ(0)",
        },
        attachment: {
            width: "250px !important",
        },
        attachmentBgWrapper: {
            background: "#fff",
            display: "block",
            alignItems: "center",
            height: "100%",
            "& img": {
                width: "100%",
            },
        },
        unknownFileIcon: {
            width: "100%",
            height: "100%",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        attachmentTitle: {
            color: theme.palette.primary.contrastText,
            paddingTop: "15px",
            paddingBottom: "15px",
            cursor: "pointer",
        },
    }),
);
export function Attachments({ attachments }: { attachments: JiraAttachment[] }) {
    const classes = useAttachmentsStyles();
    const { openLightbox } = useLightbox();

    if (attachments.length === 0) {
        return <></>;
    }

    const imageAttachments = attachments.filter((a) => !!a.thumbnail);
    const otherAttachments = attachments.filter((a) => a.thumbnail === undefined);

    return (
        <>
            <Typography variant="h6" component="h2" className={classes.headline}>
                Attachments
            </Typography>
            <div className={classes.root}>
                <SRLWrapper>
                    <ImageList className={classes.gridList}>
                        {imageAttachments.map((attachment, id) => (
                            <ImageListItem className={classes.attachment} key={`attachment-img-${id}`}>
                                <div className={classes.attachmentBgWrapper}>
                                    <Link target="_blank" href={attachment.content}>
                                        <img src={attachment.thumbnail} alt={attachment.fileName} />
                                    </Link>
                                </div>

                                <Link target="_blank" onClick={() => openLightbox(id)}>
                                    <ImageListItemBar
                                        title={attachment.fileName}
                                        classes={{
                                            title: classes.attachmentTitle,
                                        }}
                                        actionIcon={
                                            <IconButton size="large">
                                                <AttachmentOutlined />
                                            </IconButton>
                                        }
                                    />
                                </Link>
                            </ImageListItem>
                        ))}
                        {otherAttachments.map((attachment, id) => (
                            <ImageListItem className={classes.attachment} key={`attachment-${id}`}>
                                <Link target="_blank" href={attachment.content}>
                                    <div className={classes.unknownFileIcon}>
                                        <div style={{ width: "100px" }}>{getExtensionIcon(attachment.fileName)}</div>
                                    </div>
                                    <ImageListItemBar
                                        title={attachment.fileName}
                                        classes={{
                                            title: classes.attachmentTitle,
                                        }}
                                        actionIcon={
                                            <IconButton size="large">
                                                <AttachmentOutlined />
                                            </IconButton>
                                        }
                                    />
                                </Link>
                            </ImageListItem>
                        ))}
                    </ImageList>
                </SRLWrapper>
            </div>
        </>
    );
}

function getExtensionIcon(filename: string) {
    const extension = filename.split(".").pop() || "";
    if (extension === filename) {
        return <FileIcon />;
    }
    const styles = defaultStyles[extension as keyof typeof defaultStyles] || {};

    return <FileIcon extension={extension} {...styles} />;
}
