import { ThunkAction } from "redux-thunk";
import { JiraApi } from "../../helpers/JiraApi";
import { JiraInstanceCredentials } from "../../interfaces/JiraInstanceCredentials";
import { RoomState } from "../../interfaces/RoomState";
import { Actions } from "./actions";
import { toast } from "react-toastify";
import { EstimationMode } from "../../components/ConfigWizard/JqlQueryBuilder/JqlQueryBuilderConfig";
import { FinalGroupEstimations } from "../../components/VoteSummaryForGroups/VoteSummaryForGroups";
import { Config } from "../../interfaces/Config";
import { GroupedVotesSaveMethod } from "../../components/ConfigWizard/VotingSettings/VotingSettingsConfig";

export const SAVE_VOTE = "SAVE_VOTE";

export interface SaveVoteAction {
    type: typeof SAVE_VOTE;
    payload: {
        vote?: string | FinalGroupEstimations;
        ticketId: string;
    };
}

export const SaveVote =
    (
        ticketId: string,
        vote?: string | FinalGroupEstimations,
    ): ThunkAction<Promise<void>, RoomState, unknown, Actions> =>
    async (dispatch, getState) => {
        dispatch({
            type: SAVE_VOTE,
            payload: {
                vote,
                ticketId,
            },
        });

        const { ticketList, config } = getState();
        const unestimatedTickets = ticketList.filter((t) => t.voted !== true);

        if (unestimatedTickets.length === 0) {
            toast.info("All tickets has been estimated!");
        }
        if (!vote) {
            return;
        }

        try {
            const jiraApi = new JiraApi(config.jiraInstance as JiraInstanceCredentials);
            if (config.votingGroupsEnabled) {
                if (config.groupedVotesSaveMethod === GroupedVotesSaveMethod.COMMENT) {
                    await storeGroupBasedEstimationToComment(config, jiraApi, ticketId, vote as FinalGroupEstimations);
                } else {
                    await storeGroupBasedEstimationToSubtasks(config, jiraApi, ticketId, vote as FinalGroupEstimations);
                }
            } else {
                await storeEstimationToTicket(config, jiraApi, ticketId, vote as string);
            }

            toast.info(`Estimate for ticket ${ticketId} has been saved!`);
        } catch (e) {
            console.error(e);
            toast.error(`Error while saving estimate for ticket ${ticketId}. Estimate has not be saved`);
        }
    };

async function storeEstimationToTicket(config: Config, api: JiraApi, ticketId: string, vote: string) {
    const { estimationMode, storyPointsField } = config;

    if (estimationMode === EstimationMode.HOURS) {
        await api.setEstimatedTime(ticketId, Number.parseFloat(vote));
    } else if (estimationMode === EstimationMode.MANDAYS) {
        await api.setEstimatedDays(ticketId, Number.parseFloat(vote));
    } else {
        await api.setStoryPoints(ticketId, storyPointsField?.cfid as string, Number.parseFloat(vote));
    }
}

async function storeGroupBasedEstimationToSubtasks(
    config: Config,
    api: JiraApi,
    ticketId: string,
    votes: FinalGroupEstimations,
) {
    const subtasks = await api.getTicketSubtasks(ticketId);
    for (const group of votes as FinalGroupEstimations) {
        const subtaskDetails = subtasks.fields.subtasks.find((subtask) => subtask.fields.summary === group.group);
        let subtaskId: string;
        if (subtaskDetails) {
            subtaskId = subtaskDetails.key;
            console.log(`Found existing subtask ${subtaskId} for ${group.group}`);
        } else {
            console.log("Subtask not found, creating...");
            subtaskId = await api.createSubTask({
                parentTicketId: ticketId,
                projectKey: subtasks.fields.project.key,
                title: group.group,
            });
            console.log(`Created subtask ${subtaskId}`);
        }

        console.log(group);
        console.log(`Saving estimation to ${group.value.toString()} for ${group.group} to ticket ${subtaskId}`);
        await storeEstimationToTicket(config, api, subtaskId, group.value.toString());
    }
}

async function storeGroupBasedEstimationToComment(
    config: Config,
    api: JiraApi,
    ticketId: string,
    votes: FinalGroupEstimations,
) {
    const totalVote = votes.reduce((sum, v) => sum + v.value, 0);
    const unit = getEstimationUnit(config.estimationMode);
    const comment = `h1. *Estimation*\n` + votes.map((group) => `*${group.group}:* ${group.value}${unit}`).join("\n");

    await storeEstimationToTicket(config, api, ticketId, totalVote.toString());
    await api.saveComment(ticketId, comment);
}

function getEstimationUnit(mode: EstimationMode | undefined) {
    switch (mode) {
        case EstimationMode.HOURS:
            return "h";
        case EstimationMode.MANDAYS:
            return "days";
        default:
            return "";
    }
}
