import axios, { AxiosError, AxiosInstance } from "axios";
import { AppConfig } from "../Config";
import { Config } from "../interfaces/Config";
import { JiraAutocompleteData } from "../interfaces/JiraAutocompleteData";
import { JiraInstanceCredentials } from "../interfaces/JiraInstanceCredentials";
import { JiraIssueResponse, JiraIssuesResponse } from "../interfaces/JiraIssuesResponse";
import { JiraIssueTypeResponseItem } from "../interfaces/JiraIssueTypeResponseItem";
import { JiraProjectListResponse } from "../interfaces/JiraProjectListResponse";
import { JiraFieldDescriptor } from "../interfaces/JiraTicket";
import { JiraTicketsForListResponse } from "../interfaces/JiraTicketsForListResponse";
import { JiraTicketSubtasksResponse } from "../interfaces/JiraTicketSubtasksResponse";
import { VerifyJiraResults } from "../interfaces/VerifyJiraResults";
import { convertJiraTicket } from "./convertJiraTicket";
export class JiraApi {
    public axios: AxiosInstance;
    constructor(private options: JiraInstanceCredentials) {
        this.axios = axios.create({
            baseURL: this.options.url,
            auth: {
                password: this.options.password,
                username: this.options.login,
            },
        });
    }
    get publicBaseUrl() {
        return this.options.publicBaseUrl;
    }

    public async getProjects() {
        return (await this.axios.get<JiraProjectListResponse[]>("/rest/api/2/project")).data;
    }

    public async getFields(): Promise<JiraFieldDescriptor[]> {
        return (await this.axios.get<JiraFieldResponse[]>("/rest/api/2/field")).data
            .filter(
                (v) =>
                    (v?.schema && ["string", "number", "comments-page"].includes(v?.schema?.type)) ||
                    (v?.schema?.type === "array" &&
                        ["string", "number", "component", "attachment"].includes(v.schema.items)),
            )
            .map((field) => {
                if (field.id === "comment") {
                    return {
                        id: field.id,
                        label: field.name,
                        type: "array",
                        items: "comment",
                    };
                }

                if (field.id === "components") {
                    return {
                        id: field.id,
                        label: field.name,
                        type: "array",
                        items: "string",
                    };
                }

                return {
                    id: field.id,
                    label: field.name,
                    type: field?.schema?.type as "string" | "number" | "array",
                    items: field?.schema?.items,
                };
            });
    }

    public async getIssueTypes() {
        return (await this.axios.get<JiraIssueTypeResponseItem[]>("/rest/api/2/issuetype")).data;
    }

    public async getAutocompleteData() {
        return (await this.axios.get<JiraAutocompleteData>("/rest/api/2/jql/autocompletedata")).data;
    }

    public async getAutocompleteSuggestions(fieldName: string, fieldValue: string) {
        return (
            await this.axios.get<JiraAutocompleteSuggestions>("/rest/api/2/jql/autocompletedata/suggestions", {
                params: {
                    fieldName,
                    fieldValue,
                },
            })
        ).data.results.map((result) => ({
            name: result.displayName.replace(/(<([^>]+)>)/gi, ""),
            id: result.value,
        }));
    }

    public async getTicketsForList(jql: string, startAt = 0) {
        if (!jql) {
            return { startAt: 0, maxResults: 1, total: 0, issues: [] };
        }
        return (
            await this.axios.get<JiraTicketsForListResponse>("/rest/api/2/search", {
                params: {
                    maxResults: 10,
                    jql,
                    fields: "summary,issuetype",
                    startAt,
                },
            })
        ).data;
    }

    public async validateJql(jql: string) {
        try {
            await this.axios.get<JiraTicketsForListResponse>("/rest/api/2/search", {
                params: {
                    maxResults: 1,
                    validateQuery: "strict",
                    jql,
                    fields: "summary",
                },
            });
            return true;
        } catch {
            return false;
        }
    }

    public async getTicketsPickerResults(query: string) {
        if (query?.trim() === "") {
            return [];
        }
        const tickets = await (
            await this.axios.get<JiraPickerResponse>("/rest/api/2/issue/picker", {
                params: {
                    query,
                    currentJQL: `issuekey = ${query}`,
                },
            })
        ).data.sections
            .map((s) => s.issues)
            .flat()
            .map((i) => ({ id: i.key, name: i.summaryText }));
        const uniqueTicketIds = [...new Set(tickets.map(({ id }) => id))];
        return uniqueTicketIds.map((id) => tickets.find((t) => t.id === id)) as { id: string; name: string }[];
    }

    public async getRawTicket(issueKey: string) {
        return (
            await this.axios.get<JiraIssueResponse>(`/rest/api/2/issue/${issueKey}`, {
                params: { expand: "renderedFields" },
            })
        ).data;
    }
    public async getRawTickets(jql: string, limit = 100, fields?: string | undefined) {
        const params = {
            jql: jql,
            expand: "renderedFields",
            maxResults: limit,
            fields,
        };

        return (await this.axios.get<JiraIssuesResponse>("/rest/api/2/search", { params })).data.issues;
    }

    public async getTickets(jql: string, limit = 100, config: Config) {
        const storyPointFieldName = `customfield_${config.storyPointsField?.cfid}`;
        const mandatoryFields = [
            "key",
            "description",
            "summary",
            "reporter",
            "priority",
            "components",
            "comment",
            "issuetype",
        ];
        const extraFields = config.extraFields || [];

        const fields = [...mandatoryFields, ...extraFields.map((f) => f.id)];
        if (storyPointFieldName) {
            fields.push(storyPointFieldName);
        }

        return (await this.getRawTickets(jql, limit, fields.join(","))).map((issue) => {
            const converted = convertJiraTicket(issue, this.publicBaseUrl, extraFields, storyPointFieldName);
            const isAddOne = config.extraTickets.includes(issue.key);
            return { ...converted, isAddOne };
        });
    }

    public async getAllMatchingTickets(config: Config) {
        const orderString = config.jql?.includes("ORDER BY") ? "" : ` ORDER BY Rank ASC`;
        const jql = config.extraTickets?.length
            ? `(${config.jql}) OR issuekey IN (${config.extraTickets.join(",")})${orderString}`
            : `${config.jql}${orderString}`;
        return this.getTickets(jql, 100, config);
    }

    public async getTicket(ticketId: string, config: Config) {
        return (await this.getTickets(`issuekey = "${ticketId}"`, 1, config))[0];
    }

    public async setStoryPoints(issue: string, storyPointFieldCfId: string, estimate: number) {
        await this.axios.put(`/rest/api/2/issue/${issue}`, {
            fields: {
                [`customfield_${storyPointFieldCfId}`]: estimate,
            },
        });
    }

    public async setEstimatedTime(issue: string, estimate: number) {
        await this.axios.put(`/rest/api/2/issue/${issue}`, {
            fields: {
                timetracking: {
                    originalEstimate: `${estimate}h`,
                },
            },
        });
    }

    public async setEstimatedDays(issue: string, estimate: number) {
        await this.axios.put(`/rest/api/2/issue/${issue}`, {
            fields: {
                timetracking: {
                    originalEstimate: `${estimate}d`,
                },
            },
        });
    }

    public async saveComment(issue: string, comment: string) {
        await this.axios.post(`/rest/api/2/issue/${issue}/comment`, {
            body: comment,
        });
    }

    public async getTicketSubtasks(ticketId: string) {
        return (
            await this.axios.get<JiraTicketSubtasksResponse>(`/rest/api/2/issue/${ticketId}`, {
                params: { fields: "subtasks,project" },
            })
        ).data;
    }

    public async createSubTask({
        parentTicketId,
        title,
        projectKey,
    }: {
        parentTicketId: string;
        title: string;
        projectKey: string;
    }) {
        return (
            await this.axios.post<{ key: string }>(`/rest/api/2/issue`, {
                fields: {
                    project: {
                        key: projectKey,
                    },
                    parent: {
                        key: parentTicketId,
                    },
                    summary: title,
                    issuetype: {
                        id: "5",
                    },
                },
            })
        ).data.key;
    }

    static async verifyJiraUrl(inputUrl: string): Promise<VerifyJiraResults | invalidJiraResult> {
        if (!inputUrl) {
            return { isValid: false };
        }
        try {
            new URL(inputUrl);
        } catch {
            throw new Error("Invalid URL");
        }

        try {
            const serverInfo = await getServerInfo(inputUrl);
            if (serverInfo.deploymentType === "Server") {
                // Jira server does not have CORS issues with connecting to server info endpoint, checking other endpoint to verify
                // if proxy is not needed
                try {
                    await JiraApi.validateCredentials(serverInfo.url, "", "");
                } catch (e) {
                    if (!(e instanceof InvalidCredentialsError)) {
                        throw new Error("CORS issue");
                    }
                }
            }
            return { ...serverInfo, corsProxyRequired: false };
        } catch {
            return { ...(await getServerInfo(`${AppConfig.proxyUrl}/${inputUrl}`)), corsProxyRequired: true };
        }
    }

    static async validateCredentials(jiraUrl: string, username: string, password: string): Promise<boolean> {
        try {
            await axios.get(`${jiraUrl}/rest/api/2/myself`, {
                auth: {
                    password,
                    username,
                },
            });
            return true;
        } catch (e: unknown) {
            const response = (e as AxiosError)?.response;
            if (response?.status && ["401", "403"].includes(response.status?.toString())) {
                throw new InvalidCredentialsError();
            }

            throw new Error("Connection issue. Please try again ");
        }
    }
}

async function getServerInfo(url: string) {
    try {
        const res = await axios.get<ServerInfoResponse>(`${url}/rest/api/2/serverInfo`);
        if (res.data.deploymentType === undefined) {
            throw new Error("Invalid jira response");
        }
        const { deploymentType, serverTitle, baseUrl } = res.data;

        return { isValid: true, url, deploymentType: deploymentType, serverTitle: serverTitle, baseUrl };
    } catch (e) {
        throw new Error(
            "There was problem connecting to your jira. Check if address is correct and ensure jira is accessible from internet",
        );
    }
}

export interface JiraAutocompleteSuggestions {
    results: {
        value: string;
        displayName: string;
    }[];
}

export interface invalidJiraResult {
    isValid: false;
}
export interface ServerInfoResponse {
    baseUrl: string;
    version: string;
    deploymentType: JiraDeploymentType;
    serverTitle: string;
}

export type JiraDeploymentType = "Server" | "Cloud";

class InvalidCredentialsError extends Error {
    constructor() {
        super("Invalid credentials!");
    }
}

export interface JiraPickerResponse {
    sections: {
        label: string;
        sub: string;
        id: string;
        issues: {
            key: string;
            summaryText: string;
        }[];
    }[];
}

export interface JiraFieldResponse {
    id: string;
    name: string;
    custom: boolean;
    orderable: boolean;
    navigable: boolean;
    searchable: boolean;
    clauseNames: string[];
    schema?: {
        type: string;
        items: string;
        custom: string;
        customId: number;
    };
}
