import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDebounce } from "use-debounce/lib";
import SelectWithAutocomplete, { SelectWithAutocompleteProps } from "./SelectWithAutocomplete";
export interface SelectWithAutocompleteAsyncListProps<M extends boolean>
    extends Omit<SelectWithAutocompleteProps<M>, "options" | "isLoading"> {
    fetchAutocomplete: (text: string) => Promise<{ name: string; id: string }[]>;
    takeFirstOnEmpty?: boolean;
}
export default function SelectWithAutocompleteAsyncList<M extends boolean>(
    allProps: SelectWithAutocompleteAsyncListProps<M>,
) {
    const { fetchAutocomplete, takeFirstOnEmpty, onNewValue, multiple, value, ...props } = allProps;
    const [inputValue, setInputValue] = useState("");
    const [debouncedInputValue] = useDebounce(inputValue, 150);
    const { isLoading, data: options } = useQuery(
        `autocomplete-list-${allProps.label}-${debouncedInputValue}`,
        () => fetchAutocomplete(debouncedInputValue),
        { staleTime: Infinity },
    );

    useEffect(() => {
        if (!multiple && takeFirstOnEmpty && options && options.length && value === null) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onNewValue(options[0] as any);
        }
    }, [value, options, multiple, onNewValue, takeFirstOnEmpty]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const v = value as any;

    const optionsWithCurrentValue = [...(options?.filter((i) => i?.id !== v?.id) || []), ...(multiple && v ? v : [v])];

    return (
        <SelectWithAutocomplete
            {...{ ...props, value, onNewValue, multiple }}
            onInputChange={(_, value) => setInputValue(value)}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            options={optionsWithCurrentValue}
            isLoading={isLoading}
        />
    );
}
