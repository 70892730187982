import { useSortable } from "@dnd-kit/sortable";
import DeleteIcon from "@mui/icons-material/Delete";
import { Badge, Card, CardContent, IconButton, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { CSS } from "@dnd-kit/utilities";
import { useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            width: "260px",
        },

        removeButton: {
            "& .MuiBadge-badge": {
                height: 25,
                minWidth: 25,
                borderRadius: 15,
            },
            "& .MuiSvgIcon-root": {
                fontSize: "0.9rem",
            },
        },
        removeButtonAction: {
            padding: 0,
        },
        cardValueInput: {
            width: "100%",
            fontSize: "80px",
            border: "none",
            textAlign: "center",
            backgroundColor: "transparent",
            color: theme.palette.text.primary,
        },
    }),
);

export function ConfigurableCard({
    value,
    onRemove,
    setValue,
    id,
}: {
    id: string;
    value: string;
    onRemove: () => void;
    setValue: (value: string) => void;
}) {
    const [tmpValue, setTempValue] = useState(value);
    const classes = useStyles();
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <Badge
                className={classes.removeButton}
                badgeContent={
                    <IconButton className={classes.removeButtonAction} onClick={onRemove} size="large">
                        <DeleteIcon />
                    </IconButton>
                }
                color="primary"
            >
                <Card className={classes.card}>
                    <CardContent>
                        <input
                            onPointerDown={(e) => e.stopPropagation()}
                            onBlur={() => setValue(tmpValue)}
                            type="text"
                            className={classes.cardValueInput}
                            value={tmpValue}
                            onChange={(e) => setTempValue(e.target.value)}
                        ></input>
                    </CardContent>
                </Card>
            </Badge>
        </div>
    );
}
