import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import Typography from "@mui/material/Typography";
import dateFormat from "dateformat";
import React from "react";
import { Comment } from "../../interfaces/JiraTicket";
import { useJiraTicketStyles } from "./useJiraTicketStyles";

const formatCommentDate = (commentDate: string) => dateFormat(commentDate, "yyyy-mm-dd");
export function Comments({ comments }: { comments?: Comment[] }) {
    const classes = useJiraTicketStyles();

    return (
        <>
            <Typography variant="h6" component="h2" className={classes.item}>
                Comments
            </Typography>
            <List className={classes.root} key="comments-list">
                {comments?.map((comment, index) => (
                    <React.Fragment key={`comment-sec-${index}`}>
                        <ListItem alignItems="flex-start" key={`comment-${index}`}>
                            <ListItemAvatar>
                                <Avatar alt={comment.authorName} src={comment.authorImage} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={comment.authorName}
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            color="textSecondary"
                                            style={{ display: "block" }}
                                        >
                                            created on: {formatCommentDate(comment.created)}
                                        </Typography>
                                        <Typography component="span" variant="body2" color="textPrimary">
                                            {comment.body}
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Divider variant="inset" key={`comment-divider-${index}`} component="li" />
                    </React.Fragment>
                ))}
            </List>
        </>
    );
}
