import AddIcon from "@mui/icons-material/Add";
import {
    Divider,
    Fab,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Theme,
    Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { useFormikContext } from "formik";
import SelectWithAutocomplete from "../../UI/SelectWithAutocomplete";
import { ConfigurableCardsList } from "./ConfigurableCardsList";
import { GroupedVotesSaveMethod, VotingSettingsConfig } from "./VotingSettingsConfig";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& .MuiTextField-root": {
                marginBottom: theme.spacing(2),
            },
            "& .MuiDivider-root": {
                marginBottom: "1em",
                marginTop: "1em",
            },
        },
        voteControl: {
            marginTop: theme.spacing(2),
        },
        caption: {
            display: "inline-block",
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        hidden: {
            display: "none",
        },
        card: {
            width: "260px",
        },
        cards: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            gap: "20px",
        },
        addButton: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        removeButton: {
            "& .MuiBadge-badge": {
                height: 25,
                minWidth: 25,
                borderRadius: 15,
            },
            "& .MuiSvgIcon-root": {
                fontSize: "0.9rem",
            },
        },
        removeButtonAction: {
            padding: 0,
        },
        cardValueInput: {
            width: "100%",
            fontSize: "80px",
            border: "none",
            textAlign: "center",
            backgroundColor: "transparent",
            color: theme.palette.text.primary,
        },
    }),
);

export interface VotingSettingsProps {
    enabled: boolean;
}

export function VotingSettings({ enabled }: VotingSettingsProps) {
    const classes = useStyles();
    const { values, setFieldValue, handleChange, handleBlur, isSubmitting, errors, touched } =
        useFormikContext<VotingSettingsConfig>();

    const addCard = () => setFieldValue("cards", [...values.cards, ""]);

    return (
        <div className={classes.root}>
            <FormControlLabel
                className={classes.voteControl}
                control={
                    <Checkbox
                        checked={values.displayVoteInBubble}
                        onChange={({ target: { checked } }) => setFieldValue("displayVoteInBubble", checked)}
                        color="primary"
                    />
                }
                label="Display votes inside user bubbles (reduce bubble animations)"
                labelPlacement="end"
            />
            <Divider />
            <Typography variant="h6">Your vote</Typography>

            <Typography variant="subtitle1">
                Define if you want to take active part in voting. <br />
                This setting can not be changed after game is started{" "}
            </Typography>
            <FormControlLabel
                className={classes.voteControl}
                control={
                    <Checkbox
                        checked={values.isVoting}
                        onChange={({ target: { checked } }) => setFieldValue("isVoting", checked)}
                        color="primary"
                    />
                }
                label="I would like to vote"
                labelPlacement="end"
                disabled={!enabled}
            />
            <br />

            <div className={clsx({ [classes.hidden]: !values.isVoting })}>
                <Typography variant="caption" className={classes.caption}>
                    Specify your name and shortname which will be visible next to your votes
                </Typography>
                <TextField
                    fullWidth
                    label="Name *"
                    id="name"
                    variant="outlined"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.name && (isSubmitting || touched.name || !!values.name)}
                    helperText={errors.name}
                    disabled={!enabled}
                />

                <TextField
                    fullWidth
                    id="shortName"
                    label="Short Name *"
                    variant="outlined"
                    value={values.shortName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.shortName && (isSubmitting || touched.shortName || !!values.shortName)}
                    helperText={errors.shortName}
                    disabled={!enabled}
                />

                {values.votingGroupsEnabled && (
                    <FormControl fullWidth error={!!errors.group}>
                        <InputLabel id="voting-group-label">group</InputLabel>
                        <Select
                            labelId="voting-group-label"
                            value={values.group}
                            label="Group"
                            name="group"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            defaultValue=""
                        >
                            {(values.votingGroups || []).map((groupName) => (
                                <MenuItem key={groupName} value={groupName}>
                                    {groupName}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{errors.group}</FormHelperText>
                    </FormControl>
                )}
            </div>
            <Divider />

            <Typography variant="h6">Voting groups</Typography>
            <FormControlLabel
                className={classes.voteControl}
                control={
                    <Checkbox
                        checked={values.votingGroupsEnabled}
                        onChange={({ target: { checked } }) => setFieldValue("votingGroupsEnabled", checked)}
                        color="primary"
                    />
                }
                label="Split participants into voting groups (ex. Devs, QAs, DevOps etc.)"
                labelPlacement="end"
                disabled={!enabled}
            />
            {values.votingGroupsEnabled && (
                <>
                    <SelectWithAutocomplete
                        multiple
                        freeSolo
                        label="Groups"
                        isLoading={false}
                        options={[]}
                        helperText="Type group name and press enter to add it"
                        value={(values.votingGroups || []).map((value) => ({ id: value, name: value }))}
                        onNewValue={(value) => {
                            setFieldValue(
                                "votingGroups",
                                value?.map((v) => v?.id || v),
                            );
                        }}
                    />
                    <FormControl fullWidth error={isSubmitting && !!errors.groupedVotesSaveMethod}>
                        <InputLabel>How estimation should be stored to jira?</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            name="groupedVotesSaveMethod"
                            value={values.groupedVotesSaveMethod}
                            label="How estimation should be stored to jira?"
                            onChange={handleChange}
                        >
                            <MenuItem value={GroupedVotesSaveMethod.SUBTASK}>
                                Subtasks (separate for each group)
                            </MenuItem>
                            <MenuItem value={GroupedVotesSaveMethod.COMMENT}>Sum in ticket, detail in comment</MenuItem>
                        </Select>
                    </FormControl>
                </>
            )}

            <Divider />

            <Typography variant="h6">Voting cards</Typography>

            <Typography variant="subtitle1">
                Define cards that will be used in the voting. You can use anything as values - numbers, text or emojis.
                <br />
                Add as many cards as you need
            </Typography>

            <div className={classes.cards}>
                <ConfigurableCardsList cards={values.cards} setCards={(value) => setFieldValue("cards", value)} />
                <div className={clsx(classes.card, classes.addButton)}>
                    <Fab color="primary" aria-label="add" onClick={addCard}>
                        <AddIcon />
                    </Fab>
                </div>
            </div>
        </div>
    );
}
