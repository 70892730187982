import Axios from "axios";
import { ThunkAction } from "redux-thunk";
import { AppConfig } from "../../Config";
import { JWT_TOKEN_LOCAL_STORAGE_KEY, WS_URL_LOCAL_STORAGE_KEY } from "../../interfaces/LocalStorageKeys";
import { RoomState } from "../../interfaces/RoomState";
import { Actions } from "./actions";
import { RoomCreated } from "./RoomCreated";
import { RoomCreateStarted } from "./RoomCreateStarted";

export const CreateRoom =
    ({
        name,
        nick,
        group,
    }: {
        name?: string;
        nick?: string;
        group?: string;
    }): ThunkAction<Promise<string>, RoomState, unknown, Actions> =>
    async (dispatch) => {
        dispatch(RoomCreateStarted());
        const result = (await Axios.post<CreateRoomResponse>(`${AppConfig.backendUrl}/rooms`, { name, nick, group }))
            .data;

        dispatch(
            RoomCreated({
                roomId: result.roomId,
                token: result.token,
                wsUrl: result.url,
            }),
        );

        window.localStorage.setItem(JWT_TOKEN_LOCAL_STORAGE_KEY, result.token);
        window.localStorage.setItem(WS_URL_LOCAL_STORAGE_KEY, result.url);

        return result.roomId;
    };

interface CreateRoomResponse {
    roomId: string;
    token: string;
    url: string;
}
