import jwtDecode from "jwt-decode";
import { JwtPayload } from "../../interfaces/JwtTokenPayload";
import { User } from "../../interfaces/User";
import { Actions } from "../actions/actions";
import { JOINED_SESSION } from "../actions/JoinedSession";
import { LOGOUT } from "../actions/Logout";
import { RESET_VOTES } from "../actions/ResetVotes";
import { ROOM_CREATED } from "../actions/RoomCreated";
import { SESSION_RESTORED } from "../actions/SessionRestored";
import { USER_DISCONNECTED } from "../actions/UserDisconnected";
import { USER_JOINED } from "../actions/UserJoined";
import { USER_LIST_LOADED } from "../actions/UserListLoaded";
import { USER_VOTED } from "../actions/UserVoted";
import { VOTE } from "../actions/Vote";
import { VOTES_REVEALED } from "../actions/VotesRevealed";
import { USER_KICKED } from "../actions/UserKicked";
import { toast } from "react-toastify";

export function usersReducer(state: User[] = [], action: Actions): User[] {
    switch (action.type) {
        case JOINED_SESSION:
            const { name, nick, uuid, group } = action.payload;
            return [{ id: uuid, isSelf: true, hasVoted: false, name, nick, group }];
        case ROOM_CREATED:
        case SESSION_RESTORED:
            const tp = jwtDecode<JwtPayload>(action.payload.token);
            if (tp.nick) {
                return [{ id: tp.uuid, isSelf: true, hasVoted: false, name: tp.name, nick: tp.nick, group: tp.group }];
            } else {
                return state;
            }
        case USER_LIST_LOADED:
            return [
                ...state,
                ...action.payload.users.filter(
                    (eventUser) => !state.some((stateUser) => stateUser.id === eventUser.id),
                ),
            ];
        case USER_JOINED:
            if (action.payload.nick === "") {
                return state;
            }
            return state.find((u) => u.id === action.payload.id) ? [...state] : [...state, action.payload];
        case USER_DISCONNECTED:
            return state.filter((u) => u.id !== action.payload.uuid);
        case USER_KICKED:
            const kickedUser = state.find((u) => u.id === action.payload.uuid);

            if (kickedUser) {
                toast.info(`\u2620 ${kickedUser.name} has been kicked out by SM. \u2620`);
            }

            return state.filter((u) => u.id !== action.payload.uuid);
        case VOTE:
            return state.map((user) => {
                return user.isSelf ? { ...user, hasVoted: action.payload.hasVoted, vote: action.payload.vote } : user;
            });
        case USER_VOTED:
            return state.map((user) =>
                user.id === action.payload.userId
                    ? { ...user, hasVoted: action.payload.hasVoted, vote: action.payload.vote }
                    : user,
            );
        case VOTES_REVEALED:
            return state.map((user) => {
                const userFromEvent = action.payload.users.find((u) => u.uuid === user.id);
                if (userFromEvent) {
                    return { ...user, vote: userFromEvent?.vote?.toString(), hasVoted: userFromEvent.voted };
                }
                return user;
            });
        case RESET_VOTES:
            return state.map((user) => ({ ...user, hasVoted: false, vote: undefined }));
        case LOGOUT:
            return [];
        default:
            return state;
    }
}
