import { Card, CardContent, Chip, Link, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import React from "react";
import { JiraTicket } from "../../interfaces/JiraTicket";
import { PriorityIcon } from "../PriorityIcon/PriorityIcon";
import { Attachments } from "./Attachments";
import { Chips } from "./Chips";
import { Comments } from "./Comments";
import { HtmlLongText } from "./HtmlLongText";
import { useJiraTicketStyles } from "./useJiraTicketStyles";
import { EstimationMode } from "../../components/ConfigWizard/JqlQueryBuilder/JqlQueryBuilderConfig";
import { useSelector } from "react-redux";
import { RoomState } from "../../interfaces/RoomState";
import { UserType } from "../../interfaces/ActiveUser";

export function JiraTicketDetails({
    ticketId,
    title,
    reporter,
    priority,
    issueType,
    url,
    extraFields,
    voted,
}: Partial<JiraTicket>) {
    const classes = useJiraTicketStyles();

    const estimationMode = useSelector((state: RoomState) => state.config.estimationMode);
    const userType = useSelector((state: RoomState) => state.activeUser.userType);

    return (
        <Card
            className={classes.root}
            style={{
                maxHeight:
                    estimationMode !== EstimationMode.STORY_POINTS
                        ? userType === UserType.SCRUM_MASTER
                            ? "82vh"
                            : "88vh"
                        : undefined,
            }}
        >
            <CardContent className={classes.cardContent}>
                <div className={classes.header}>
                    {ticketId && (
                        <Typography className={classes.link} gutterBottom>
                            <Link target={"blank"} href={url} color="inherit">
                                {ticketId}
                            </Link>
                        </Typography>
                    )}
                    <Typography variant="h5" component="h2" className={classes.item}>
                        {voted && (
                            <Tooltip title="Estimated">
                                <CheckCircleTwoToneIcon />
                            </Tooltip>
                        )}
                        {title}
                    </Typography>
                    <Typography className={classes.reporter} color="textSecondary" gutterBottom>
                        Reporter: {reporter}
                    </Typography>

                    {issueType && (
                        <Chip
                            label={
                                <div>
                                    <img alt="" src={issueType?.iconUrl} /> &nbsp;
                                    <span>{issueType?.name}</span>
                                </div>
                            }
                            className={classes.chip}
                        />
                    )}
                    {priority && (
                        <Chip
                            label={
                                <div>
                                    <PriorityIcon priority={priority} />
                                    <span>{priority}</span>
                                </div>
                            }
                            className={classes.chip}
                        />
                    )}
                </div>
                <div className={classes.body}>
                    {(extraFields || [])
                        .filter((f) => f.type === "number" || (f.type === "string" && !f.isLongText))
                        .map((extraField) => (
                            <Chip
                                label={
                                    <span>
                                        <strong>{extraField.label}</strong> : {extraField.value}
                                    </span>
                                }
                                className={classes.chip}
                            />
                        ))}

                    {(extraFields || []).map((extraField) => {
                        if (extraField.type === "array") {
                            switch (extraField.items) {
                                case "string":
                                    return (
                                        <Chips
                                            key={`extra-field-${extraField.label}`}
                                            label={extraField.label}
                                            components={extraField.value}
                                        />
                                    );
                                case "attachment":
                                    return (
                                        <Attachments
                                            key={`extra-field-${extraField.label}`}
                                            attachments={extraField.value}
                                        />
                                    );
                                case "comment": {
                                    return (
                                        <Comments comments={extraField.value} key={`extra-field-${extraField.label}`} />
                                    );
                                }
                                default:
                            }
                        } else if (extraField.type === "string" && extraField.isLongText) {
                            return (
                                <HtmlLongText
                                    key={`extra-field-${extraField.label}`}
                                    label={extraField.label}
                                    htmlText={extraField.value}
                                />
                            );
                        }
                        return undefined;
                    })}
                </div>
            </CardContent>
        </Card>
    );
}
