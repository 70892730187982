export const MARK_TICKET_AS_UNESTIAMTED = "MARK_TICKET_AS_UNESTIAMTED";

export interface MarkTicketAsUnestiamtedAction {
    type: typeof MARK_TICKET_AS_UNESTIAMTED;
    payload: {
        ticketId: string;
    };
}

export function MarkTicketAsUnestiamted(ticketId: string): MarkTicketAsUnestiamtedAction {
    return {
        type: MARK_TICKET_AS_UNESTIAMTED,
        payload: {
            ticketId,
        },
    };
}
