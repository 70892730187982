import { ActiveUser, UserState, UserType } from "../../interfaces/ActiveUser";
import { Actions } from "../actions/actions";
import { ROOM_CREATE_STARTED } from "../actions/RoomCreateStarted";
import { JIRA_INSTANCE_CONNECTED } from "../actions/JiraInstanceConnected";
import { JOINED_SESSION } from "../actions/JoinedSession";
import { JOIN_SESSION_STARTED } from "../actions/JoinRoomStarted";
import { LOGOUT } from "../actions/Logout";
import { RESET_VOTES } from "../actions/ResetVotes";
import { ROOM_CREATED } from "../actions/RoomCreated";
import { SESSION_RESTORED } from "../actions/SessionRestored";
import { VOTE } from "../actions/Vote";
import { USER_LIST_LOADED } from "../actions/UserListLoaded";

export function activeUserReducer(state: ActiveUser = { state: UserState.EMPTY }, action: Actions): ActiveUser {
    switch (action.type) {
        case VOTE:
            return { ...state, vote: action.payload.vote };
        case USER_LIST_LOADED:
            return { ...state, vote: action.payload.users.find((u) => u.id === state.uuid)?.vote };
        case ROOM_CREATE_STARTED:
            return { state: UserState.LOADING, userType: UserType.SCRUM_MASTER };
        case ROOM_CREATED:
            return { state: UserState.READY, userType: UserType.SCRUM_MASTER, ...action.payload };
        case JOIN_SESSION_STARTED:
            return { state: UserState.LOADING, userType: UserType.MEMBER, group: state.group, ...action.payload };
        case JOINED_SESSION:
            return { state: UserState.READY, userType: UserType.MEMBER, ...action.payload };
        case RESET_VOTES:
            return { ...state, vote: undefined };
        case JIRA_INSTANCE_CONNECTED:
            return { ...state, userType: UserType.SCRUM_MASTER };
        case SESSION_RESTORED:
            return { ...action.payload };
        case LOGOUT:
            return { state: UserState.EMPTY, wasKicked: action.payload.wasKicked };
        default:
            return state;
    }
}
