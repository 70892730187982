import {
    Avatar,
    Button,
    DialogContent,
    DialogTitle,
    IconButton,
    LinearProgress,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { CheckCircle } from "@mui/icons-material";
import ListIcon from "@mui/icons-material/List";
import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { JiraFieldDescriptor, JiraStringArrayField, JiraTicket } from "../../interfaces/JiraTicket";
import { ActiveTicketChanged } from "../../store/actions/ActiveTicketChanged";
import { RefreshTicketList } from "../../store/actions/TicketsListRefreshed";
import SelectWithAutocomplete, { SelectValueObject } from "../UI/SelectWithAutocomplete";
import CachedIcon from "@mui/icons-material/Cached";
import { PriorityIcon } from "../PriorityIcon/PriorityIcon";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: "50vw",
        },
        dialog: {
            fullWidth: true,
            maxWidth: "xl",
        },
        priority: {
            display: "flex",
            alignItems: "center",
        },
        actionsBar: {
            display: "flex",
            width: "100%",
            justifyContent: "flex-start",
            alignItems: "center",
        },
        ticketRow: {
            paddingTop: "0px",
            paddingBottom: "0px",
        },
        rowAdded: {
            backgroundColor: "#ff9100",
        },
        filterSelect: {
            flexGrow: 5,
            maxWidth: "40%",
        },
    }),
);

export function TicketListView({
    ticketList,
    extraFields,
}: {
    ticketList: JiraTicket[];
    extraFields: JiraFieldDescriptor[];
}) {
    const [open, setOpen] = React.useState(false);
    const availableFilters = useMemo(() => {
        return [
            { name: "none", id: "none" },
            { name: "priority", id: "priority" },
            ...extraFields
                .filter((ef) => ef.type === "array" && (ef as JiraStringArrayField).items === "string")
                .map(({ label, id }) => ({ name: label, id })),
        ];
    }, [extraFields]);
    const defaultFilter = useMemo(
        () => availableFilters.find((f) => f.name.toLowerCase() === "sprint") || { name: "none", id: "none" },
        [availableFilters],
    );
    const [filter, setFilter] = React.useState<SelectValueObject | undefined>(defaultFilter);
    const [isLoading, setIsLoading] = React.useState(false);

    const dispatch = useDispatch();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (chosenTicket: JiraTicket) => {
        dispatch(ActiveTicketChanged(chosenTicket));
        setOpen(false);
    };

    const handleRefresh = () => {
        setIsLoading(true);
        const dispatchPromise = dispatch(RefreshTicketList());

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (dispatchPromise as any).then(() => {
            setIsLoading(false);
        });
    };

    const classes = useStyles();

    const groups =
        filter?.name === "none"
            ? [{ name: "Tickets", list: ticketList }]
            : filterList(
                  ticketList,
                  filter?.id === "priority"
                      ? (ticket: JiraTicket) => [ticket.priority]
                      : (ticket: JiraTicket) =>
                            (ticket.extraFields?.find((f) => f.id === filter?.id) as JiraStringArrayField)?.value,
              );

    return (
        <>
            <IconButton onClick={() => handleClickOpen()} size="large">
                <Tooltip title="Show list of all tickets in this session">
                    <ListIcon />
                </Tooltip>
            </IconButton>

            <Dialog fullWidth={false} maxWidth={"xl"} className={classes.dialog} onClose={handleClose} open={open}>
                {isLoading && <LinearProgress />}
                <DialogTitle className={classes.actionsBar}>
                    <IconButton onClick={handleRefresh} disabled={isLoading} size="large">
                        <Tooltip title="Refresh current ticket">
                            <CachedIcon />
                        </Tooltip>
                    </IconButton>
                    <SelectWithAutocomplete
                        disableClearable
                        className={classes.filterSelect}
                        options={availableFilters}
                        label="filter"
                        value={filter}
                        isLoading={false}
                        onNewValue={(v) => setFilter(v as SelectValueObject)}
                    />
                </DialogTitle>
                <DialogContent dividers={true}>
                    <TableContainer>
                        {groups
                            .filter((group) => group.list.length)
                            .map((group, groupIndex) => (
                                <React.Fragment key={`group-${groupIndex}`}>
                                    <Typography variant="h5" key={`title-${groupIndex}`}>
                                        {group.name}
                                    </Typography>
                                    <Table className={classes.table} aria-label="ticket list">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ width: "75%" }} align="left">
                                                    Ticket
                                                </TableCell>
                                                <TableCell style={{ width: "15%" }}>Priority</TableCell>
                                                <TableCell style={{ width: "5%" }}>Estimated</TableCell>

                                                <TableCell style={{ width: "5%" }}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {group.list.map((row) => (
                                                <TableRow
                                                    key={`row-${groupIndex}-${row.ticketId}`}
                                                    className={row.isAddOne ? classes.rowAdded : ""}
                                                >
                                                    <TableCell
                                                        style={{ width: "75%" }}
                                                        align="left"
                                                        className={classes.ticketRow}
                                                    >
                                                        <ListItem
                                                            divider={false}
                                                            dense={true}
                                                            button
                                                            key={`listItem-${groupIndex}-${row.ticketId}`}
                                                            component="a"
                                                            href={row.url}
                                                            target="_blank"
                                                        >
                                                            <ListItemAvatar>
                                                                <Avatar src={row.issueType?.iconUrl}></Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                                primaryTypographyProps={{
                                                                    style: { fontWeight: "bold" },
                                                                }}
                                                                primary={row.ticketId}
                                                                secondary={row.title}
                                                            />
                                                        </ListItem>
                                                    </TableCell>
                                                    <TableCell style={{ width: "15%" }} align="left" valign="baseline">
                                                        <div className={classes.priority}>
                                                            <PriorityIcon priority={row.priority} />
                                                            <span>{row.priority}</span>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell style={{ width: "5%" }} component="th" scope="row">
                                                        {row.voted ? <CheckCircle /> : <></>}
                                                    </TableCell>
                                                    <TableCell style={{ width: "5%" }} align="left">
                                                        <Button onClick={() => handleChange(row)}>Select</Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </React.Fragment>
                            ))}
                    </TableContainer>
                </DialogContent>
            </Dialog>
        </>
    );
}

export function filterList(
    ticketList: JiraTicket[],
    fieldSelector: (ticket: JiraTicket) => string[] | undefined,
): { name: string; list: JiraTicket[] }[] {
    const mappedTickets = ticketList.map((ticket) => ({ ...ticket, filterField: fieldSelector(ticket) }));
    const valueList = mappedTickets.flatMap((ticket) =>
        Array.isArray(ticket.filterField) ? ticket.filterField : [ticket.filterField],
    );
    const uniqueValues = Array.from(new Set(valueList));

    const groups = uniqueValues.map((field) => ({
        name: field as string,
        list: mappedTickets.filter((ticket) => field && ticket.filterField?.includes(field)),
    }));
    groups.push({
        name: "empty",
        list: mappedTickets.filter(
            (ticket) =>
                ticket.filterField === undefined ||
                ticket.filterField.length === 0 ||
                ticket.filterField?.[0] === undefined,
        ),
    });
    return groups;
}
