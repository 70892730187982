import { UserType } from "../../interfaces/ActiveUser";
import { WsCompatibleAction } from "./WsCompatibleAction";

export const RESET_VOTES = "RESET_VOTES";

export interface ResetVotesAction extends WsCompatibleAction {
    type: typeof RESET_VOTES;
}

export const ResetVotes = (isFallback = false): ResetVotesAction => {
    return {
        type: RESET_VOTES,
        sendOverWs: {
            when: (user) => user.userType === UserType.SCRUM_MASTER && isFallback === false,
            format: ({ token }) => ({ action: "reset_votes", token: token }),
        },
    };
};
