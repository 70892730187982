import { SelectValueObject } from "../../UI/SelectWithAutocomplete";

export interface JqlQueryBuilderConfig {
    jql?: string;
    referenceTicketsJql?: string;
    project?: SelectValueObject;
    estimationMode?: EstimationMode;
    storyPointsField?: SelectValueObject & { cfid: string };
    manualJql?: boolean;
    isJqlValid?: boolean;
    statuses?: SelectValueObject[];
    issueTypes?: SelectValueObject[];
}

export enum EstimationMode {
    "STORY_POINTS" = "STORY_POINTS",
    "HOURS" = "HOURS",
    "MANDAYS" = "MANDAYS",
}
