import { JiraTicket } from "../../interfaces/JiraTicket";
import { Actions } from "../actions/actions";
import { CONFIG_UPDATED } from "../actions/ConfigUpdated";
import { LOGOUT } from "../actions/Logout";
import { MARK_TICKET_AS_UNESTIAMTED } from "../actions/MarkTicketAsUnestiamted";
import { TICKET_ADDED_OR_UPDATED } from "../actions/TicketAddedOrUpdated";
import { SAVE_VOTE } from "../actions/SaveVote";
import { TICKETS_LIST_LOADED } from "../actions/TicketsListLoaded";
import { TICKETS_LIST_REFRESHED } from "../actions/TicketsListRefreshed";

export function ticketListReducer(state: JiraTicket[] = [], action: Actions): JiraTicket[] {
    switch (action.type) {
        case TICKETS_LIST_LOADED:
            return [...action.payload];
        case TICKETS_LIST_REFRESHED:
            const existingUpdated = state.map((ticket) => {
                const updatedTicket = action.payload.find((newTicket) => newTicket.ticketId === ticket.ticketId);
                if (updatedTicket) {
                    return { ...updatedTicket, isAddOne: ticket.isAddOne, voted: ticket.voted };
                }
                return ticket;
            });
            const newTickets = action.payload.filter(
                (ticket) => !state.some((existingTicket) => existingTicket.ticketId === ticket.ticketId),
            );
            return [...existingUpdated, ...newTickets];

        case SAVE_VOTE:
            return [
                ...state.map((ticket) => {
                    if (ticket.ticketId === action.payload.ticketId) {
                        return { ...ticket, voted: true };
                    }
                    return ticket;
                }),
            ];
        case MARK_TICKET_AS_UNESTIAMTED:
            return [
                ...state.map((ticket) => {
                    if (ticket.ticketId === action.payload.ticketId) {
                        return { ...ticket, voted: false };
                    }
                    return ticket;
                }),
            ];
        case TICKET_ADDED_OR_UPDATED:
            if (state.find((t) => t.ticketId === action.payload.ticketId)) {
                return [
                    ...state.map((ticket) => {
                        if (ticket.ticketId === action.payload.ticketId) {
                            return { ...action.payload, voted: ticket.voted };
                        }
                        return ticket;
                    }),
                ];
            } else {
                return [...state, action.payload];
            }
        case CONFIG_UPDATED:
            return [];
        case LOGOUT:
            return [];
        default:
            return state;
    }
}
