import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import LinearProgress from "@mui/material/LinearProgress";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import useLocalStorage from "react-use/lib/useLocalStorage";
import { CopyLinkView } from "../components/CopyLink/CopyLinkView";
import { isJwtTokenExpired, useJwtFromLocalStorage } from "../helpers/useJwtFromLocalStorage";
import { UserState, UserType } from "../interfaces/ActiveUser";
import { Config } from "../interfaces/Config";
import { JwtPayload } from "../interfaces/JwtTokenPayload";
import {
    JWT_TOKEN_LOCAL_STORAGE_KEY,
    SESSION_CONFIG_LOCAL_STORAGE_KEY,
    WS_URL_LOCAL_STORAGE_KEY,
} from "../interfaces/LocalStorageKeys";
import { RoomState } from "../interfaces/RoomState";
import { ConfigUpdated } from "../store/actions/ConfigUpdated";
import { SessionRestored } from "../store/actions/SessionRestored";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            paddingTop: "5em",
            alignSelf: "center",
            justifyItems: "center",
            textAlign: "center",
            display: "inline-grid",
            width: "100%",
        },
    }),
);

export function IndexPage() {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [jwtPayload, removeJwtFromLs] = useJwtFromLocalStorage<JwtPayload>(JWT_TOKEN_LOCAL_STORAGE_KEY);
    const [wsUrl] = useLocalStorage<string>(WS_URL_LOCAL_STORAGE_KEY, undefined, { raw: true });
    const [config] = useLocalStorage<Config>(SESSION_CONFIG_LOCAL_STORAGE_KEY);
    const activeUser = useSelector((state: RoomState) => state.activeUser);

    useEffect(() => {
        if (activeUser.state === UserState.EMPTY && jwtPayload && wsUrl) {
            if (isJwtTokenExpired(jwtPayload)) {
                removeJwtFromLs();
                toast.info("Your session has expired");
                return;
            }
            dispatch(SessionRestored(jwtPayload, jwtPayload.jwt, wsUrl));
            if (jwtPayload.role === "sm" && config) {
                dispatch(ConfigUpdated(config));
            }
        }
    }, [activeUser, wsUrl, dispatch, removeJwtFromLs, jwtPayload, config]);

    if (activeUser.state === UserState.LOADING || (activeUser.state === UserState.EMPTY && jwtPayload)) {
        return (
            <div className={classes.root}>
                Please wait...
                <br />
                <LinearProgress />{" "}
            </div>
        );
    }

    if (activeUser.state === UserState.READY && activeUser.userType === UserType.SCRUM_MASTER && !jwtPayload) {
        return (
            <div className={classes.root}>
                <CopyLinkView
                    url={`${window.location.origin}/room/${activeUser.roomId}`}
                    startGameAction={() => history.push(`/room/${activeUser.roomId}`)}
                />
            </div>
        );
    }

    if (activeUser.roomId) {
        return <Redirect to={`/room/${activeUser.roomId}`} />;
    }

    return <Redirect to="/create-session" />;
}
