/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    calculateVoteStatistics,
    calculateVoteStatisticsForGroups,
    VoteStatistics,
} from "../../helpers/calculateVoteSummary";
import { UserType } from "../../interfaces/ActiveUser";
import { RoomState } from "../../interfaces/RoomState";
import { ChangeActiveTicketToNext } from "../../store/actions/ActiveTicketChanged";
import { MarkTicketAsUnestiamted } from "../../store/actions/MarkTicketAsUnestiamted";
import { ResetVotes } from "../../store/actions/ResetVotes";
import { RevealVotes } from "../../store/actions/RevealVotes";
import { SaveVote } from "../../store/actions/SaveVote";
import { SetGroupFilter } from "../../store/actions/SetGroupFilter";
import { Vote } from "../../store/actions/Vote";
import { VoteCardsWithUsers } from "../VoteCardsWithUsers/VoteCardsWithUsers";
import { VoteSummary } from "../VoteSummary/VoteSummary";
import { VoteSummaryForGroups } from "../VoteSummaryForGroups/VoteSummaryForGroups";
import { VoteMgmtView } from "../VotingManagamentView/VoteMgmtView";
import { SetAvailableVoters } from "../../store/actions/SetAvailableVoters";

export function VoteCardsWithUsersConnected() {
    const {
        users,
        revealVotes,
        activeUser,
        activeTicket,
        groupFilter,
        config: {
            isVoting: isSmVoting,
            cards,
            votingGroups,
            displayVoteInBubble,
            availableVoters,
            votingGroupsEnabled,
        },
    } = useSelector((state: RoomState) => state);
    const dispatch = useDispatch();
    const userCanVote = useMemo(() => {
        if ((activeUser.userType === UserType.SCRUM_MASTER && isSmVoting) || activeUser.userType === UserType.MEMBER) {
            return (
                !availableVoters ||
                availableVoters?.length === 0 ||
                !!availableVoters?.filter((voter) => voter.value === activeUser.group)[0]?.checked
            );
        }
        return false;
    }, [availableVoters, isSmVoting, activeUser.group, activeUser.userType]);
    console.log("userCanVote", {
        userCanVote,
        availableVoters,
        isSmVoting,
        group: activeUser.group,
        activeUser,
    });
    const isSm = activeUser.userType === UserType.SCRUM_MASTER;

    console.log({
        userCanVote,
        availableVoters,
        activeUser,
        isSmVoting,
    });
    const setFilter = (newFilter: string) => {
        if (revealVotes && newFilter !== groupFilter) {
            dispatch(SetGroupFilter(newFilter));
        }
    };

    const checkboxOnChange = (e: any) => {
        const checked = e.target.checked;
        const value = e.target.value;
        const groups = availableVoters.filter((voter) => voter.value !== value);
        const newGroups = [...groups, { checked, value }];
        dispatch(SetAvailableVoters(newGroups));
    };

    let statistics: VoteStatistics | undefined;

    const usersPossibleVoters = useMemo(
        () =>
            votingGroupsEnabled
                ? users.filter((user) => {
                      const find = availableVoters.find((voter) => voter.value === user.group);
                      return find?.checked;
                  })
                : users,
        [users, availableVoters, votingGroupsEnabled],
    );

    const userVotes = useMemo(
        () => usersPossibleVoters.filter((u) => u.hasVoted).map((u) => u.vote) as string[],
        [usersPossibleVoters],
    );

    if (revealVotes) {
        statistics = userVotes.length ? calculateVoteStatistics(userVotes, cards) : undefined;
    }
    const groupStatistics = useMemo(
        () =>
            votingGroups && revealVotes
                ? calculateVoteStatisticsForGroups(usersPossibleVoters, cards, votingGroups)
                : undefined,
        [usersPossibleVoters, votingGroups, cards, revealVotes],
    );
    const [finalEstimations, setFinalEstimations] = useState<{ group: string; value: number }[]>([]);

    const onSave = (vote: string | undefined) => {
        if (activeTicket?.ticketId) {
            dispatch(SaveVote(activeTicket.ticketId, votingGroups ? finalEstimations : vote));
        }
        dispatch(ResetVotes());
        dispatch(ChangeActiveTicketToNext());
    };
    return (
        <>
            <VoteCardsWithUsers
                users={usersPossibleVoters.filter(
                    (user) => groupFilter === "" || groupFilter === undefined || user.group === groupFilter,
                )}
                keepBubblesInPlace={!!displayVoteInBubble}
                showVotes={revealVotes}
                votePossible={userCanVote}
                removeVotePossible={!revealVotes}
                possibleScores={cards}
                userVote={activeUser.vote}
                selectVote={(userVote) => dispatch(Vote(userVote))}
                groups={votingGroups}
                filter={groupFilter}
                setFilter={setFilter}
                isSm={isSm}
            />
            {statistics && !votingGroups && <VoteSummary voteStatistics={statistics} />}
            {groupStatistics && (
                <VoteSummaryForGroups
                    voteStatistics={groupStatistics}
                    {...{ finalEstimations, setFinalEstimations, isSm }}
                />
            )}
            {isSm && (
                <VoteMgmtView
                    estimation={revealVotes ? statistics?.recommended?.toString() || "" : undefined}
                    onReset={() => dispatch(ResetVotes())}
                    onSave={onSave}
                    onReveal={() => dispatch(RevealVotes())}
                    canReveal={userVotes.length > 0}
                    everyOneVoted={
                        !revealVotes &&
                        usersPossibleVoters.length > 0 &&
                        userVotes.length === usersPossibleVoters.length
                    }
                    onMarkAsUnestimated={() => {
                        dispatch(MarkTicketAsUnestiamted(activeTicket?.ticketId as string));
                        dispatch(ResetVotes());
                    }}
                    wasTicketEstimated={!!activeTicket?.voted}
                    groups={votingGroups}
                    checkboxOnChange={checkboxOnChange}
                    availableVoters={availableVoters}
                />
            )}
        </>
    );
}
