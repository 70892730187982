import React, { useRef } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Icon from "@mui/material/Icon";
import { Button, Card, CardActions, CardContent, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minWidth: 275,
            maxWidth: 600,
            "& .MuiTextField-root": {
                margin: theme.spacing(2),
            },
            paddingRight: 10,
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
        button: {
            margin: theme.spacing(1),
        },
    }),
);

interface ICopyLink {
    url: string;
    startGameAction?: () => void;
}

export function CopyLinkView({ url, startGameAction }: ICopyLink) {
    const classes = useStyles();
    const textAreaRef = useRef(null);

    const handleOnClick = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        navigator.clipboard.writeText(url);
    };

    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography variant="h5" component="h6">
                    Your Session has been created!
                    <div></div>
                    Share link with your team to start your session!
                </Typography>

                <form className={classes.root} noValidate autoComplete="off">
                    <div>
                        <TextField
                            fullWidth
                            id="outlined-link"
                            label="Link"
                            variant="outlined"
                            defaultValue={url}
                            disabled
                            onClick={handleOnClick}
                            ref={textAreaRef}
                        />
                    </div>
                </form>
            </CardContent>
            <CardActions>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={<Icon>file_copy</Icon>}
                    onClick={handleOnClick}
                >
                    COPY LINK TO JOIN
                </Button>

                {startGameAction && (
                    <Button variant="contained" color="primary" className={classes.button} onClick={startGameAction}>
                        START GAME!
                    </Button>
                )}
            </CardActions>
        </Card>
    );
}
