export const JOIN_SESSION_STARTED = "JOIN_SESSION_STARTED";

export interface JoinRoomStartedAction {
    type: typeof JOIN_SESSION_STARTED;
    payload: JoinedRoomStartedActionPayload;
}

interface JoinedRoomStartedActionPayload {
    name: string;
    nick: string;
    group?: string;
}

export const JoinRoomStarted = (payload: JoinedRoomStartedActionPayload): JoinRoomStartedAction => {
    return {
        type: JOIN_SESSION_STARTED,
        payload,
    };
};
