import React from "react";
import { RoomState } from "../../interfaces/RoomState";
import { useSelector } from "react-redux";
import { JiraTicketQuickView } from "../JiraTicketQuickView/JiraTicketQuickView";
import { Typography } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { EstimationMode } from "../ConfigWizard/JqlQueryBuilder/JqlQueryBuilderConfig";

const useStyles = makeStyles(() =>
    createStyles({
        label: {
            marginTop: "1em",
            marginBottom: "1em",
        },
    }),
);

export function JiraTickersWithSimilarVoteConnected() {
    const classes = useStyles();
    const allReferenceTickets = useSelector((state: RoomState) => state.referenceTickets);
    const userVote = useSelector((state: RoomState) => state.activeUser.vote);
    const estimationMode = useSelector((state: RoomState) => state.config.estimationMode);

    if (userVote && estimationMode === EstimationMode.STORY_POINTS) {
        const referenceTickets = allReferenceTickets.find((rt) => rt.storyPoint.toString() === userVote);
        if (referenceTickets && referenceTickets.tickets.length) {
            return (
                <>
                    <Typography variant="h6" className={classes.label}>
                        Reference tickets with {userVote} story points
                    </Typography>
                    <JiraTicketQuickView data={referenceTickets.tickets} />
                </>
            );
        }
    }
    return <></>;
}
