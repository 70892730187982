import { Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import { useFormikContext } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { convertJiraTicket } from "../../../helpers/convertJiraTicket";
import { JiraApi } from "../../../helpers/JiraApi";
import { JiraFieldDescriptor } from "../../../interfaces/JiraTicket";
import { JiraTicketDetails } from "../../JiraTicketDetails/JiraTicketDetails";
import { JiraTicketDetailsPlaceholder } from "../../JiraTicketDetails/JiraTicketDetailsPlaceholder";
import SelectWithAutocomplete, { SelectValueObject } from "../../UI/SelectWithAutocomplete";
import { ExtraFieldsList } from "./ExtraFieldsList";
import { TicketViewCustomFieldsConfig } from "./TicketViewCustomFieldsConfig";

const useStyles = makeStyles(() =>
    createStyles({
        manual: {
            marginBottom: "2em",
        },
    }),
);

export interface TicketViewCustomFieldsProps {
    jiraApi: JiraApi;
}

export function TicketViewCustomFields({ jiraApi }: TicketViewCustomFieldsProps) {
    const classes = useStyles();
    const [previewTicket, setPreviewTicket] = useState<{ name: string; id: string }>();
    const {
        values: { extraFields, jql },
        setFieldValue,
    } = useFormikContext<TicketViewCustomFieldsConfig>();

    const { data: allFieldsOptions, isLoading: isTicketFieldsListLoading } = useQuery(
        "extended-field-list",
        () => jiraApi.getFields(),
        { staleTime: Infinity },
    );

    const { data: jqlTicketList, isLoading: jqlTicketListLoading } = useQuery(
        `jira-tickets-select-${jql}`,
        async () =>
            (await jiraApi.getTicketsForList(jql as string, 0)).issues.map((ticket) => ({
                id: ticket.key,
                name: `${ticket.key} - ${ticket.fields.summary}`,
            })),
        { retry: false },
    );

    const { data: jiraTicketSource } = useQuery(
        `ticket-for-preview-${previewTicket?.name}`,
        async () => (previewTicket?.id ? jiraApi.getRawTicket(previewTicket?.id) : undefined),
        { retry: 0, staleTime: Infinity },
    );

    useEffect(() => {
        if (!previewTicket && jqlTicketList?.length) {
            setPreviewTicket(jqlTicketList[0]);
        }
    }, [jqlTicketList, previewTicket]);

    const jiraTicket = useMemo(
        () => jiraTicketSource && convertJiraTicket(jiraTicketSource, jiraApi.publicBaseUrl, extraFields || [], ""),
        [extraFields, jiraApi.publicBaseUrl, jiraTicketSource],
    );
    return (
        <>
            <Typography variant="subtitle1" className={classes.manual}>
                Configure which data will be visible in ticket preview
                <br />
            </Typography>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <SelectWithAutocomplete
                        multiple
                        label="Select field to add"
                        isLoading={isTicketFieldsListLoading}
                        options={allFieldsOptions?.map((v) => ({ id: v.id, name: v.label })) || []}
                        value={(extraFields ?? []).map((v) => ({ id: v.id, name: v.label })) || null}
                        onNewValue={(value) => {
                            if (value) {
                                setFieldValue(
                                    "extraFields",
                                    value?.map(
                                        (selected) =>
                                            allFieldsOptions?.find((v) => v.id === selected.id) as JiraFieldDescriptor,
                                    ),
                                );
                            }
                        }}
                    />
                    <ExtraFieldsList extraFields={extraFields || []} setFieldValue={setFieldValue} />
                    <SelectWithAutocomplete
                        label="Preview ticket"
                        value={previewTicket || null}
                        onNewValue={(value) => setPreviewTicket(value as SelectValueObject)}
                        isLoading={jqlTicketListLoading}
                        options={jqlTicketList || []}
                        disableClearable={true}
                    />
                </Grid>
                <Grid item xs={8}>
                    {jiraTicket ? <JiraTicketDetails {...jiraTicket} /> : <JiraTicketDetailsPlaceholder />}
                </Grid>
            </Grid>
        </>
    );
}
