import { Chip } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import { useJiraTicketStyles } from "./useJiraTicketStyles";

export function Chips({ components, label }: { components?: string[]; label: string }) {
    const classes = useJiraTicketStyles();

    return (
        <>
            <Typography variant="h6" component="h2" className={classes.item}>
                {label}
            </Typography>
            {(components || []).map((chipLabel) => (
                <Chip label={chipLabel} key={`label-${chipLabel}`} className={classes.chip} />
            ))}
        </>
    );
}
