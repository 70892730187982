import React from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Card, CardContent, ImageList } from "@mui/material";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import { JiraTicketDetails } from "../JiraTicketDetails/JiraTicketDetails";
import { JiraTicket } from "../../interfaces/JiraTicket";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridList: {
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            transform: "translateZ(0)",
            "overflow-y": "unset",
        },
        card: {
            height: "120px !important", // materialUi apply custom height so we need to override it with important
            marginRight: "15px",
            "&:hover": {
                cursor: "pointer",
            },
        },
        title: {
            fontSize: 16,
        },
        ticket: {
            fontSize: 14,
        },
        dialogContent: {},
    }),
);

interface IJiraTicketShortDetailsProps {
    data: JiraTicket[];
}

export function JiraTicketQuickView({ data }: IJiraTicketShortDetailsProps) {
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState<JiraTicket | undefined>();

    const handleClickOpen = (value: JiraTicket) => {
        setOpen(true);
        setSelectedValue(value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const classes = useStyles();

    return (
        <>
            <ImageList className={classes.gridList}>
                {data.map((card) => (
                    <Card key={card.ticketId} className={classes.card} onClick={() => handleClickOpen(card)}>
                        <CardContent>
                            <Typography className={classes.ticket} color="textSecondary" gutterBottom>
                                {card.ticketId}
                            </Typography>
                            <Typography className={classes.title} component="h2">
                                {card.title}
                            </Typography>
                        </CardContent>
                    </Card>
                ))}
            </ImageList>
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                onClose={handleClose}
                aria-labelledby="jira-details-dialog"
                open={open}
            >
                <div className={classes.dialogContent}>
                    <JiraTicketDetails {...(selectedValue as JiraTicket)}></JiraTicketDetails>
                </div>
            </Dialog>
        </>
    );
}
