import { Button, Icon } from "@mui/material";

export function CopyLinkButton({ roomId }: { roomId: string }) {
    const handleCopyLink = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        const url = `${window.location.origin}/room/${roomId}`;
        navigator.clipboard.writeText(url);
    };

    return (
        <div
            style={{
                textAlign: "left",
                marginBottom: "1.5em",
                marginTop: "-0.8em",
            }}
        >
            <Button variant="contained" startIcon={<Icon>file_copy</Icon>} onClick={handleCopyLink}>
                COPY LINK TO JOIN
            </Button>
        </div>
    );
}
