export const JOINED_SESSION = "JOINED_SESSION";

export interface JoinedSessionAction {
    type: typeof JOINED_SESSION;
    payload: JoinedSessionActionPayload;
}

interface JoinedSessionActionPayload {
    token: string;
    wsUrl: string;
    uuid: string;
    name: string;
    nick: string;
    roomId: string;
    group?: string;
}
export const JoinedSession = (payload: JoinedSessionActionPayload): JoinedSessionAction => {
    return {
        type: JOINED_SESSION,
        payload,
    };
};
