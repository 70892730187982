import * as moo from "moo";

export const specification = {
    WS: /[ \t]+/,
    keyword: ["AND", "OR"],
    orderKeyword: /ORDER BY|order by/,
    orderDirection: /ASC|DESC|asc|desc/,
    string: /"(?:\\["\\]|[^\n"\\])*"/,
    openString: /"(?:\\["\\]|[^\n"\\])*/,
    function: /[a-zA-Z]+\(\)/,
    operator: ["=", ">", ">=", "~", "!=", "<", "<=", "!~"],
    word: {
        match: /was in|WAS IN|not in|NOT IN|is not|IS NOT|was not|WAS NOT|was not in|WAS NOT IN|[a-zA-Z]+/,
        type: moo.keywords({
            operator: [
                "was in",
                "WAS IN",
                "changed",
                "CHANGED",
                "not in",
                "NOT IN",
                "is not",
                "IS NOT",
                "was not",
                "WAS NOT",
                "was not in",
                "WAS NOT IN",
                "in",
                "IN",
                "is",
                "IS",
                "was",
                "WAS",
            ],
        }),
    },
    arrayStart: /\(/,
    arrayEnd: /\)/,
    comma: /,/,
};

export type Tokens = keyof typeof specification;
