import React from "react";
import {
    Avatar,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
} from "@mui/material";
import CloudIcon from "@mui/icons-material/Cloud";
import StorageIcon from "@mui/icons-material/Storage";
import DeleteIcon from "@mui/icons-material/Delete";
import { JiraInstanceCredentials } from "../../interfaces/JiraInstanceCredentials";

export function ExistingConnectionsList({
    existingConnections,
    updateConnections,
    onSelect,
}: {
    existingConnections: JiraInstanceCredentials[];
    updateConnections: (newList: JiraInstanceCredentials[]) => void;
    onSelect: (instance: JiraInstanceCredentials) => void;
}) {
    const removeConnection = (indexToRemove: number) => {
        updateConnections(existingConnections.filter((_, index) => index !== indexToRemove));
    };
    return (
        <List>
            {existingConnections.map((instance, index) => (
                <ListItem key={index} button onClick={() => onSelect(instance)}>
                    <ListItemAvatar>
                        <Avatar>{instance.kind === "Server" ? <StorageIcon /> : <CloudIcon />}</Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={instance.instanceName} secondary={`user: ${instance.login}`} />
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" onClick={() => removeConnection(index)} size="large">
                            <DeleteIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
        </List>
    );
}
