export const USER_DISCONNECTED = "USER_DISCONNECTED";

export interface UserDisconnectedAction {
    type: typeof USER_DISCONNECTED;
    payload: {
        uuid: string;
    };
}

export const UserDisconnected = (uuid: string): UserDisconnectedAction => {
    return {
        type: USER_DISCONNECTED,
        payload: { uuid },
    };
};
