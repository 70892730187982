import { ReferenceTicket } from "../../interfaces/ReferenceTicket";
import { Actions } from "../actions/actions";
import { LOGOUT } from "../actions/Logout";
import { REFERENCE_TICKETS_LOADED } from "../actions/ReferenceTicketsLoaded";
import { ROOM_LOADED } from "../actions/RoomLoaded";

export function referenceTicketsReducer(state: ReferenceTicket[] = [], action: Actions): ReferenceTicket[] {
    switch (action.type) {
        case REFERENCE_TICKETS_LOADED:
            return [...action.payload];
        case ROOM_LOADED:
            if (action.payload.reference_tickets) {
                return [...action.payload.reference_tickets];
            }
            return [];
        case LOGOUT:
            return [];
        default:
            return state;
    }
}
