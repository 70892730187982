import {
    JiraTicket,
    JiraFieldDescriptor,
    JiraField,
    JiraAttachmentsField,
    JiraStringArrayField,
    JiraCommentsField,
} from "../interfaces/JiraTicket";
import { JiraIssueResponse } from "../interfaces/JiraIssuesResponse";

export function convertJiraTicket(
    source: JiraIssueResponse,
    jiraBaseUrl: string,
    fieldDescriptors: JiraFieldDescriptor[],
    storyPointFieldName?: string,
): JiraTicket {
    const extraFields = fieldDescriptors
        .map((extraField) => {
            if (extraField.type === "array" && extraField.id === "attachment") {
                return {
                    ...extraField,
                    items: "attachment",
                    value:
                        source?.fields?.attachment?.map((sourceAttachment) => ({
                            content: sourceAttachment.content,
                            fileName: sourceAttachment.filename,
                            thumbnail: sourceAttachment.thumbnail,
                        })) || [],
                } as JiraAttachmentsField;
            }

            if (extraField.type === "array" && extraField.id === "components") {
                return {
                    ...extraField,
                    items: "string",
                    value: source.fields.components.map((c) => c.name) || [],
                } as JiraStringArrayField;
            }

            if (extraField.type === "array" && extraField.id === "comment") {
                return {
                    ...extraField,
                    type: "array",
                    items: "comment",
                    value: source?.fields?.comment?.comments?.map((comment) => ({
                        authorImage: comment.author.avatarUrls["32x32"],
                        authorName: comment.author.displayName,
                        body: comment.body,
                        created: comment.created,
                    })),
                } as JiraCommentsField;
            }

            if (extraField.type === "array" && (extraField as JiraStringArrayField).items === "string") {
                return { ...extraField, value: (source.fields[extraField.id] as string[])?.map(parseSprintField) };
            }

            if (extraField.type === "string" && !!source.renderedFields[extraField.id]) {
                return { ...extraField, value: source.renderedFields[extraField.id], isLongText: true };
            }
            if (source.fields[extraField.id] && !!source.fields[extraField.id]) {
                return { ...extraField, value: source.fields[extraField.id] };
            }
            return undefined;
        })
        .filter((v) => !!v) as JiraField[];

    return {
        ticketId: source.key,
        url: `${jiraBaseUrl}/browse/${source.key}`,
        title: source.fields.summary,
        priority: source.fields.priority.name,
        reporter: source?.fields?.reporter?.displayName,
        storyPoint: storyPointFieldName ? (source.fields[storyPointFieldName] as string) : "",
        issueType: {
            name: source?.fields?.issuetype?.name,
            iconUrl: source?.fields?.issuetype?.iconUrl,
        },
        extraFields,
    };
}

function parseSprintField(text: string) {
    if (!text.includes("com.atlassian.greenhopper.service.sprint.Sprint")) {
        return text;
    }
    try {
        const items = Object.fromEntries(
            text
                .replace(/com\.atlassian\.greenhopper\.service\.sprint\.Sprint@\w+\[(.*)\]/, "$1")
                .split(",")
                .map((s) => s.split("=")),
        );
        return items["name"];
    } catch (e) {
        console.error("sprint parsing error", e);
        return text;
    }
}
