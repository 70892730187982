export const JWT_TOKEN_LOCAL_STORAGE_KEY = "user-token";

export const JWT_MEMBER_TOKEN_SESSION_STORAGE_KEY = "member-token";

export const WS_URL_LOCAL_STORAGE_KEY = "ws-url";

export const SESSION_CONFIG_LOCAL_STORAGE_KEY = "session-config";

export const SESSION_CONFIG_STORAGE_LOCAL_STORAGE_KEY = "config-storage";
export const JIRA_CREDENTIALS_STORAGE_LOCAL_STORAGE_KEY = "cred-storage";
export const MEMBER_NAME_AND_NICK_LOCAL_STORAGE_KEY = "member-name";
