import { Config } from "../../interfaces/Config";
import { JiraAttachmentsField, JiraCommentsField } from "../../interfaces/JiraTicket";
import { Actions } from "../actions/actions";
import { CONFIG_UPDATED } from "../actions/ConfigUpdated";
import { JIRA_INSTANCE_CONNECTED } from "../actions/JiraInstanceConnected";
import { LOGOUT } from "../actions/Logout";
import { TICKET_ADDED_OR_UPDATED } from "../actions/TicketAddedOrUpdated";
import { EstimationMode } from "../../components/ConfigWizard/JqlQueryBuilder/JqlQueryBuilderConfig";
import { ROOM_LOADED } from "../actions/RoomLoaded";
import { SET_AVAILABLE_VOTERS } from "../actions/SetAvailableVoters";

const defaultConfig: Config = {
    isVoting: false,
    name: "",
    shortName: "",
    estimationMode: EstimationMode.STORY_POINTS,
    extraTickets: [],
    cards: ["?", "1", "2", "3", "5", "8", "13", "21"],
    availableVoters: [],
    extraFields: [
        {
            id: "description",
            label: "Description",
            type: "string",
        },
        {
            id: "attachment",
            label: "Attachment",
            type: "array",
            items: "attachment",
        } as Omit<JiraAttachmentsField, "value">,
        {
            id: "comment",
            label: "Comment",
            type: "array",
            items: "comment",
        } as Omit<JiraCommentsField, "value">,
    ],
    votingGroupsEnabled: false,
    votingGroups: [],
};
export function configReducer(state: Config | undefined = defaultConfig, action: Actions): Config {
    switch (action.type) {
        case ROOM_LOADED:
            return { ...defaultConfig, ...action.payload.config };
        case SET_AVAILABLE_VOTERS:
            return {
                ...state,
                availableVoters: action.payload?.sort((a, b) => (a.value > b.value ? 1 : -1)) || [],
            };
        case JIRA_INSTANCE_CONNECTED:
            return { ...defaultConfig, jiraInstance: action.payload };
        case CONFIG_UPDATED:
            const { votingGroupsEnabled, votingGroups } = action.payload;
            return {
                ...action.payload,
                availableVoters: votingGroupsEnabled
                    ? votingGroups?.map((v) => ({ value: v, checked: true })) || []
                    : [],
                votingGroups: votingGroupsEnabled ? votingGroups : [],
            };
        case LOGOUT:
            return { ...defaultConfig };
        case TICKET_ADDED_OR_UPDATED:
            if (action.payload.isAddOne && !state.extraTickets?.some((tId) => tId === action.payload.ticketId)) {
                return { ...state, extraTickets: [...(state.extraTickets || []), action.payload.ticketId] };
            }
            return state;
        default:
            return state;
    }
}
