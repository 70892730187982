import { Button, Checkbox, FormControl, FormControlLabel } from "@mui/material";
import { Alert } from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { JiraApi } from "../../helpers/JiraApi";
import { TextFieldWithStatusIndications } from "../UI/TextFieldWithStatusIndications";

interface JiraLoginProps {
    jiraUrl: string;
    isCloud: boolean;
    onSetCredentials: (credentials: { login: string; password: string }, remember: boolean) => void;
}

export function JiraLogin({ jiraUrl, isCloud, onSetCredentials }: JiraLoginProps) {
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [rememberThisConnection, setRememberThisConnection] = useState<boolean>(false);

    const {
        refetch: validateCredentials,
        error,
        data: isValid,
    } = useQuery({
        queryKey: `jira-url-${login}-${password}`,
        queryFn: () => JiraApi.validateCredentials(jiraUrl, login, password),
        retry: 0,
        staleTime: Infinity,
        enabled: false,
    });

    return (
        <FormControl fullWidth={true}>
            <TextFieldWithStatusIndications
                fullWidth
                id="outlined-jira-username"
                label="Login"
                variant="outlined"
                value={login}
                onChange={(e) => {
                    setLogin(e.target.value);
                }}
                helperText="Login to jira"
                valid={isValid}
                required
            />
            <TextFieldWithStatusIndications
                fullWidth
                id="outlined-jira-password"
                label={isCloud ? "Api token" : "Password"}
                variant="outlined"
                type="password"
                onChange={(e) => {
                    setPassword(e.target.value);
                }}
                valid={isValid}
                helperText={
                    isCloud ? (
                        <span>
                            Api token (
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://confluence.atlassian.com/cloud/api-tokens-938839638.html"
                            >
                                learn more
                            </a>
                            )
                        </span>
                    ) : (
                        "Password to jira"
                    )
                }
                required
            />
            {error instanceof Error && <Alert severity="error">{error.message}</Alert>}
            <FormControlLabel
                control={<Checkbox color="primary" onChange={(e) => setRememberThisConnection(e.target.checked)} />}
                label="Remember this connection"
            />
            <Button
                variant="contained"
                color="primary"
                disabled={!login || !password}
                onClick={() => {
                    validateCredentials().then(
                        ({ isSuccess }) => isSuccess && onSetCredentials({ login, password }, rememberThisConnection),
                    );
                }}
            >
                Next
            </Button>
        </FormControl>
    );
}
