import { ThunkAction } from "redux-thunk";
import { JiraApi } from "../../helpers/JiraApi";
import { JiraTicket } from "../../interfaces/JiraTicket";
import { RoomState } from "../../interfaces/RoomState";
import { Actions } from "./actions";
import { ActiveTicketChanged } from "./ActiveTicketChanged";

export const TICKET_ADDED_OR_UPDATED = "TICKET_ADDED_OR_UPDATED";

export interface NewTicketAddedAction {
    type: typeof TICKET_ADDED_OR_UPDATED;
    payload: JiraTicket;
}

export const RefreshOrAddTicket =
    (
        ticketId: string,
        {
            setAsActive,
            resetVotes,
        }: {
            setAsActive: boolean;
            resetVotes: boolean;
        },
    ): ThunkAction<Promise<void>, RoomState, unknown, Actions> =>
    async (dispatch, getState) => {
        const { config, ticketList } = getState();
        const isAddOne = !ticketList.some((ticket) => ticket.ticketId === ticketId);
        dispatch({
            type: TICKET_ADDED_OR_UPDATED,
            payload: {
                ticketId,
                isPlaceholder: true,
            } as JiraTicket,
        });

        if (!config.jiraInstance) {
            console.error("Unexpected state! Missing jira instance on CONFIG_UPDATED");
            return;
        }
        const jiraApi = new JiraApi(config.jiraInstance);

        jiraApi.getTicket(ticketId, config).then((ticket) => {
            dispatch({
                type: TICKET_ADDED_OR_UPDATED,
                payload: { ...ticket, isAddOne },
            });
            if (setAsActive) {
                dispatch(ActiveTicketChanged(ticket, resetVotes));
            }
        });
    };
