import { ThunkAction } from "redux-thunk";
import { JiraTicket } from "../../interfaces/JiraTicket";
import { RoomState } from "../../interfaces/RoomState";
import { ActiveTicketChanged } from "../actions/ActiveTicketChanged";
import { Actions } from "./actions";

export const TICKETS_LIST_LOADED = "TICKETS_LIST_LOADED";

export interface TicketsListLoadedAction {
    type: typeof TICKETS_LIST_LOADED;
    payload: JiraTicket[];
}

export const TicketsListLoaded =
    (payload: JiraTicket[]): ThunkAction<void, RoomState, unknown, Actions> =>
    (dispatch) => {
        dispatch({
            type: TICKETS_LIST_LOADED,
            payload,
        });
        if (payload.length) {
            dispatch(ActiveTicketChanged(payload[0]));
        }
    };
