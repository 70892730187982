import React, { useEffect, useState } from "react";
import Header from "./components/header";
import PokerSessionPage from "./pages/PokerSessionPage";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { createTheme, CssBaseline, ThemeProvider, Theme, StyledEngineProvider, useMediaQuery } from "@mui/material";
import { ConfigPage } from "./pages/ConfigPage";
import { CreateSessionPage } from "./pages/CreateSessionPage";
import { IndexPage } from "./pages/IndexPage";
import "react-toastify/dist/ReactToastify.css";
import { AppConfig } from "./Config";

declare module "@mui/styles/defaultTheme" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

function App() {
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const [darkModeEnabled, setIsDarkMode] = useState(() => false);
    useEffect(() => {
        setIsDarkMode(prefersDarkMode);
    }, [prefersDarkMode]);
    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    primary: {
                        main: AppConfig.primaryColor,
                    },

                    mode: darkModeEnabled ? "dark" : "light",
                },
            }),
        [darkModeEnabled],
    );
    console.log(theme);
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Router>
                    <Header setIsDarkMode={setIsDarkMode} darkModeEnabled={darkModeEnabled} />
                    <ToastContainer position="bottom-center" toastStyle={{ backgroundColor: "#f00" }} />
                    <div>
                        <Switch>
                            <Route path="/" exact component={IndexPage} />
                            <Route path="/create-session" exact component={CreateSessionPage} />
                            <Route path="/config" component={ConfigPage} />
                            <Route path="/room/:roomId" component={PokerSessionPage} />
                        </Switch>
                    </div>
                </Router>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
