import { ActiveUser, UserState, UserType } from "../../interfaces/ActiveUser";
import { JwtPayload } from "../../interfaces/JwtTokenPayload";

export const SESSION_RESTORED = "SESSION_RESTORED";

export interface SessionRestoredAction {
    type: typeof SESSION_RESTORED;
    payload: Required<Omit<ActiveUser, "vote">>;
}

export const SessionRestored = (payload: JwtPayload, jwt: string, wsUrl: string): SessionRestoredAction => {
    return {
        type: SESSION_RESTORED,
        payload: {
            state: UserState.READY,
            token: jwt,
            roomId: payload.roomId,
            userType: payload.role === "member" ? UserType.MEMBER : UserType.SCRUM_MASTER,
            wsUrl,
            uuid: payload.uuid,
            wasKicked: false,
            group: payload.group,
        },
    };
};
