import React, { FunctionComponent } from "react";
import { User } from "../../interfaces/User";
import { UserBubble } from "../UserBubble/UserBubble";
import "./UserList.css";
interface UserListProps {
    showVotes: boolean;
    users: User[];
}
export const UserList: FunctionComponent<UserListProps> = ({ showVotes, users }: UserListProps) => {
    const userList = showVotes
        ? [...users].sort((a, b) => {
              const voteA = Number(a.vote);
              const voteB = Number(b.vote);
              if (Number.isNaN(voteA)) {
                  return -1;
              }
              if (Number.isNaN(voteB)) {
                  return 1;
              }
              return voteA - voteB;
          })
        : users;
    return (
        <div className="user-list">
            {userList.map((user) => (
                <UserBubble {...user} showVotes={showVotes} key={user.id} flipId={`usr-${user.id}`} />
            ))}
        </div>
    );
};
