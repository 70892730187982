import { JiraTicket } from "../../interfaces/JiraTicket";
import { Actions } from "../actions/actions";
import { ACTIVE_TICKET_CHANGED } from "../actions/ActiveTicketChanged";
import { LOGOUT } from "../actions/Logout";
import { MARK_TICKET_AS_UNESTIAMTED } from "../actions/MarkTicketAsUnestiamted";
import { ROOM_LOADED } from "../actions/RoomLoaded";
import { SAVE_VOTE } from "../actions/SaveVote";
import { TICKET_ADDED_OR_UPDATED } from "../actions/TicketAddedOrUpdated";
import { TICKETS_LIST_REFRESHED } from "../actions/TicketsListRefreshed";

export function activeTicketReducer(state: JiraTicket | null = null, action: Actions): JiraTicket | null {
    switch (action.type) {
        case ROOM_LOADED:
            if (action.payload.active_ticket) {
                return { ...action.payload.active_ticket };
            }
            return null;
        case ACTIVE_TICKET_CHANGED:
            return { ...action.payload };
        case SAVE_VOTE:
            if (state?.ticketId === action.payload.ticketId) {
                return { ...state, voted: true };
            }
            return state;
        case MARK_TICKET_AS_UNESTIAMTED:
            if (state?.ticketId === action.payload.ticketId) {
                return { ...state, voted: false };
            }
            return state;
        case TICKET_ADDED_OR_UPDATED:
            if (state?.ticketId === action.payload.ticketId) {
                return { ...action.payload };
            }
            return state;
        case TICKETS_LIST_REFRESHED:
            const updated = action.payload.find((t) => t.ticketId === state?.ticketId);
            if (updated) {
                return { ...updated, isAddOne: state?.isAddOne, voted: state?.voted };
            }
            return state;
        case LOGOUT:
            return null;
        default:
            return state;
    }
}
