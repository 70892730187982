import React, { useState } from "react";
import { JiraLogin } from "./JiraLogin";
import { JiraUrlInput, OnJiraSetPayload } from "./JiraUrlInput";
import { Card, CardContent, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import useLocalStorage from "react-use/lib/useLocalStorage";
import "./JiraConnect.css";
import { ExistingConnectionsList } from "./ExistingConnectionsList";
import { JiraInstanceCredentials } from "../../interfaces/JiraInstanceCredentials";
import { JIRA_CREDENTIALS_STORAGE_LOCAL_STORAGE_KEY } from "../../interfaces/LocalStorageKeys";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minWidth: 275,
            maxWidth: 600,
            "& .MuiTextField-root": {
                margin: theme.spacing(2),
            },
            paddingRight: 10,
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
    }),
);

export interface JiraConnectProps {
    onConnected: (instance: JiraInstanceCredentials) => void;
}
export function JiraConnect({ onConnected }: JiraConnectProps) {
    const classes = useStyles();
    const [jira, setJira] = useState<OnJiraSetPayload>({ isSet: false });
    const [storedConnectionsList, updateStoredConnectionsList] = useLocalStorage<JiraInstanceCredentials[]>(
        JIRA_CREDENTIALS_STORAGE_LOCAL_STORAGE_KEY,
        [],
    );

    const saveConnection = (connectionData: JiraInstanceCredentials) =>
        updateStoredConnectionsList([...(storedConnectionsList || []), connectionData]);

    const onSave = ({ login, password }: { login: string; password: string }, remember?: boolean) => {
        if (!jira.isSet) {
            return;
        }
        const connection = {
            ...jira,
            login,
            password,
        };
        if (remember) {
            saveConnection(connection);
        }
        onConnected(connection);
    };

    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography variant="h5" component="h2">
                    Connect to your jira instance to create poker session
                </Typography>{" "}
                <JiraUrlInput onJiraSet={setJira}></JiraUrlInput>
                {jira.isSet && (
                    <JiraLogin
                        jiraUrl={jira.url as string}
                        isCloud={jira.kind === "Cloud"}
                        onSetCredentials={onSave}
                    ></JiraLogin>
                )}
                {storedConnectionsList && storedConnectionsList.length > 0 && (
                    <div className="existing-connections">
                        <Typography variant="h6">Or use previous connection</Typography>
                        <ExistingConnectionsList
                            existingConnections={storedConnectionsList || []}
                            updateConnections={updateStoredConnectionsList}
                            onSelect={onConnected}
                        />
                    </div>
                )}
            </CardContent>
        </Card>
    );
}
