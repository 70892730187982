import * as yup from "yup";
import { EstimationMode } from "./JqlQueryBuilderConfig";

export const jqlQueryBuilderValidationSchema = yup.object({
    project: yup
        .object()
        .shape({
            name: yup.string().required("project is required"),
            id: yup.string().required("project is required"),
        })
        .required(),
    estimationMode: yup
        .string()
        .oneOf([EstimationMode.HOURS, EstimationMode.STORY_POINTS, EstimationMode.MANDAYS])
        .default(EstimationMode.STORY_POINTS)
        .required(),
    storyPointsField: yup
        .object()
        .shape({
            name: yup.string(),
            id: yup.string(),
            cfid: yup.string(),
        })
        .when("estimationMode", {
            is: EstimationMode.STORY_POINTS,
            then: (schema) => schema.required("story points field is required"),
        }),
    jql: yup.string().required(),
    referenceTicketsJql: yup.string().required(),
    isJqlValid: yup.boolean().required().oneOf([true], "Invalid JQL"),
});
