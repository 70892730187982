export const ROOM_CREATED = "ROOM_CREATED";

export interface RoomCreatedAction {
    type: typeof ROOM_CREATED;
    payload: RoomCreatedActionPayload;
}

interface RoomCreatedActionPayload {
    roomId: string;
    token: string;
    wsUrl: string;
}
export const RoomCreated = (payload: RoomCreatedActionPayload): RoomCreatedAction => {
    return {
        type: ROOM_CREATED,
        payload,
    };
};
