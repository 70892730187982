import * as moo from "moo";
import { Tokens } from "./languageSpecification";
import { JqlState, getDefaultState } from "./JqlState";

export function processOrderToken(token: moo.Token, srcState: JqlState): JqlState {
    const state = { ...srcState };

    switch (token.type as Tokens) {
        case "orderKeyword":
            break;
        case "string":
        case "word":
            state.activeOderSection.field = token.value;
            break;
        case "orderDirection":
            state.activeSection = "orderDirection";
            state.activeOderSection.direction = token.value.toUpperCase() as "ASC" | "DESC";
            break;
        case "comma":
            state.orderBy.push({ ...state.activeOderSection });
            state.orderBy
                .filter((o) => o.direction === undefined)
                .forEach((o) => (o.direction = state.activeOderSection.direction));
            state.activeSection = "field";
            state.activeOderSection = getDefaultState().activeOderSection;
            break;
        case "WS":
            break;
        default:
            console.error(`Unexpected token (${token.type} in order section)!`, token);
            break;
    }
    return state;
}
