import React from "react";
import { User } from "../../interfaces/User";
import { SeeMoreBubble } from "../UserBubble/SeeMoreBubble";
import { UserBubble } from "../UserBubble/UserBubble";
import { VoteCard } from "../VoteCard/VoteCard";
import "./VoteCards.css";
export interface VoteCardsProps {
    showVotes: boolean;
    possibleScores: string[];
    users: User[];
    includeFlipId: boolean;
    votePossible: boolean;
    removeVotePossible?: boolean;
    userVote?: string;
    selectVote: (vote?: string) => void;
}

const CROWDED_LIMIT = 10;
export function VoteCards({
    showVotes,
    users,
    possibleScores,
    includeFlipId,
    votePossible,
    removeVotePossible,
    userVote,
    selectVote,
}: VoteCardsProps) {
    const userVotes: [string, User[]][] = possibleScores.map((score) => [
        score,
        users.filter((user) => user.vote && user.vote.toString() === score),
    ]);
    return (
        <>
            <div className="vote-cards">
                {userVotes.map(([score, scoreUsers]) => (
                    <VoteCard
                        score={score}
                        key={`vote-card-${score}`}
                        votePossible={votePossible}
                        selected={score === userVote}
                        onClick={() => {
                            if (removeVotePossible === false && userVote === score) {
                                return;
                            }
                            selectVote(userVote === score ? undefined : score);
                        }}
                    >
                        <div
                            className={`vote-card__card--voters ${scoreUsers.length >= CROWDED_LIMIT && "crowded"} ${
                                showVotes && "votes-visible"
                            }`}
                        >
                            {scoreUsers
                                .filter(() => showVotes)
                                .map((user) => (
                                    <UserBubble
                                        {...user}
                                        showVotes={showVotes}
                                        key={`user-${user.id}`}
                                        flipId={includeFlipId ? `usr-${user.id}` : undefined}
                                    />
                                ))}

                            {scoreUsers.length >= CROWDED_LIMIT && (showVotes || includeFlipId) && (
                                <SeeMoreBubble count={scoreUsers.length - 2} includeFlipId={includeFlipId} />
                            )}
                        </div>
                    </VoteCard>
                ))}
            </div>
        </>
    );
}
