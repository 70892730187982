import * as moo from "moo";
import { Tokens } from "./languageSpecification";
import { JqlState, dumpState } from "./JqlState";

export function processQueryToken(token: moo.Token, srcState: JqlState): JqlState {
    const state: JqlState = { ...srcState };
    switch (token.type as Tokens) {
        case "string":
        case "word":
            if (state.activeSection === "field") {
                state.queryBlock.field = token.value;
            }
            if (state.activeSection === "keyword") {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                state.queryBlock.keyword = token.value as any;
            }
            if (state.activeSection === "value") {
                if (state.queryBlock.isArrayValue) {
                    (state.queryBlock.value as string[]).push(token.value);
                } else {
                    state.queryBlock.value = token.value;
                }
            }
            break;
        case "openString":
            break;
        case "function":
            if (state.activeSection !== "value") {
                console.error("Invalid token position!", token);
            }
            if (state.queryBlock.isArrayValue) {
                (state.queryBlock.value as string[]).push(token.value);
            } else {
                state.queryBlock.value = token.value;
            }
            break;
        case "keyword":
            state.queryBlock.keyword = token.value as "AND" | "OR";
            break;
        case "WS":
            if (state.activeSection === "field") {
                state.activeSection = "operator";
                break;
            }
            if (state.activeSection === "keyword") {
                state.activeSection = "field";
                break;
            }
            if (state.activeSection === "operator") {
                state.activeSection = "value";
                break;
            }
            if (
                state.activeSection === "value" &&
                (!state.queryBlock.isArrayValue || (state.queryBlock.isArrayValue && state.queryBlock.isComplete))
            ) {
                state.queryBlock.isComplete = true;
                return {
                    ...dumpState(state),
                    text: state.text,
                    activeSection: "keyword",
                };
            }

            break;
        case "operator":
            if (state.activeSection !== "operator") {
                console.error("Invalid token position!", token);
            }
            state.queryBlock.operator = token.value;
            break;
        case "arrayStart":
            if (state.activeSection !== "value") {
                console.error("Invalid token position!", token);
            }
            state.queryBlock.isArrayValue = true;
            state.queryBlock.value = [];
            break;
        case "arrayEnd":
            if (state.activeSection !== "value") {
                console.error("Invalid token position!", token);
            }
            state.queryBlock.isComplete = true;
            break;
        default:
            break;
    }
    return state;
}
