import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { JiraConnect } from "../components/JiraConnect/JiraConnect";
import { JiraInstanceCredentials } from "../interfaces/JiraInstanceCredentials";
import { SESSION_CONFIG_STORAGE_LOCAL_STORAGE_KEY } from "../interfaces/LocalStorageKeys";
import { ConfigUpdated } from "../store/actions/ConfigUpdated";
import { JiraInstanceConnected } from "../store/actions/JiraInstanceConnected";
import "./sessionBox.css";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            paddingTop: "5em",
            alignSelf: "center",
            justifyItems: "center",
            textAlign: "center",
            display: "inline-grid",
            width: "100%",
        },
    }),
);

export function CreateSessionPage() {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();

    const onConnected = (instance: JiraInstanceCredentials) => {
        dispatch(JiraInstanceConnected(instance));
        const existingConfig = localStorage.getItem(
            `${SESSION_CONFIG_STORAGE_LOCAL_STORAGE_KEY}-${instance.publicBaseUrl}`,
        );
        if (existingConfig) {
            try {
                dispatch(ConfigUpdated({ ...JSON.parse(existingConfig), jiraInstance: instance }, true));
            } catch {
                console.warn("error while restoring jira session config");
            }
        }
        history.push("/config");
    };

    return (
        <div className={classes.root}>
            <JiraConnect onConnected={onConnected} />
        </div>
    );
}
