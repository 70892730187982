import { UserType } from "../../interfaces/ActiveUser";
import { WsCompatibleAction } from "./WsCompatibleAction";

export const SM_USER_KICK_OUT = "SM_USER_KICK_OUT";

export interface SmUserKickOutAction extends WsCompatibleAction {
    type: typeof SM_USER_KICK_OUT;
    payload: SmUserKickOutPayload;
}

export interface SmUserKickOutPayload {
    userId: string;
}

export function UserKickOut(payload: SmUserKickOutPayload): SmUserKickOutAction {
    return {
        type: SM_USER_KICK_OUT,
        sendOverWs: {
            when: (user) => user.userType === UserType.SCRUM_MASTER,
            format: (user) => ({ action: "sm_user_kick_out", token: user.token, data: payload }),
        },
        payload,
    };
}
