import { ThunkAction } from "redux-thunk";
import { UserType } from "../../interfaces/ActiveUser";
import { JiraTicket } from "../../interfaces/JiraTicket";
import { RoomState } from "../../interfaces/RoomState";
import { Actions } from "./actions";
import { ResetVotes } from "./ResetVotes";
import { WsCompatibleAction } from "./WsCompatibleAction";

export const ACTIVE_TICKET_CHANGED = "ACTIVE_TICKET_CHANGED";

export interface ActiveTicketChangedAction extends WsCompatibleAction {
    type: typeof ACTIVE_TICKET_CHANGED;
    payload: JiraTicket;
}

export const ActiveTicketChanged =
    (payload: JiraTicket, resetVotes = true): ThunkAction<void, RoomState, unknown, Actions> =>
    (dispatch) => {
        dispatch({
            type: ACTIVE_TICKET_CHANGED,
            payload,
            sendOverWs: {
                when: (user) => user.userType === UserType.SCRUM_MASTER,
                format: ({ token }) => ({ action: "active_ticket", data: JSON.stringify(payload), token: token || "" }),
            },
        } as ActiveTicketChangedAction);
        if (resetVotes) {
            dispatch(ResetVotes());
        }
    };

export const ChangeActiveTicketToNext = (): ThunkAction<void, RoomState, unknown, Actions> => (dispatch, getState) => {
    const { ticketList, activeTicket } = getState();
    const activeTicketIndex = getTicketIndex(ticketList, activeTicket);

    const newTicket = next(ticketList, activeTicketIndex);

    if (newTicket) {
        dispatch(ActiveTicketChanged(newTicket));
    }
};

export const ChangeActiveTicketToPrev = (): ThunkAction<void, RoomState, unknown, Actions> => (dispatch, getState) => {
    const { ticketList, activeTicket } = getState();
    const activeTicketIndex = getTicketIndex(ticketList, activeTicket);

    const newTicket = prev(ticketList, activeTicketIndex);

    if (newTicket) {
        dispatch(ActiveTicketChanged(newTicket));
    }
};

function getTicketIndex(ticketList?: JiraTicket[], activeTicket?: JiraTicket | null): number | undefined {
    const activeTicketId = activeTicket?.ticketId;
    if (activeTicketId && ticketList?.length) {
        const activeTicketIndex = ticketList?.findIndex((ticket) => ticket.ticketId === activeTicketId);
        return activeTicketIndex;
    }
}

function next<T>(list: T[], activeIndex?: number): T | undefined {
    const newIndex = activeIndex !== undefined && activeIndex < list.length - 1 ? activeIndex + 1 : 0;
    if (list[newIndex]) {
        return list[newIndex];
    }
    return undefined;
}

function prev<T>(list: T[], activeIndex?: number): T | undefined {
    const newIndex = activeIndex !== undefined && activeIndex - 1 >= 0 ? activeIndex - 1 : list.length - 1;
    if (list[newIndex]) {
        return list[newIndex];
    }
    return undefined;
}
