/* eslint-disable @typescript-eslint/ban-types */
import { toast } from "react-toastify";
import { UserType } from "../../interfaces/ActiveUser";
import { RoomState } from "../../interfaces/RoomState";
import { Actions } from "../actions/actions";
import { ActiveTicketChanged } from "../actions/ActiveTicketChanged";
import { ConfigUpdated } from "../actions/ConfigUpdated";
import { JOINED_SESSION } from "../actions/JoinedSession";
import { ReferenceTicketsLoaded } from "../actions/ReferenceTicketsLoaded";
import { ResetVotes } from "../actions/ResetVotes";
import { RevealVotes } from "../actions/RevealVotes";
import { ROOM_CREATED } from "../actions/RoomCreated";
import { SESSION_RESTORED } from "../actions/SessionRestored";
import { UserDisconnected } from "../actions/UserDisconnected";
import { UserJoined } from "../actions/UserJoined";
import { UserListLoaded } from "../actions/UserListLoaded";
import { UserVoted } from "../actions/UserVoted";
import { VotesRevealed } from "../actions/VotesRevealed";
import { WsCompatibleAction } from "../actions/WsCompatibleAction";
import { ReferenceTicket } from "../../interfaces/ReferenceTicket";
import { UserKicked } from "../actions/UserKicked";
import { Logout } from "../actions/Logout";

export enum Events {
    USER_JOINED = "user_joined",
    PING = "ping",
    USER_DISCONNECTED = "user_disconnected",
    CURRENT_STATE = "current_state",
    ACTIVE_TICKET = "active_ticket",
    REFERENCE_TICKETS = "reference_tickets",
    RESET_VOTES = "reset_votes",
    REVEAL_VOTES = "reveal_votes",
    USER_VOTED = "user_voted",
    CONFIG_CHANGED = "config",
    SM_USER_KICK_OUT = "sm_user_kick_out",
}

export enum Roles {
    SM = "sm",
    DEV = "member",
}

let ws: WebSocket;
const messageQueue: object[] = [];

function connectToWs(url: string, onData: (event: string) => void, token: string) {
    if (ws && ws?.readyState === WebSocket.OPEN) {
        ws.close();
    }

    let pingTimeout: NodeJS.Timeout;
    const sendPing = () => {
        ws.send(JSON.stringify({ action: Events.PING, token }));
        pingTimeout = setTimeout(() => sendPing(), 5 * 60 * 1000);
    };

    ws = new WebSocket(url);
    const helloMsg: OutgoingMessage = { action: Events.USER_JOINED, token };
    ws.onopen = () => {
        ws.send(JSON.stringify(helloMsg));
        while (messageQueue.length > 0) {
            const msg = messageQueue.shift();
            ws.send(JSON.stringify({ ...msg, token }));
        }
        sendPing();
    };
    ws.onmessage = (event) => {
        onData(event.data);
    };

    ws.onclose = (event) => {
        console.log("WS reconnect started");
        setTimeout(() => connectToWs(url, onData, token), 0);
        clearTimeout(pingTimeout);
    };

    ws.onerror = () => {
        toast.error("Problem with connection to server (websockets error)!");
        if (pingTimeout) {
            clearTimeout(pingTimeout);
        }
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const websocketsMiddleware = (store: any) => (next: any) => (action: Actions) => {
    if (ws && action?.type && action.type === "LOGOUT") {
        ws.onopen = null;
        ws.onclose = null;
        ws.onmessage = null;
        ws.onerror = null;
        ws.close();
    } else if (
        action?.type &&
        (action.type === ROOM_CREATED || action.type === JOINED_SESSION || action.type === SESSION_RESTORED)
    ) {
        const userType =
            action.type === SESSION_RESTORED
                ? action.payload.userType
                : action.type === ROOM_CREATED
                  ? UserType.SCRUM_MASTER
                  : UserType.MEMBER;
        connectToWs(
            action.payload.wsUrl,
            (event) => processIncomingEvent(event, (data) => store.dispatch(data), userType, store),
            action.payload.token,
        );
    }

    if (isWsCompatibleAction(action)) {
        const { sendOverWs, ...filteredAction } = action;
        const activeUser = (store.getState() as RoomState).activeUser;
        if (sendOverWs.when(activeUser)) {
            const message = sendOverWs.format(activeUser) as object;
            if (ws?.readyState !== WebSocket.OPEN) {
                messageQueue.push(message);
            } else {
                ws.send(JSON.stringify(message));
            }
        }
        return next(filteredAction);
    }

    return next(action);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function processIncomingEvent(eventAsString: string, dispatch: (obj: unknown) => void, userType: UserType, store: any) {
    let event: IncomingMessage;
    try {
        event = JSON.parse(eventAsString);
    } catch {
        console.error("WS event is not a valid json!", eventAsString);
        return;
    }
    switch (event.type) {
        case Events.ACTIVE_TICKET:
            const activeTicketData = event.data as ActiveTicketMessage;
            if (userType === UserType.MEMBER) {
                let newTicket;

                try {
                    newTicket = JSON.parse(activeTicketData.active_ticket);
                } catch (error) {
                    newTicket = activeTicketData.active_ticket;
                }

                const resetVotes = !store.getState().activeTicket?.ticketId === newTicket.ticketId;

                dispatch(ActiveTicketChanged(newTicket, resetVotes));
            }
            break;
        case Events.USER_JOINED:
            const userData = event.data as UserJoinedMessage;
            dispatch(
                UserJoined({
                    name: userData.name,
                    nick: userData.nick || "",
                    id: userData.uuid,
                    group: userData.group,
                    hasVoted: false,
                }),
            );

            break;
        case Events.USER_DISCONNECTED:
            const disconnectedData = event.data as UserDisconnectedMessage;
            dispatch(UserDisconnected(disconnectedData.uuid));
            break;
        case Events.SM_USER_KICK_OUT:
            const kickedData = event.data as UserKickedMessage;
            const activeUser = (store.getState() as RoomState).activeUser;

            if (activeUser.uuid === kickedData.userId) {
                dispatch(Logout(true));
            } else {
                dispatch(UserKicked(kickedData.userId));
            }
            break;
        case Events.CURRENT_STATE:
            const stateData = event.data as CurrentStateMessage;

            if (stateData.referenceTickets && userType === UserType.MEMBER) {
                dispatch(ReferenceTicketsLoaded(JSON.parse(stateData.referenceTickets as string)));
            }
            if (stateData.activeTicket && userType === UserType.MEMBER) {
                dispatch(ActiveTicketChanged(JSON.parse(stateData.activeTicket as string)));
            }
            if (stateData.config && userType === UserType.MEMBER) {
                dispatch(ConfigUpdated(JSON.parse(stateData.config), true));
            }

            dispatch(
                UserListLoaded(
                    stateData.usersData
                        .filter((u) => u.name !== undefined)
                        .map((u) => ({
                            vote: u.vote,
                            hasVoted: u.voted,
                            role: u.role,
                            name: u.name || "",
                            nick: u.nick || "",
                            group: u.group,
                            id: u.uuid,
                        })),
                ),
            );

            if (stateData.usersData.filter((u) => !!u.vote).length) {
                dispatch(RevealVotes());
            }

            break;
        case Events.REFERENCE_TICKETS:
            const referenceTicketsData = event.data as ReferenceTicketsMessage;
            if (userType === UserType.MEMBER) {
                let referenceTicketsDataContent: ReferenceTicket[];

                try {
                    referenceTicketsDataContent = JSON.parse(referenceTicketsData.reference_tickets);
                } catch (error) {
                    referenceTicketsDataContent =
                        referenceTicketsData.reference_tickets as unknown as ReferenceTicket[];
                }

                dispatch(ReferenceTicketsLoaded(referenceTicketsDataContent));
            }
            break;
        case Events.USER_VOTED:
            const userVoteddata = event.data as UserVotedMessage;
            const userRecord = (store.getState() as RoomState).users.find((u) => u.id === userVoteddata.uuid);
            dispatch(
                UserVoted({
                    userId: userVoteddata.uuid,
                    vote: userVoteddata.vote,
                    hasVoted: userVoteddata.voted,
                    ...(userRecord && { userName: userRecord.name, previousVote: userRecord.vote }),
                }),
            );
            break;
        case Events.REVEAL_VOTES:
            const revealData = event.data as RevealVotesMessage[];
            dispatch(VotesRevealed(revealData));
            break;
        case Events.RESET_VOTES:
            dispatch(ResetVotes(true));
            break;
        case Events.PING:
            break;
        case Events.CONFIG_CHANGED:
            if (userType === UserType.MEMBER) {
                const configChangedMsg = event.data as ConfigChangedMessage;
                let configChangedMsgContent;

                try {
                    configChangedMsgContent = JSON.parse(configChangedMsg.config) as ConfigChangedMessageJsonContent;
                } catch (error) {
                    configChangedMsgContent = configChangedMsg.config as unknown as ConfigChangedMessageJsonContent;
                }
                dispatch(ConfigUpdated(configChangedMsgContent, true));
            }
            break;
        default:
            console.error("Unknown WS event", event);
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isWsCompatibleAction = (action: any): action is WsCompatibleAction => action.sendOverWs !== undefined;

export interface OutgoingMessage {
    action: Events;
    data?: string;
    token: string;
}

export interface IncomingMessage {
    type: Events;
    data?:
        | UserJoinedMessage
        | CurrentStateMessage
        | ActiveTicketMessage
        | ReferenceTicketsMessage
        | RevealVotesMessage[]
        | UserVotedMessage
        | ConfigChangedMessage
        | UserKickedMessage;
}

export interface UserJoinedMessage {
    uuid: string;
    name: string;
    nick: string;
    role: string;
    group?: string;
}

export interface UserDisconnectedMessage {
    uuid: string;
}

export interface UserKickedMessage {
    userId: string;
}

export interface CurrentStateMessage {
    activeTicket: unknown;
    referenceTickets: unknown;
    usersData: {
        uuid: string;
        name: string;
        nick: string;
        role: string;
        vote?: string;
        voted: boolean;
        group?: string;
    }[];
    config: string;
}

export interface ActiveTicketMessage {
    active_ticket: string;
}

export interface ReferenceTicketsMessage {
    reference_tickets: string;
}

export interface RevealVotesMessage {
    uuid: string;
    vote: number;
    voted: boolean;
    role: Roles;
    group?: string;
}

export interface UserVotedMessage {
    uuid: string;
    voted: boolean;
    vote?: string;
}

export interface ConfigChangedMessage {
    config: string;
}

export interface ConfigChangedMessageJsonContent {
    cards: string[];
}
