import { User } from "../../interfaces/User";

export const USER_LIST_LOADED = "USER_LIST_LOADED";

export interface UserListLoadedAction {
    type: typeof USER_LIST_LOADED;
    payload: {
        users: User[];
    };
}

export const UserListLoaded = (users: User[]): UserListLoadedAction => {
    return {
        type: USER_LIST_LOADED,
        payload: { users },
    };
};
