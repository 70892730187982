import jwt_decode from "jwt-decode";
import { JwtPayload } from "../interfaces/JwtTokenPayload";
import { JWT_MEMBER_TOKEN_SESSION_STORAGE_KEY } from "../interfaces/LocalStorageKeys";
import { isJwtTokenExpired } from "./useJwtFromLocalStorage";

export const getMemberRoomToken = (roomId: string) => {
    try {
        const token = window.sessionStorage.getItem(JWT_MEMBER_TOKEN_SESSION_STORAGE_KEY) as string;
        const decoded = jwt_decode<JwtPayload>(token);

        if (isJwtTokenExpired(decoded)) {
            window.sessionStorage.removeItem(JWT_MEMBER_TOKEN_SESSION_STORAGE_KEY);
            return;
        }

        if (decoded.roomId !== roomId) {
            return;
        }

        return { decoded, token };
    } catch (e) {
        window.sessionStorage.removeItem(JWT_MEMBER_TOKEN_SESSION_STORAGE_KEY);
    }
};

export const storeMemberRoomToken = (token: string) => {
    window.sessionStorage.setItem(JWT_MEMBER_TOKEN_SESSION_STORAGE_KEY, token);
};

export interface MemberToken {
    token: string;
    roomId: string;
}
