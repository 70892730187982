import { compareTwoStrings } from "string-similarity";
import { SelectValueObject } from "../../components/UI/SelectWithAutocomplete";
import { JiraAutocompleteData } from "../../interfaces/JiraAutocompleteData";
import { JiraApi } from "../JiraApi";
import { JqlState } from "./JqlState";
import { processJql } from "./processJql";

export type AutocompleteState = [autocompleteSuggestions: SelectValueObject[], queryState: JqlState | undefined];
const DEFAULT_STATE: AutocompleteState = [[], undefined];
export async function handleJqlAutocomplete(
    jql: string,
    autocompleteData: JiraAutocompleteData,
    jiraApi: JiraApi,
): Promise<AutocompleteState> {
    if (jql.trim() === "") {
        return DEFAULT_STATE;
    }

    let jqlState: JqlState;
    try {
        jqlState = processJql(jql);
        const autocompleteSuggestions = await getAutocompleteSuggestions(autocompleteData, jiraApi, jqlState);
        const userInput = jqlState?.text ?? "";
        const filteredOptions = filterOptions(autocompleteSuggestions, userInput);

        return [filteredOptions, jqlState];
    } catch (e) {
        return DEFAULT_STATE;
    }
}

const keywordOptions = ["AND", "OR", "ORDER BY"].map((v) => ({ name: v, id: v }));
export async function getAutocompleteSuggestions(
    autocompleteData: JiraAutocompleteData,
    jiraApi: JiraApi,
    jqlState: JqlState,
): Promise<SelectValueObject[]> {
    if (jqlState.activeSection === "field") {
        return autocompleteData.visibleFieldNames.map((field) => ({
            name: field.displayName,
            id: field.value,
        }));
    } else if (jqlState.activeSection === "operator") {
        const operator = autocompleteData.visibleFieldNames.find((f) => f.value === jqlState.queryBlock.field);
        return operator?.operators.map((v) => ({ name: v, id: v })) || [];
    } else if (
        jqlState.activeSection === "value" &&
        jqlState.queryBlock.operator?.toUpperCase() === "IS" &&
        jqlState.queryBlock.field !== undefined &&
        jqlState.text !== undefined
    ) {
        return [{ name: "EMPTY", id: "EMPTY" }];
    } else if (
        jqlState.activeSection === "value" &&
        jqlState.queryBlock.field !== undefined &&
        jqlState.text !== undefined
    ) {
        return jiraApi.getAutocompleteSuggestions(jqlState.queryBlock.field, jqlState.text);
    } else if (jqlState.activeSection === "keyword") {
        return keywordOptions;
    } else {
        return [];
    }
}

function filterOptions(newOptions: SelectValueObject[], userInput: string) {
    if (userInput.trim() !== "") {
        return newOptions
            .filter((f) => f.name.toLowerCase().includes(userInput))
            .map((option) => ({
                ...option,
                compatibleRate: compareTwoStrings(option.name, userInput),
            }))
            .sort((a, b) => b.compatibleRate - a.compatibleRate);
    }
    return newOptions;
}
