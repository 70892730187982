import React, { Suspense } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RoomState } from "../interfaces/RoomState";
import { Logout } from "../store/actions/Logout";
import { toast } from "react-toastify";
import { AppConfig } from "../Config";
import { Link, useHistory } from "react-router-dom";
import { UserType } from "../interfaces/ActiveUser";
const AnimatedDarkModeSwitcher = React.lazy(() => import("./DarkModeSwitcher/SimpleDarkModeSwitcher"));

export interface HeaderBarProps {
    setIsDarkMode: (darkModeEnabled: boolean) => void;
    darkModeEnabled: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        header: {
            position: "sticky",
            zIndex: 99999,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        button: {
            marginLeft: "1em",
            borderColor: "#fff",
            color: "#fff",
        },
    }),
);

export default function HeaderBar({ setIsDarkMode, darkModeEnabled }: HeaderBarProps) {
    const classes = useStyles();
    const activeUser = useSelector((state: RoomState) => state.activeUser);
    const dispatch = useDispatch();
    const history = useHistory();

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.header}>
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        {AppConfig.appName}
                    </Typography>
                    <Suspense fallback={<></>}>
                        <AnimatedDarkModeSwitcher {...{ darkModeEnabled, setIsDarkMode }} />
                    </Suspense>

                    {activeUser.roomId && activeUser.userType === UserType.SCRUM_MASTER && (
                        <>
                            <Button component={Link} to={"/config"} className={classes.button} variant="outlined">
                                Settings
                            </Button>
                            <Button
                                variant="outlined"
                                className={classes.button}
                                onClick={() => {
                                    dispatch(Logout());
                                    history.push("/");
                                    toast.info("You have been logged out");
                                }}
                            >
                                Logout
                            </Button>
                        </>
                    )}
                </Toolbar>
            </AppBar>
        </div>
    );
}
