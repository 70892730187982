import { Roles } from "../middleware/websocketsMiddleware";

export const VOTES_REVEALED = "VOTES_REVEALED";

export interface VotesRevealedAction {
    type: typeof VOTES_REVEALED;
    payload: {
        users: VoteRevealedUser[];
    };
}
interface VoteRevealedUser {
    uuid: string;
    vote: number;
    voted: boolean;
    role: Roles;
    group?: string;
}

export function VotesRevealed(users: VoteRevealedUser[]): VotesRevealedAction {
    return {
        type: VOTES_REVEALED,
        payload: {
            users,
        },
    };
}
