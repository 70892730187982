import * as yup from "yup";

export const VotingSettingsValidationSchema = yup.object({
    isVoting: yup.boolean().required().default(false),
    name: yup
        .string()
        .optional()
        .when("isVoting", { is: true, then: yup.string().required("Name is required") }),
    shortName: yup
        .string()
        .optional()
        .when("isVoting", {
            is: true,
            then: yup.string().required("shortname is required").max(5, "Shortname must have less then 6 characters"),
        }),
    group: yup
        .string()
        .optional()
        .when(["isVoting", "votingGroupsEnabled"], {
            is: true,
            then: yup.string().required("group is required"),
        }),
    cards: yup.array().of(yup.string()).default([1, 2, 3, 5, 8]),
    votingGroupsEnabled: yup.boolean().required().default(false),
    votingGroups: yup.array().of(yup.string()).default([]).when("votingGroupsEnabled", {
        is: false,
        then: yup.array(),
    }),
    groupedVotesSaveMethod: yup.string().when("votingGroupsEnabled", {
        is: true,
        then: yup.string().required(),
    }),
});
