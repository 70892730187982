export type Section = "field" | "operator" | "value" | "orderDirection" | "keyword";

export interface JqlState {
    activeMode: "query" | "orderBy";
    activeSection: Section;
    text?: string;
    activeOderSection: OderBySection;
    queryBlock: JqlSection;
    sections: JqlSection[];
    orderBy: OderBySection[];
}
export interface JqlSection {
    keyword: "AND" | "OR";
    field?: string;
    operator?: string;
    isArrayValue?: boolean;
    value?: string | string[];
    isComplete: boolean;
}

export interface OderBySection {
    field: string;
    direction?: "DESC" | "ASC";
}

export const getDefaultState = (): JqlState => ({
    activeSection: "field" as Section,
    activeOderSection: { field: "" },
    activeMode: "query",
    queryBlock: {
        keyword: "AND",
        isComplete: false,
        isArrayValue: false,
    },
    sections: [],
    orderBy: [],
});

export const dumpState = (srcState: JqlState): JqlState => {
    const state = { ...srcState };
    state.sections.push({ ...state.queryBlock });
    return {
        ...getDefaultState(),
        activeMode: state.activeMode,
        sections: state.sections,
        orderBy: [...state.orderBy],
    };
};
