import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import * as Colors from "@material-ui/core/colors";
import Green from "@material-ui/core/colors/green";
import DoneIcon from "@material-ui/icons/Done";
import clsx from "clsx";
import random from "random";
import seedrandom from "seedrandom";
import "./UserBubble.css";
import { AppConfig } from "../../Config";
import { useDispatch, useSelector } from "react-redux";
import { RoomState } from "../../interfaces/RoomState";
import { useMemo, useState } from "react";
import { Button, DialogContent, DialogTitle, Dialog, DialogContentText, DialogActions } from "@mui/material";
import { UserType } from "../../interfaces/ActiveUser";
import { UserKickOut } from "../../store/actions/UserKickOut";

const POSSIBLE_COLORS = [
    Colors.teal,
    Colors.lightBlue,
    Colors.blue,
    Colors.cyan,
    Colors.amber,
    Colors.brown,
    Colors.blueGrey,
].map((c) => c["500"]);

const POSSIBLE_GROUP_COLORS = [
    AppConfig.primaryColor,
    ...[Colors.deepPurple, Colors.deepOrange, Colors.lightBlue].map((c) => c["500"]),
];

interface UserBubbleProps {
    id: string;
    /**
     * Nick of the user, max 4 characters
     */
    nick: string;
    /**
     * User name shown under bubble
     */
    name: string;
    /**
     * Id for align with animation context
     */
    flipId?: string;

    /**
     * Indicate if user has voted
     */
    hasVoted: boolean;
    /**
     * user vote, undefined if used didn't voted yer
     */
    vote?: string;

    /**
     * should show votes or display checkmark if user already voted
     */
    showVotes?: boolean;

    /**
     * Color of bubble. Default its auto-assigned based on nick, using color form POSSIBLE_COLORS list and using random algorithm with fixed entropy
     */
    bubbleColor?: string;
    /**
     * User group. If present it change bubble mode to "group mode"
     */
    group?: string;
}

const useMuStyles = makeStyles((theme: Theme) =>
    createStyles({
        voteCheck: {
            backgroundColor: theme.palette.type === "light" ? Green.A400 : Green[700],
        },
    }),
);

export function UserBubble({
    id: userId,
    nick,
    name,
    vote,
    hasVoted,
    flipId,
    bubbleColor,
    showVotes,
    group,
}: UserBubbleProps) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const colorIndex = (random as any).clone(seedrandom(nick)).int(0, POSSIBLE_COLORS.length - 1);
    const color = bubbleColor || POSSIBLE_COLORS[colorIndex];
    const muStyles = useMuStyles();
    const groups = useSelector((state: RoomState) => state.config.votingGroups);
    const userType = useSelector((state: RoomState) => state.activeUser.userType);
    const groupColor = useMemo(
        () =>
            group &&
            groups?.includes(group) &&
            POSSIBLE_GROUP_COLORS[groups?.indexOf(group) % POSSIBLE_GROUP_COLORS.length],
        [group, groups],
    );
    const hasGroups = !!groups?.length;
    const [openModal, setOpenModal] = useState(false);
    const dispatch = useDispatch();

    const openKickModal = () => {
        setOpenModal(true); // Show the modal when the kick button is clicked
    };

    const handleCloseModal = () => {
        setOpenModal(false); // Close the modal
    };

    const handleKick = () => {
        dispatch(
            UserKickOut({
                userId,
            }),
        );
        handleCloseModal();
    };

    return (
        <div className="bubble" data-flip-id={flipId}>
            <div
                className={clsx("circle", { "voted-glow": hasVoted && hasGroups && !showVotes })}
                style={{ backgroundColor: color }}
            >
                {userType === UserType.SCRUM_MASTER && (
                    <div className="kick-button" onClick={openKickModal}>
                        x
                    </div>
                )}
                <div className="label">{nick}</div>
            </div>
            {
                <Typography className="fullname" variant="subtitle1">
                    {name}
                </Typography>
            }

            <Dialog open={openModal} onClose={handleCloseModal}>
                <DialogTitle>{"Kick User?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to kick {nick} out of the session?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleKick} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            {hasGroups && (
                <div className="group" style={{ backgroundColor: groupColor || AppConfig.primaryColor }}>
                    {group && getFirstWord(group).toUpperCase().substring(0, 4)}
                </div>
            )}

            {hasVoted && !showVotes && !hasGroups && (
                <div className={`vote ${muStyles.voteCheck}`}>
                    <span>
                        <DoneIcon />
                    </span>
                </div>
            )}

            {hasVoted && showVotes && (
                <div className={`vote ${muStyles.voteCheck}`}>
                    <span>
                        <strong>{vote}</strong>
                    </span>
                </div>
            )}
        </div>
    );
}

function getFirstWord(name: string) {
    const parts = name.split(" ");
    if (parts.length === 1) {
        return name;
    }
    return parts.map((part) => part[0]).join("");
}
