import { UserType } from "../../interfaces/ActiveUser";
import { WsCompatibleAction } from "./WsCompatibleAction";

export const REVEAL_VOTES = "REVEAL_VOTES";

export interface RevealVotesAction extends WsCompatibleAction {
    type: typeof REVEAL_VOTES;
}

export function RevealVotes(): RevealVotesAction {
    return {
        type: REVEAL_VOTES,
        sendOverWs: {
            when: (user) => user.userType === UserType.SCRUM_MASTER,
            format: (user) => ({ action: "reveal_votes", token: user.token }),
        },
    };
}
