import { Card, CardContent, Link } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import { BulletList as CommentPlaceholder, List as ListPlaceholder } from "react-content-loader";
import { useJiraTicketStyles } from "./useJiraTicketStyles";

export function JiraTicketDetailsPlaceholder() {
    const classes = useJiraTicketStyles();

    return (
        <Card className={classes.root}>
            <CardContent className={classes.cardContent}>
                <div className={classes.header}>
                    <Typography className={classes.link} gutterBottom>
                        <Link target={"blank"} color="inherit">
                            Loading...
                        </Link>
                    </Typography>
                </div>

                <ListPlaceholder />
                <ListPlaceholder />

                <div style={{ width: "70%" }}>
                    <CommentPlaceholder />
                </div>
            </CardContent>
        </Card>
    );
}
