import { Actions } from "../actions/actions";
import { RESET_VOTES } from "../actions/ResetVotes";
import { SET_GROUP_FILTER } from "../actions/SetGroupFilter";

export function groupFilterReducer(state = "", action: Actions): string {
    switch (action.type) {
        case SET_GROUP_FILTER:
            return action.group;
        case RESET_VOTES:
            return "";

        default:
            return state;
    }
}
