if (!process.env.REACT_APP_BACKEND_URL || !process.env.REACT_APP_NAME) {
    throw new Error("Missing configuration. Ensure that env variables are set!");
}
export const AppConfig = {
    backendUrl: process.env.REACT_APP_BACKEND_URL,
    proxyUrl: process.env.REACT_APP_CORS_PROXY_URL,
    appName: process.env.REACT_APP_NAME,
    possibleScores: ["?", "1", "2", "3", "5", "8", "13", "21"],
    primaryColor: process.env.REACT_APP_PRIMARY_COLOR || "#3f51b5",
};
