import { JiraInstanceCredentials } from "../../interfaces/JiraInstanceCredentials";

export const JIRA_INSTANCE_CONNECTED = "JIRA_INSTANCE_CONNECTED";

export interface JiraInstanceConnectedAction {
    type: typeof JIRA_INSTANCE_CONNECTED;
    payload: JiraInstanceCredentials;
}

export const JiraInstanceConnected = (payload: JiraInstanceCredentials): JiraInstanceConnectedAction => {
    return {
        type: JIRA_INSTANCE_CONNECTED,
        payload,
    };
};
