import Box from "@mui/material/Box";

export default function SideBar({ children }: { children: JSX.Element }) {
    return (
        <Box sx={{ display: "flex" }}>
            {/* <ActiveTicketsDrawer /> */}
            <Box component="main" sx={{ flexGrow: 1 }}>
                {children}
            </Box>
        </Box>
    );
}
